import React, { useState } from "react";
import UniversalModal from "../../../components/modal/UniversalModal";
import {
  OnboardingCenterContainer,
  OnboardingMainContainer,
} from "../../onBoarding/Onboarding.styles";
import {
  CustomSwitch,
  DayContainer,
  Dot,
  FormControlLabelStyled,
  IconButtonStyled,
  InfoContainer,
  SameDaysDivStyled,
  Separator,
  TimeDivStyled,
} from "../../onBoarding/Step2.styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import DeleteIcon from "@mui/icons-material/Delete";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Checkbox, IconButton, Typography, useMediaQuery } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { PrimaryButton } from "../../../components/buttons/primaryButton/PrimaryButton";
import { LinkButton } from "../../../components/buttons/linkButton/LinkButton";
import dayjs, { Dayjs } from "dayjs";
import { COLORS } from "../../../constants/colors";
import {
  ModalHeader,
  StyledModalHeaderwrapper,
} from "../workerDataModal/WorkerModal.styles";
import { StyledXButton } from "../../../components/calendar/reservationModal/ReservationModal.styles";
import { ReactComponent as Xicon } from "../../../assets/X-ico.svg";
import { SalonWorkingDay } from "../../onBoarding/types/step2";

function parseScheduleWithSecondShift(scheduleObj) {
  let parsedSchedule: SalonWorkingDay[] = [];
  for (const day in scheduleObj) {
    if (scheduleObj[day].isIncluded) {
      const dayOfWeek = scheduleObj[day].dayOfWeek;
      const firstShiftOpeningTime = scheduleObj[day].firstShift.startTime
        ?.format("YYYY-MM-DDTHH:mm:ss")
        .split("T")[1];
      const firstShiftClosingTime = scheduleObj[day].firstShift.endTime
        ?.format("YYYY-MM-DDTHH:mm:ss")
        .split("T")[1];

      const secondShiftOpeningTime = scheduleObj[day].secondShift.startTime
        ?.format("YYYY-MM-DDTHH:mm:ss")
        .split("T")[1];
      const secondShiftClosingTime = scheduleObj[day].secondShift.endTime
        ?.format("YYYY-MM-DDTHH:mm:ss")
        .split("T")[1];

      parsedSchedule.push({
        dayOfWeek: dayOfWeek,
        openingTime: firstShiftOpeningTime,
        closingTime: firstShiftClosingTime,
      });

      if (secondShiftOpeningTime && secondShiftClosingTime) {
        parsedSchedule.push({
          dayOfWeek: dayOfWeek,
          openingTime: secondShiftOpeningTime,
          closingTime: secondShiftClosingTime,
        });
      }
    }
  }

  return parsedSchedule;
}

interface WorkerShiftModalProps {
  isOpen: boolean;
  setOpenState: React.Dispatch<React.SetStateAction<boolean>>;
  employeeData: any;
  setEmployeeData: React.Dispatch<React.SetStateAction<any>>;
}

const WorkerShiftModal = ({
  isOpen,
  setOpenState,
  employeeData,
  setEmployeeData,
}: WorkerShiftModalProps) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const closeModal = () => {
    setOpenState(false);
  };

  const createInitialDayInfo = (name: string, dayOfWeek: string) => {
    const employeeDayData = employeeData?.find((el) => el.dayOfWeek === dayOfWeek);
    // console.log(employeeDayData)
    const groupedData: any = [];

    employeeData.forEach(item => {
      if (item.dayOfWeek === dayOfWeek) {
        groupedData?.push(item);
      }
    });
 

    console.log({groupedData})

    if (groupedData?.length === 0) {
      return {
        isOpenTime: false,
        isIncluded: false,
        isSecondShiftOpen: false,
        firstShift: {
          startTime: null,
          endTime: null,
        },
        secondShift: {
          startTime: null,
          endTime: null,
        },
        labelName: name,
        dayOfWeek,
      }; 
  }


    const [hours, minutes] = groupedData[0]?.openingTime?.split(':');
    const [hoursClosing, minutesClosing] = groupedData[0]?.closingTime?.split(':');
   const dateOpen = hours && minutes ? dayjs().set('hour', hours).set('minute', minutes) : null;
   const dateClose = hoursClosing && minutesClosing ? dayjs().set('hour', hoursClosing).set('minute', minutesClosing) : null;

   let dateOpenSecondShift: Dayjs | null = null;
   let dateCloseSecondShift: Dayjs | null = null;
   if(groupedData?.[1]){
    const [hoursSecondShift, minutesSecondShift] = groupedData[1]?.openingTime?.split(':');
    const [hoursClosingSecondShift, minutesClosingSecondShift] = groupedData[1]?.closingTime?.split(':');
dateOpenSecondShift = hoursSecondShift && minutesSecondShift ? dayjs().set('hour', hoursSecondShift).set('minute', minutesSecondShift) : null;
dateCloseSecondShift = hoursClosingSecondShift && minutesClosingSecondShift ? dayjs().set('hour', hoursClosingSecondShift).set('minute', minutesClosingSecondShift) : null;
   }

    return {
    isOpenTime: false,
    isIncluded: true,
    isSecondShiftOpen: dateOpenSecondShift && dateCloseSecondShift,
    firstShift: {
      startTime: dateOpen,
      endTime: dateClose,
    },
    secondShift: {
      startTime: dateOpenSecondShift,
      endTime: dateCloseSecondShift,
    },
    labelName: name,
    dayOfWeek,
  }};

  const [schedule, setSchedule] = useState({
    mon: createInitialDayInfo("Ponedeljak", "Monday"),
    tue: createInitialDayInfo("Utorak", "Tuesday"),
    wed: createInitialDayInfo("Sreda", "Wednesday"),
    thu: createInitialDayInfo("Cetvrtak", "Thursday"),
    fri: createInitialDayInfo("Petak", "Friday"),
    sat: createInitialDayInfo("Subota", "Saturday"),
    sun: createInitialDayInfo("Nedelja", "Sunday"),
  });

  const formatTime = (startTime, endTime) => {
    const localStartTime = dayjs(startTime).format("YYYY-MM-DDTHH:mm:ss");
    const localEndTime = dayjs(endTime).format("YYYY-MM-DDTHH:mm:ss");
    const timeStart = startTime
      ? localStartTime.split("T")[1].split(":").slice(0, 2).join(":")
      : "";
    const timeEnd = endTime
      ? localEndTime.split("T")[1].split(":").slice(0, 2).join(":")
      : "";
    return startTime && endTime && `${timeStart} - ${timeEnd}`;
  };

  const handleValueChange = (day, key) => {
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [day]: {
        ...prevSchedule[day],
        [key]: !prevSchedule[day][key],
        isOpenTime:
          key === "isIncluded"
            ? !prevSchedule[day]["isIncluded"]
            : key === "isOpenTime"
            ? !prevSchedule[day]["isOpenTime"]
            : prevSchedule[day]["isOpenTime"],
        isSecondShiftOpen:
          key === "isSecondShiftOpen"
            ? true
            : prevSchedule[day]["isSecondShiftOpen"],
      },
    }));
  };

  const handleShiftTime = (day, shift, partOfShift, newStartTime) => {
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [day]: {
        ...prevSchedule[day],
        [shift]: {
          ...prevSchedule[day][shift],
          [partOfShift]: newStartTime,
        },
      },
    }));
  };

  const handleSameForAllDays = (day, isChecked) => {
    const newSchedule = { ...schedule };
    if (isChecked) {
      Object.keys(newSchedule).forEach((key) => {
        newSchedule[key] = {
          ...schedule[day],
          labelName: newSchedule[key].labelName,
          dayOfWeek: newSchedule[key].dayOfWeek,
          isOpenTime: key === "mon" ? true : false,
        };
      });
      setSchedule(newSchedule);
    }
  };

  const handleDeleteShift = (day) => {
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [day]: {
        ...prevSchedule[day],
        isSecondShiftOpen: false,
        secondShift: {
          startTime: null,
          endTime: null,
        },
      },
    }));
  };

  const handleSubmit = () => {
    const parsedSchedule = parseScheduleWithSecondShift(schedule);
    setEmployeeData((prev) => ({
      ...prev,
      "workingHours": parsedSchedule,
    }));
    closeModal();
  };

  return (
    <UniversalModal
      isModalOpen={isOpen}
      handleClose={closeModal}
      style={{ maxHeight: "100vh", overflowY: "auto" }}
    >
      <>
        <StyledModalHeaderwrapper sx={{ width: "92%" }}>
          <ModalHeader> Radno vreme</ModalHeader>
          <StyledXButton onClick={closeModal}>
            <Xicon />
          </StyledXButton>
        </StyledModalHeaderwrapper>
        <OnboardingMainContainer>
          <OnboardingCenterContainer>
            <InfoContainer sx={{ paddingTop: "0rem" }}>
              {Object.entries(schedule).map(([day, dayInfo]: any) => {
                return (
                  <DayContainer key={day}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabelStyled
                        value={dayInfo.isIncluded}
                        control={
                          <CustomSwitch
                            checked={dayInfo.isIncluded}
                            onChange={() =>
                              handleValueChange(day, "isIncluded")
                            }
                          />
                        }
                        label={dayInfo.labelName}
                      />
                      <Typography>
                        { dayInfo.firstShift.startTime &&  dayInfo.firstShift.endTime && formatTime(
                          dayInfo.firstShift.startTime,
                          dayInfo.firstShift.endTime
                        )}
                      </Typography>
                      {dayInfo.secondShift.startTime &&
                        dayInfo.secondShift.endTime && (
                          <>
                            <Dot />
                            <Typography>
                              {formatTime(
                                dayInfo.secondShift.startTime,
                                dayInfo.secondShift.endTime
                              )}
                            </Typography>
                          </>
                        )}
                      <IconButton
                        style={{ color: COLORS.primary, cursor: "pointer" }}
                        onClick={() => handleValueChange(day, "isOpenTime")}
                      >
                        {dayInfo.isOpenTime ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </IconButton>
                    </div>
                    {dayInfo.isOpenTime && (
                      <>
                        <TimeDivStyled>
                          <TimePicker
                            value={dayInfo.firstShift.startTime}
                            onChange={(newValue) =>
                              handleShiftTime(
                                day,
                                "firstShift",
                                "startTime",
                                newValue
                              )
                            }
                            ampm={false}
                            slotProps={{
                              textField: {
                                  inputProps: { readOnly: true },
                              },
                          }}
                          />
                          <Separator>do</Separator>
                          <TimePicker
                            value={dayInfo.firstShift.endTime}
                            onChange={(newValue) =>
                              handleShiftTime(
                                day,
                                "firstShift",
                                "endTime",
                                newValue
                              )
                            }
                            ampm={false}
                            slotProps={{
                              textField: {
                                  inputProps: { readOnly: true },
                              },
                          }}
                          />
                          <IconButton>
                            <DeleteIcon
                              style={{
                                color: COLORS.primary,
                                fontSize: "1.5rem",
                                visibility: "hidden",
                              }}
                            />
                          </IconButton>
                        </TimeDivStyled>
                        {dayInfo.isSecondShiftOpen && (
                          <TimeDivStyled>
                            <TimePicker
                              value={dayInfo.secondShift.startTime}
                              onChange={(newValue) =>
                                handleShiftTime(
                                  day,
                                  "secondShift",
                                  "startTime",
                                  newValue
                                )
                              }
                              ampm={false}
                              slotProps={{
                                textField: {
                                    inputProps: { readOnly: true },
                                },
                            }}
                            />
                            <Separator>do</Separator>
                            <TimePicker
                              value={dayInfo.secondShift.endTime}
                              onChange={(newValue) =>
                                handleShiftTime(
                                  day,
                                  "secondShift",
                                  "endTime",
                                  newValue
                                )
                              }
                              ampm={false}
                              slotProps={{
                                textField: {
                                    inputProps: { readOnly: true },
                                },
                            }}
                            />
                            <IconButton onClick={() => handleDeleteShift(day)}>
                              <DeleteIcon
                                style={{
                                  color: COLORS.primary,
                                  fontSize: "1.5rem",
                                }}
                              />
                            </IconButton>
                          </TimeDivStyled>
                        )}
                        <SameDaysDivStyled isSameDay={day === "mon"}>
                          {day === "mon" && (
                            <FormControlLabelStyled
                              control={
                                <Checkbox
                                  value={dayInfo.isIncluded}
                                  onChange={(event) =>
                                    handleSameForAllDays(
                                      day,
                                      event.target.checked
                                    )
                                  }
                                />
                              }
                              label="Isto za sve dane"
                            />
                          )}
                          <IconButtonStyled
                            onClick={() =>
                              handleValueChange(day, "isSecondShiftOpen")
                            }
                          >
                            <AddOutlinedIcon
                              style={{
                                fontSize: "1.5rem",
                                color: COLORS.white,
                              }}
                            />
                          </IconButtonStyled>
                        </SameDaysDivStyled>
                      </>
                    )}
                  </DayContainer>
                );
              })}
            </InfoContainer>
            <div style={{ marginTop: "1.875rem" }}>
              <PrimaryButton
                text={"Sačuvaj"}
                width={isMobile ? "20rem" : "30rem"}
                onClick={handleSubmit}
              />
            </div>
            <div style={{ marginBottom: "1.25rem" }}>
              <LinkButton
                text={"Otkaži"}
                onClick={() => {
                  closeModal();
                }}
              />
            </div>
          </OnboardingCenterContainer>
        </OnboardingMainContainer>
      </>
    </UniversalModal>
  );
};

export default WorkerShiftModal;
