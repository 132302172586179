import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

import { COLORS } from "../../../constants/colors";

export const StyledLinkButton = styled(Button)({
  color: COLORS.primary,
  fontSize: "1rem",
  fontWeight: "400",
  "&:hover": {
    color: COLORS.primary,
  },
});
