import * as React from "react";
import { useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Grid, IconButton, useMediaQuery } from "@mui/material";
import { COLORS } from "../../constants/colors";
import {
  ActionsMobile,
  Separator,
  StyledDataGrid,
  StyledPaper,
  StyledTableContainer,
  TableContainerMobile,
} from "./TableDataGrid.styles";
import { ServicesText } from "../../pages/services/Services.styles";
import {
  Severity,
  useNotificationContext,
} from "../../context/NotificationContext";
import ConfirmModal from "../modal/ConfirmModal";
import {
  ISalonDaysOff,
  deleteDaysOffForSalon,
  useDaysOfForSalon,
} from "../../services/daysOff";
import { useMutation } from "react-query";
import DaysOffModal from "../modal/DaysOffModal";
import { IS_WORKER } from "../../constants/localStorageKeys";

const SalonDaysOffTable = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isWorker = JSON.parse(localStorage.getItem(IS_WORKER) ?? "false");
  const { showNotification } = useNotificationContext();
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const defaultRowHeight = 55;
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isDaysOffModalOpen, setIsDaysOffModalOpen] = useState<boolean>(false);
  const [deleteDaysoff, setDeleteDaysOff] = useState<ISalonDaysOff>({
    startDate: "",
    endDate: "",
  });
  const { refetch, data } = useDaysOfForSalon();

  const { mutate: deleteDaysOffMutation } = useMutation(deleteDaysOffForSalon, {
    onSuccess: () => {
      setIsConfirmModalOpen(false);
      showNotification("Uspesno obrisano.", Severity.Success);
      refetch();
    },
    onError: (error) => {
      showNotification("Greška prilikom brisanja", Severity.Error);
    },
  });

  const handleArrowClick = (rowId: number) => {
    setExpandedRow((prevRow) => (prevRow === rowId ? null : rowId));
  };

  const getRowHeight = (params: any) => {
    return expandedRow === params.id ? "auto" : defaultRowHeight;
  };

  const rowsWithIds = data?.result?.map((row, index) => ({
    ...row,
    id: `${row.startDate}-${row.endDate}-${index}`,
  }));

  const openConfirmModal = (open: boolean, start?: string, end?: string) => {
    open &&
      start &&
      end &&
      setDeleteDaysOff({ startDate: start, endDate: end });
    setIsConfirmModalOpen(open);
  };

  const handleDaysOffModal = (open: boolean, start?: string, end?: string) => {
    open &&
      start &&
      end &&
      setDeleteDaysOff({ startDate: start, endDate: end });
    setIsDaysOffModalOpen(open);
  };

  const columns: GridColDef[] = isMobile
    ? [
        {
          field: "startDate",
          headerName: "",
          flex: 0.8,
          sortable: false,
          renderCell: (params) => {
            const isExpanded = expandedRow === params.row.id;
            return (
              <div style={{ marginTop: isExpanded ? "0.5rem" : undefined }}>
                <div>{params.value}</div>
                {isExpanded && (
                  <>
                    <TableContainerMobile>
                      <Separator />
                      <Grid container spacing={1}>
                        <Grid item xs={5}>
                          Od:
                        </Grid>
                        <Grid item xs={7}>
                          {params.row.startDate}
                        </Grid>
                        <Grid item xs={5}>
                          Do:
                        </Grid>
                        <Grid item xs={7}>
                          {params.row.endDate}
                        </Grid>
                      </Grid>
                    </TableContainerMobile>
                  </>
                )}
              </div>
            );
          },
        },
        {
          field: "actions",
          headerName: "",
          sortable: false,
          flex: 0.2,
          renderCell: isWorker ? undefined : ActionCellMobile,
        },
      ]
    : [
        {
          field: "startDate",
          headerName: "Od",
          flex: 1,
          sortable: false,
        },
        {
          field: "endDate",
          headerName: "Do",
          flex: 1,
          sortable: false,
        },
        {
          field: "actions",
          headerName: "",
          sortable: false,
          flex: 1,
          renderCell: isWorker ? undefined : ActionCell,
        },
      ];

  function ActionCell(params) {
    return (
      <div>
        <IconButton
          style={{ color: COLORS.yellow_800 }}
          onClick={() =>
            handleDaysOffModal(true, params.row.startDate, params.row.endDate)
          }
        >
          <BorderColorIcon />
        </IconButton>
        <IconButton
          style={{ color: COLORS.primary }}
          onClick={() =>
            openConfirmModal(true, params.row.startDate, params.row.endDate)
          }
        >
          <DeleteIcon />
        </IconButton>
      </div>
    );
  }

  function ActionCellMobile(params) {
    const isExpanded = expandedRow === params.row.id;

    return (
      <ActionsMobile
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {isExpanded && (
          <>
            <IconButton
              style={{ color: COLORS.yellow_800 }}
              onClick={() => handleDaysOffModal(true, params.row)}
            >
              <BorderColorIcon />
            </IconButton>
            <IconButton
              style={{ color: COLORS.primary }}
              onClick={() =>
                openConfirmModal(true, params.row.startDate, params.row.endDate)
              }
            >
              <DeleteIcon />
            </IconButton>
          </>
        )}
        <IconButton
          style={{
            color: COLORS.mediumGray,
            fontSize: 20,
          }}
          onClick={() => handleArrowClick(params.row.id)}
        >
          {isExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </IconButton>
      </ActionsMobile>
    );
  }

  return (
    <StyledTableContainer>
      <StyledPaper>
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <ServicesText>Neradni dani salona</ServicesText>
          </Grid>
        </Grid>
        <StyledDataGrid
          justifyContent={isMobile ? "start" : "end"}
          rows={rowsWithIds || []}
          columns={columns}
          hideFooterPagination
          hideFooter
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          disableDensitySelector
          hideFooterSelectedRowCount
          disableRowSelectionOnClick={true}
          columnHeaderHeight={isMobile ? 0 : 55}
          rowHeight={defaultRowHeight}
          getRowHeight={getRowHeight}
          getRowClassName={(params) =>
            expandedRow === params.id ? "expanded-row" : ""
          }
          style={{ width: "100%" }}
        />
      </StyledPaper>
      {isConfirmModalOpen && (
        <ConfirmModal
          isModalOpen={isConfirmModalOpen}
          handleClose={() => {
            openConfirmModal(false);
            refetch();
          }}
          onSave={() => deleteDaysOffMutation(deleteDaysoff)}
          actionToConfirm="želite da obrišete"
        />
      )}
      {isDaysOffModalOpen && (
        <DaysOffModal
          isModalOpen={isDaysOffModalOpen}
          handleClose={() => {
            handleDaysOffModal(false);
            refetch();
          }}
          title="Neradni dani salona"
          data={deleteDaysoff}
        />
      )}
    </StyledTableContainer>
  );
};
export default SalonDaysOffTable;
