import styled from "@emotion/styled";

export const LoaderContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  margin-top: -74px;
`;

export const LogoWrapper = styled("div")`
  width: 100px;
  height: 100px;
  animation: spin 3s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;