import { createContext, useContext } from "react";

export const portalLocales = ["en-GB"] as const;

export type PortalLocale = (typeof portalLocales)[number];

export const isPortalLocale = (value: string): value is PortalLocale => {
  const locales = portalLocales as readonly string[];

  return locales.includes(value);
};

export interface LocaleContextProps {
  locale: PortalLocale;
  updateLocale: (locale: PortalLocale) => Promise<void>;
}

export const LocaleContext = createContext<LocaleContextProps | null>(null);

export const useLocaleContext = (): LocaleContextProps => {
  const localeContext = useContext(LocaleContext);

  if (localeContext === null) {
    throw new Error("LocaleContext should not be null");
  }

  return localeContext;
};
