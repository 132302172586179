import { styled } from "@mui/system";

import { COLORS } from "../../constants/colors";
import { TextField } from "@mui/material";

export const StyledTextField = styled(TextField)(({ theme }) => ({
  borderRadius: "0.5625rem",
  width: "100%",
  backgroundColor: COLORS.lightGray,
  "& label.Mui-focused": {
    color: COLORS.darkGray,
  },
  "& input": {
    "&::placeholder": {
      color: COLORS.mediumGray,
    },
  },

  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
    borderColor: "red !important",
    borderWidth: "3px",
    border: "1px solid red",
    borderRadius: "0.5625rem",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "red !important",
  },

  "& .MuiOutlinedInput-root": {
    "& .MuiInputLabel-root": {
      color: COLORS.primary,
    },
  },
  "& fieldset": {
    border: "none",
  },
  [theme.breakpoints.down("sm")]: {
    "& fieldset": {
      border: "none",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "red !important",
      borderWidth: "3px",
      border: "1px solid red",
      borderRadius: "0.5625rem",
    },
  },
}));
