import apiClient from "./api/api";
import { LOGIN, REGISTER_PASSWORD, REGISTER_WORKER, SEND_EMAIL } from "./routes/routes";

export const sendConfirmationEmail = async ({ email }: { email: string }) => {
  const { data } = await apiClient.post(SEND_EMAIL, { email });
  return data;
};

export const registerSalon = async ({
  email,
  password,
  verificationCode,
}: {
  email: string;
  password: string;
  verificationCode: number;
}) => {
  const { data } = await apiClient.post(REGISTER_PASSWORD, {
    email,
    password,
    verificationCode,
  });
  return data;
};

export const login = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  const { data } = await apiClient.post(LOGIN, {
    email,
    password,
  });

  return data;
};

export const registerWorker = async ({
  email,
  password,
  verificationCode,
}: {
  email: string;
  password: string;
  verificationCode: number;
}) => {
  const { data } = await apiClient.post(REGISTER_WORKER, {
    email,
    password,
    verificationCode,
  });
  return data;
};
