import React from "react";
import { styled } from "@mui/system";
import { TextareaAutosize, Typography } from "@mui/material";

import { COLORS } from "./colors";
import { SupportAgentRounded } from "@mui/icons-material";

export const Header = styled(Typography)(({ theme }) => ({
  marginTop: "4.0625rem",
  fontSize: "2rem",
  color: COLORS.darkGray,
  fontWeight: "600",
  [theme.breakpoints.down("sm")]: {
    marginTop: "1.875rem",
    fontSize: "1.5rem",
  },
}));

export const HeaderPage = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  color: COLORS.darkGray,
  fontWeight: "600",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
}));

export const StepperHeader = styled(Typography)(({ theme }) => ({
  fontSize: "2.25rem",
  color: COLORS.primary,
  textAlign: "center",
  fontWeight: "600",
  [theme.breakpoints.down("sm")]: {
    marginTop: "1.875rem",
  },
}));

export const SubHeader = styled(Typography)(({ theme }) => ({
  marginTop: "1.3125rem",
  color: COLORS.mediumGray,
  fontSize: "1rem",
  fontWeight: "400",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    marginLeft: "0.625rem",
    marginRight: "0.625rem",
  },
}));

export const StepperSubHeader = styled(Typography)(({ theme }) => ({
  color: COLORS.darkGray,
  fontSize: "1rem",
  fontWeight: "400",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    marginLeft: "0.625rem",
    marginRight: "0.625rem",
  },
}));

export const StyledMainContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  [theme.breakpoints.down("sm")]: {
    marginTop: "1.25rem",
  },
}));

export const StyledMasterContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: "60px",
  [theme.breakpoints.up("md")]: {
    marginTop: "30px",
  },
}));

export const StyledSupportButton = styled(SupportAgentRounded)(({ theme }) => ({
  position: "fixed",
  top: "2%",
  right: "2%",
  color: COLORS.white,
          backgroundColor: COLORS.primary,
          padding: "5px",
          borderRadius: "50%",
}));

export const StyledCenteredContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  padding: "20px",
  maxWidth: "450px",
}));

export const StyledCenterContainer = styled("div")(({ theme }) => ({
  width: "27.125rem",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    width: "23.75rem",
  },
}));

export const StyledButtonContainer = styled("div")({
  marginTop: "0.9375rem",
  justifyContent: "center",
  display: "flex",
  alignItems: "center",
});

export const StyledDescription = styled(TextareaAutosize)(({ theme }) => ({
  width: "25.0625rem",
  height: "10.3125rem",
  resize: "none",
  borderRadius: "0.5rem",
  backgroundColor: COLORS.lightGray,
  border: "none",
  fontFamily: "Poppins",
  fontSize: "1rem",
  outline: "none",
  paddingTop: "0.625rem",
  paddingRight: "0.625rem",
  paddingBottom: "0.625rem",
  paddingLeft: "0.625rem",
  marginLeft: "-0.6rem",
  [theme.breakpoints.down("sm")]: {
    width: "20rem",
    height: "10.3125rem",
    marginLeft: "0.5rem",
  },
  "&:focus": {
    borderColor: COLORS.primary,
    outline: `0.00625rem solid ${COLORS.primary}`,
  },
}));
