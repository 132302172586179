import { COLORS } from "../../constants/colors";

export enum ViewUnits {
  month = "month",
  week = "week",
  day = "day",
}

export const getViewUnit = (view) => {
  switch (view) {
    case "month":
      return ViewUnits.month;
    case "week":
      return ViewUnits.week;
    case "day":
      return ViewUnits.day;
    default:
      return ViewUnits.month;
  }
};

enum colorIDs {
  COLOR_1 = 1,
  COLOR_2,
  COLOR_3,
  COLOR_4,
}

export const treatmentColor = (colorID) => {
  switch (colorID) {
    case colorIDs.COLOR_1:
      return COLORS.event_orange;
    case colorIDs.COLOR_2:
      return COLORS.event_blue;
    case colorIDs.COLOR_3:
      return COLORS.event_yellow;
    case colorIDs.COLOR_4:
      return COLORS.event_green;
    default:
      return COLORS.event_primary;
  }
};

export const eventStyleGetter = (event, data, view) => {
  const currentEvent = event?.appointmentId;
  let colorID = currentEvent ? ((currentEvent - 1) % 4) + 1 : 0;

  let style = {
    maxWidth: `${view === ViewUnits.day ? "50%" : "90%"}`,
    flexDirection: `${view === ViewUnits.week ? "column" : ""}`,
    flexFlow: `${view === ViewUnits.week ? "column" : ""}`,
    backgroundColor: `${treatmentColor(colorID)}`,
    fontSize: `${view === ViewUnits.day ? "1rem" : "0.8rem"}`,
  };

  return { style };
};

export const getMinOpeningAndMaxClosingTimes = (times) => {
  let minOpeningTime = times[0]?.openingTime;
  let maxClosingTime = times[0]?.closingTime;

  times.forEach(item => {
      if (item.openingTime < minOpeningTime) {
          minOpeningTime = item.openingTime;
      }
      if (item.closingTime > maxClosingTime) {
          maxClosingTime = item.closingTime;
      }
  });

  return { minOpeningTime, maxClosingTime };
};