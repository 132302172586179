import { Modal } from "@mui/material";
import React from "react";
import { StyledUniversalModal } from "./UniversalModal.styles";

interface UniversalModalProps {
  isModalOpen: boolean;
  handleClose: any;
  width?: string;
  children: JSX.Element;
  responsiveBottom?: string;
  style?: React.CSSProperties;
}

const UniversalModal = ({
  isModalOpen,
  handleClose,
  children,
  width,
  responsiveBottom,
  style,
}: UniversalModalProps) => {
  return (
    <Modal open={isModalOpen} onClose={handleClose}>
      <StyledUniversalModal
        width={width}
        style={style}
        bottom={responsiveBottom}
      >
        {children}
      </StyledUniversalModal>
    </Modal>
  );
};

export default UniversalModal;
