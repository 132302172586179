import { styled } from "@mui/system";
import { COLORS } from "../../constants/colors";

export const CreatedService = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: COLORS.orange_light,
  paddingLeft: "0.688rem",
  paddingRight: "0.688rem",
  paddingTop: "0.5rem",
  paddingBottom: "0.5rem",
  width: "15.438rem",
  height: "2.375rem",
  borderRadius: "0.563rem",
  flexDirection: "row",
  marginBottom: "0.875rem",
  marginTop: "0.4rem",
  [theme.breakpoints.down("sm")]: {
    width: "21.125rem",
    height: "2.8125rem",
  },
}));

export const OnboardingMainContainerStep4 = styled("div")({
  display: "flex",
  marginTop: "3.75rem",
});

export const ColumnContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "15px"
});

export const ServicesText = styled("span")({
  color: COLORS.gray_600,
  textTransform: "uppercase",
});
