import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";

import NavigationMenu from "../../components/navigation/NavigationMenu";
import {
  StyledContainer,
  StyledContent,
  StyledContentShift,
} from "../../styles/Container.styles";
import { ProtectedRoute } from "../../components/route/PrivateRoute";

interface PageContainerProps {
  children: JSX.Element;
  mobileNoPadding?: boolean;
}

export const PageContainer = ({ children,mobileNoPadding }: PageContainerProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [closed, setClosed] = useState<boolean>(isMobile);

  return (
    <ProtectedRoute>
      <StyledContainer maxWidth={false}>
        <NavigationMenu
          closeMenu={() => {
            setClosed(true);
          }}
          openMenu={(): void => {
            setClosed(false);
          }}
          isClosed={closed}
        />
        {closed ? (
          <StyledContent mobileNoPadding={mobileNoPadding}>
            <Grid
              container
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                container
                item
                flexDirection="row"
                justifyContent="center"
                style={{ flex: "1 1 auto" }}
              >
                {children}
              </Grid>
            </Grid>
          </StyledContent>
        ) : (
          <StyledContentShift>
            <Grid
              container
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Grid
                container
                item
                flexDirection="row"
                justifyContent="center"
                style={{ flex: "1 1 auto" }}
              >
                {children}
              </Grid>
            </Grid>
          </StyledContentShift>
        )}
      </StyledContainer>
    </ProtectedRoute>
  );
};
