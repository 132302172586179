import { useMutation, useQuery, useQueryClient } from "react-query";
import apiClient from "./api/api";
import { WORKERS } from "./routes/routes";

export const postNewWorker = async ({
  email,
  fullName,
  workerType,
  workingHours,
}: {
  email: string;
  fullName: string;
  workerType: string;
  workingHours: {
    dayOfWeek: string;
    openingTime: string;
    closingTime: string;
  }[];
}) => {
  const data = await apiClient.post(WORKERS, {
    email,
    fullName,
    workerType,
    workingHours,
  });

  return data;
};

export const putWorker = async ({
  id,
  fullName,
  workerType,
  workingHours,
}: {
  id: number;
  fullName: string;
  workerType: string;
  workingHours: {
    dayOfWeek: string;
    openingTime: string;
    closingTime: string;
  }[];
}) => {
  const data = await apiClient.put(WORKERS, {
    id,
    fullName,
    workerType,
    workingHours,
  });

  return data;
};

const getWorkers = async () => {
  const { data } = await apiClient.get(WORKERS);

  return data;
};

export const useWorkers = (enabled = true) => {
  const { data, isLoading, isError, isFetching, refetch } = useQuery(
    ["workers"],
    () => getWorkers(),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled,
    }
  );

  return {
    data: data ?? [],
    isLoading,
    isError,
    isFetching,
    refetch,
  };
};

const deleteWorker = async (id: number) => {
  await apiClient.delete(WORKERS, {
    data: {
      id,
    },
  });
};

export const useDeleteWorker = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteWorker, {
    onSuccess: () => {
      queryClient.invalidateQueries("workers");
    },
  });
};
