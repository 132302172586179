enum InputError {
  LowerCaseRequired = "Malo slovo neophodno",
  UpperCaseRequired = "Veliko slovo neophodno",
  NumberRequired = "Broj neophodan",
  SpecialCharacterRequired = "Specijalan karakter neophodan",
  Empty = "Niste uneli karaktere",
  InvalidEmail = "Neispravna email adresa",
  EmptyEmail = "Niste uneli email adresu",
  InvalidDate = "Neispravan datum",
  NotStrongPassword = "Lozinka nije dovoljno jaka: mora imati bar 8 karaktera, bar jedno malo, veliko slovo, barem jedan broj i specijalni karakter ",
  NoMatch = "Lozinke se ne podudaraju",
  EmptyLoginPassword = "Niste uneli lozinku",
  EmptyLoginEmail = "Niste uneli email adresu",
  EmailAlreadyTaken = "Email adresa već postoji",
  EmptyServiceGroup = "Niste izabrali grupu",
  EmptyServiceName = "Niste uneli ime usluge",
  EmptyPrice = "Niste uneli cenu",
  InvalidPhoneNumber = "Neispravan broj telefona",
}

enum PasswordError {
  TooShort = "Lozinka je previše kratka",
}

enum ConfirmPasswordError {
  MustMatch = "Lozinke se ne poklapaju",
}

export { InputError, ConfirmPasswordError, PasswordError };
