import { styled } from "@mui/material/styles";
import { COLORS } from "../../constants/colors";

export const StyledContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  gap: "1.25rem",
});

export const StyledHeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
}));

export const StyledContentContainer = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    gap: "0.5rem",
});

export const StyledHeaderTitle = styled("span")(({ theme }) => ({
  color: COLORS.darkGray,
  fontSize: "1.5rem",
  fontWeight: "500",

  [theme.breakpoints.down("sm")]: {
    fontSize: "1.125rem"
  },
}));

export const StyledErrorMessageContainer = styled("div")(({ theme }) => ({
    height: "1rem",

    [theme.breakpoints.down("sm")]: {
    height: "10px",
    marginBottom: '0',
  },
}));
