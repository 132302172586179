import { useQuery } from "react-query";
import apiClient from "./api/api";
import { ANALYTICS_APPOINTMENTS_INFO, ANALYTICS_SERVICES_INFO } from "./routes/routes";

interface Owner {
  id: number | null;
  name: string;
}
interface PriceAnalytics {
  totalAmount: number;
  salonShare: number;
  petifyShare: number;
}
interface Service {
  key: number;
  value: string;
}
export interface AppointmentInfo {
  appointmentId: number;
  appointmentDate: string;
  appointmentTime: string;
  isManualReservation: boolean;
  isCanceled: boolean;
  owner: Owner;
  priceAnalytics: PriceAnalytics;
  services: Service[];
}

export const getAppointmentsInfo = async (startDate, endDate, creationMode, cancelationMode) => {
  const { data } = await apiClient.get(
    `${ANALYTICS_APPOINTMENTS_INFO}?startDate=${startDate}&endDate=${endDate}&reservationCreationMode=${creationMode}&reservationCancelationMode=${cancelationMode}`
  );
  return data;
};

export const useAppointmentsInfo = (startDate, endDate, creationMode, cancelationMode) => {
  const { data, isLoading, isError, isFetching, refetch, isSuccess } = useQuery(
    [`appoitmentInfo-${startDate}-${endDate}`],
    () => getAppointmentsInfo(startDate, endDate, creationMode, cancelationMode),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false
    }
  );

  return {
    data: data ?? [],
    isLoading,
    isError,
    isFetching,
    refetch,
    isSuccess
  };
};

export const getServicesInfo = async (startDate, endDate, cancelationMode) => {
  const { data } = await apiClient.get(
    `${ANALYTICS_SERVICES_INFO}?startDate=${startDate}&endDate=${endDate}&reservationCancelationMode=${cancelationMode}`
  );
  return data;
};