import { useQuery } from "react-query";
import apiClient from "../api/api";
import { FREE_TIME_SLOTS } from "../routes/routes";

export const fetchSalonFreeSlots = async ({
  date,
  serviceIds,
}: {
  date: string;
  serviceIds: [];
}) => {
  const { data } = await apiClient.post(FREE_TIME_SLOTS, { date, serviceIds });

  return data;
};

export const useSalonFreeSlots = (date, serviceIds) => {
  const { data, isLoading, isError, isFetching, refetch } = useQuery(
    [`salon-free-time-slots${date}`],
    () => fetchSalonFreeSlots({ date: date, serviceIds: serviceIds }),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: serviceIds !== null,
    }
  );

  return {
    data,
    isLoading,
    isError,
    isFetching,
    refetch,
  };
};
