import React from "react";
import { useMediaQuery } from "@mui/material";

import UniversalModal from "./UniversalModal";
import { PrimaryButton } from "../buttons/primaryButton/PrimaryButton";
import { StyledContainer } from "./CreateGroupModal.styles";
import { SecondaryButton } from "../buttons/secondaryButton/SecondaryButton";
import { COLORS } from "../../constants/colors";
import { useDeleteWorker } from "../../services/workers";

interface ConfirmModalProps {
  isModalOpen: boolean;
  handleClose: any;
  onSave: () => void;
  actionToConfirm: string;
  workerId?: number;
}

const ConfirmModal = ({
  isModalOpen,
  handleClose,
  onSave,
  actionToConfirm,
  workerId = 0,
}: ConfirmModalProps) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <UniversalModal
      style={{
        width: isMobile ? undefined : "27.18rem",
        height: isMobile ? undefined : "max-content",
        paddingTop: isMobile ? "10rem" : "1.875rem",
        paddingBottom: isMobile ? undefined : "1.875rem",
        borderRadius: isMobile ? undefined : 9,
      }}
      isModalOpen={isModalOpen}
      handleClose={handleClose}
    >
      <StyledContainer>
        <p
          style={{
            fontSize: isMobile ? "1rem" : "1.125rem",
            color: COLORS.darkGray,
          }}
        >
          Da li ste sigurni da {actionToConfirm}?
        </p>

        <PrimaryButton
          text={"Da"}
          width={isMobile ? "20.5rem" : "21rem"}
          onClick={onSave}
        />
        <SecondaryButton
          text={"Otkaži"}
          width={"21rem"}
          onClick={handleClose}
        />
      </StyledContainer>
    </UniversalModal>
  );
};

export default ConfirmModal;
