import { Box, Button, Typography, styled } from "@mui/material";
import { COLORS } from "../../constants/colors";

export const StyledCalendarComponentWrapper = styled(Box)(({ theme }) => ({
  width: "100%",

  [theme.breakpoints.down("lg")]: {
    width: "90vw",
  },

  [theme.breakpoints.down("md")]: {
    width: "98vw",
  },

  "& .rbc-month-view": {
    overflow: "hidden",
    borderRadius: "0.563rem",
    borderColor: COLORS.calendar_border,

    [theme.breakpoints.down("sm")]: {
     marginBottom: "2rem"
    },
  },

  "& .rbc-time-gutter.rbc-time-column": {
    [theme.breakpoints.down("sm")]: {
      border: "none",
      color: COLORS.medium_gray,
      marginBottom: "2rem"
    },
  },

  "& .rbc-month-header": {
    height: "2.75rem",
    fontSize: "1rem",
    backgroundColor: COLORS.lightGray,
  },

  "& .rbc-header": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderColor: COLORS.calendar_border,
    fontSize: "1rem",
    fontWeight: "400",
    color: COLORS.darkGray,

    [theme.breakpoints.down("sm")]: {
      border: "none"
    },
  },

  "& .rbc-day-bg + .rbc-day-bg": {
    borderColor: COLORS.calendar_border,
  },

  "& .rbc-off-range-bg": {
    backgroundColor: COLORS.white,
  },

  "& .rbc-date-cell": {
    position: "relative",
    minHeight: "1.313rem",

    "& .rbc-button-link": {
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 3,
      display: "flex",
      alignItems: "start",
      justifyContent: "end",
      padding: "0.62rem",
      width: "100%",
      height: "16.3vh",
    },
  },

  "& .rbc-row-segment": {
    marginTop: "2%",
  },

  "& .MuiButtonBase-root": {
    boxShadow: "none",
  },

  "& .rbc-show-more": {
    color: COLORS.primary,
  },

  "& .rbc-time-header": {
    marginRight: "0 !important",
    borderRight: 0,

    [theme.breakpoints.down("md")]: {
      paddingRight: "1px",
    },
  },

  "& .rbc-time-header-content": {
    borderTop: `1px solid ${COLORS.calendar_border}`,
    borderRight: `1px solid ${COLORS.calendar_border}`,
    borderColor: COLORS.calendar_border,
    borderTopRightRadius: "0.5rem",
    borderTopLeftRadius: "0.5rem",
  },

  "& .rbc-time-header-cell": {
    height: "2.75rem",
    backgroundColor: COLORS.lightGray,
    borderColor: COLORS.calendar_border,
    borderTopRightRadius: "0.5rem",
    borderTopLeftRadius: "0.5rem",
  },

  "& .rbc-time-header-gutter": {
    border: "none",
    visibility: "hidden",
    width: "1.6875rem",
  },

  "& .rbc-time-content > * + * > *": {
    borderColor: COLORS.calendar_border,
  },

  "& .rbc-allday-cell": {
    display: "none",
  },

  "& .rbc-time-content": {
    borderTop: "none",
    borderBottomLeftRadius: "0.5rem",

    [theme.breakpoints.down("lg")]: {
      paddingRight: "0.1rem",
    },
  },

  "& .rbc-time-view": {
    border: "none",
  },

  "& .rbc-day-slot": {
    overflow: "hidden",

    "& .rbc-event-content": {
      width: "fit-content",
    },

    "& .rbc-event-label": {
      lineHeight: "0.9",
    },
  },

  "& .rbc-day-slot:last-of-type": {
    borderRight: `1px solid ${COLORS.calendar_border}`,
    borderBottomRightRadius: "0.5rem",

    "& .rbc-timeslot-group:nth-last-of-type(2)": {
      borderBottomRightRadius: 8,
    },
  },

  "& .rbc-time-gutter": {
    "& .rbc-timeslot-group": {
      border: "none",
    },
  },

  "& .rbc-time-slot": {
    height: "2rem",
  },

  "& .rbc-time-content .rbc-time-column:nth-of-type(2)": {
    borderBottomLeftRadius: "0.5rem",

    "& .rbc-timeslot-group:nth-last-of-type(2)": {
      borderBottomLeftRadius: "0.5rem",
    },

    "& .rbc-events-container": {
      borderBottomLeftRadius: "0.5rem",
    },
  },

  "& .rbc-day-slot .rbc-events-container": {
    marginRight: 0,
  },

  "& .rbc-event": {
    color: "Black",

    "& .rbc-event-content": {
      fontSize: "1rem",

      [theme.breakpoints.down("lg")]: {
        fontSize: "0.85rem",
      },
    },
  },

  "& .rbc-day-slot .rbc-event": {
    borderRadius: "10px",
    border: "0px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "fit-content",
    padding: "0.2rem 0.1rem",

    "& .rbc-event-content": {
      textAlign: "center",
    },

    [theme.breakpoints.down("lg")]: {
      "& .rbc-event-label": {
        maxWidth: "45%",
        overflow: "hidden",
        textOverflow: "clip",
      },
    },
  },

  "& .dayViewContainer": {
    "& .rbc-time-header-content": {
      border: "none",
    },

    "& .rbc-day-slot.rbc-time-column": {
      borderTopLeftRadius: "0.5rem",
      borderTopRightRadius: "0.5rem",
    },
    "& .rbc-day-slot.rbc-time-column .rbc-timeslot-group:first-of-type": {
      borderTop: `1px solid ${COLORS.calendar_border}`,
      borderTopLeftRadius: "0.5rem",
      borderTopRightRadius: "0.5rem",
    },
  },
}));

export const StyledCalendarHeaderWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",

  [theme.breakpoints.down("sm")]: {
    marginBottom: "1rem",
    alignItems: 'flex-end'
  },
}));

export const StyledCalendarHeadingContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "2rem",
  marginBottom: "1rem",

  [theme.breakpoints.down("lg")]: {
    margin: "0",
    flexDirection: "row",
    gap: ".5rem",
  },

  [theme.breakpoints.down("sm")]: {
    flexDirection: "row",
  },
}));
export const StyledCalendarHeading = styled(Typography)(({ theme }) => ({
  color: COLORS.darkGray,
  fontSize: "2rem",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",

  [theme.breakpoints.down("md")]: {
    fontSize: "1.5rem",
  },
}));

export const StyledCalendarEmptyButton = styled(Button)(({ theme }) => ({
  minWidth: "7.875rem",
  minHeight: "3.4rem",
  flexShrink: 0,
  padding: "1rem",
  border: `1px solid ${COLORS.primary}`,
  borderRadius: "0.5625rem",
  color: COLORS.primary,
  fontWeight: 500,
  fontSize: "1rem",
  fontStyle: "normal",
  textTransform: "capitalize",
  lineHeight: "1.2",

  "&:hover": {
    border: `1px solid var(--Primary, ${COLORS.primary})`,
    backgroundColor: COLORS.primary_transparent,
    color: COLORS.primary,
  },

  [theme.breakpoints.down("md")]: {
    minWidth: "4rem",
    minHeight: "2.5rem",
    padding: "0.7rem",
  },
}));

export const StyledCalendarEmptyButtonDisabled = styled(Button)(
  ({ theme }) => ({
    minWidth: "7.875rem",
    minHeight: "3.4rem",
    flexShrink: 0,
    padding: "1rem",
    border: `1px solid transparent`,
    borderRadius: "0.5625rem",
    color: COLORS.primary,
    backgroundColor: COLORS.primary_transparent,
    fontWeight: 500,
    fontSize: "1rem",
    fontStyle: "normal",
    textTransform: "capitalize",
    lineHeight: "1.2",
    pointerEvents: "none",

    [theme.breakpoints.down("md")]: {
      minWidth: "4rem",
      minHeight: "2.5rem",
      padding: "0.7rem",
    },
  })
);

export const StyledCalendarHeaderDropdownAndBtnWrapper = styled(Box)(
  ({ theme }) => ({
    display: "flex",
    alignItems: "start",
    gap: "1.25rem",

    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      justifyContent: "end",
      paddingBottom: "1.2rem",
    },

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "end",
      paddingBottom: "0",
    },
  })
);

export const StyledDropdownContainer = styled(Box)(({ theme }) => ({
  width: "13rem",
  height: "3.5rem",
  padding: "0",
  display: "flex",
  alignItems: "end",

  [theme.breakpoints.down("sm")]: {
    width: "fit-content",
  },
}));

export const StyledViewButton = styled(Button)({
  textTransform: "capitalize",
  color: COLORS.primary,
});

export const StyledCalendarFullButton = styled(Button)(({ theme }) => ({
  minWidth: "10.06rem",
  minHeight: "3.40rem",
  padding: "0.75rem",
  letterSpacing: "0.08rem",
  borderRadius: "0.5rem",
  flexShrink: 0,
  backgroundColor: COLORS.primary,
  color: "#fff",
  boxShadow: "none",
  textTransform: "capitalize",
  fontSize: "1rem",
  lineHeight: "1.2",

  "&:hover": {
    border: `1px solid  ${COLORS.primary}`,
    backgroundColor: COLORS.primary_transparent,
    color: COLORS.primary,
  },

  [theme.breakpoints.down("md")]: {
    minWidth: "6.375rem",
    minHeight: "2.8125rem",
    padding: "0.7rem",
  },
}));

export const StyledNavButtonsWrapper = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "1.31rem",
});

export const StyledNavButton = styled(Button)({});

export const StyledCalendarMonthName = styled(Typography)({
  fontSize: "1rem",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  color: COLORS.primary,
  textAlign: "center",
});

export const StyledCalendarContainer = styled(Box)(({ theme }) => ({
  height: "80vh",
  width: "100%",
  position: "relative",

  [theme.breakpoints.down("lg")]: {
    height: "80vh",
  },

  [theme.breakpoints.down("sm")]: {
    position: "absolute",
    right: "0",
    paddingBottom: "0",
  },
}));

export const StyledCalendarLoadingState = styled(Box)(({ theme }) => ({
  position: "absolute",
  backgroundColor: COLORS.mediumGray_opacity,
  width: "100%",
  height: "100%",
  zIndex: "99",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const StyledLoadingContent = styled(Typography)(({ theme }) => ({
  margin: "auto",
  backgroundColor: COLORS.white,
  padding: "1rem",
  width: "40%",
  textAlign: "center",
  borderRadius: "0.5rem",
}));
