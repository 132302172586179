export const USE_REFETCH_APPOITMENTS_KEY = 'use-refetch-appoitments-key'

export const SERVICE_GROUPS_KEY = 'service-groups'
export const SERVICES_BY_GROUP_KEY = 'services-by-group'
export const SERVICES_KEY = 'services'

export const SALONS_WEB_KEY = 'salon-web-info'
export const SALONS_WEB_IMAGES_KEY = 'salon-web-images'
export const SALONS_WEB_HOURS = 'salon-web-hours'

export const DAYS_OFF_SALON_KEY = 'days-off-salon'
export const DAYS_OFF_WORKERS_KEY = 'days-off-workers'
export const DAYS_OFF_ALL_KEY = 'days-off-all'

export const ANALYTICS_APPOINTMENTS_INFO_KEY = 'appointments-info'