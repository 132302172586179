import { FormControlLabel, IconButton, Switch, styled } from "@mui/material";
import { COLORS } from "../../constants/colors";

export const CustomSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: COLORS.primary,
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: COLORS.primary_light,
  },
  "& .MuiSwitch-switchBase.Mui-checked:hover + .MuiSwitch-track": {
    backgroundColor: COLORS.primary_light,
  },
}));

export const FormControlLabelStyled = styled(FormControlLabel)({
  "& .MuiFormControlLabel-label": {
    color: COLORS.black,
  },
});

export const DayContainer = styled("div")({
  padding: "1rem",
  borderRadius: ".5rem",
  border: `1px solid ${COLORS.medium_gray}`,
  display: "flex",
  flexDirection: "column",
  marginBottom: ".8rem",
});

export const TimeDivStyled = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: ".7rem",
});

export const Dot = styled("div")({
  display: "flex",
  width: ".25rem",
  height: ".25rem",
  borderRadius: "50%",
  backgroundColor: COLORS.medium_gray,
});

export const SameDaysDivStyled = styled("div")(
  ({ isSameDay }: { isSameDay: boolean }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: isSameDay ? "space-between" : "flex-end",
    alignItems: "center",
  })
);

export const IconButtonStyled = styled(IconButton)({
  borderRadius: "50%",
  background: COLORS.secondary,
  width: "1.7rem",
  height: "1.7rem",
  "&:hover": {
    background: COLORS.secondary,
  },
});

export const InfoContainer = styled("div")(({ theme }) => ({
  maxWidth: "30rem",
  width: "100%",
  paddingTop: "2.75rem",
  [theme.breakpoints.down("sm")]: {
    paddingTop: "1.5rem",
  },
}));

export const Separator = styled("span")({
  marginRight: "0.3125rem",
  marginLeft: "0.3125rem",
});
