import { styled } from "@mui/material/styles";
import { COLORS } from "../../../constants/colors";
import { Box, Typography } from "@mui/material";

export const StyledWorkerModalWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "1.25rem",
  width: "100%",
}));

export const StyledModalHeaderwrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: "15px",

  [theme.breakpoints.down("sm")]: {
    marginBottom: "0"
  },
}));

export const ModalHeader = styled("p")(({ theme }) => ({
  color: COLORS.darkGray,
  fontSize: "1.5rem",
  fontWeight: "500",
  margin: "0",

  [theme.breakpoints.down("sm")]: {
    fontSize: "1.125rem",
  },
}));

export const StyledAddIcon = styled("div")({
  backgroundColor: COLORS.yellow_800,
  width: 27,
  height: 27,
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "0.625rem",
});

export const StyledEditIcon = styled("div")({
  backgroundColor: COLORS.yellow_800,
  width: 27,
  height: 27,
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "0.625rem",
});

export const StyledIconContainer = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",

  "&:hover": {
    cursor: "pointer",
  },

  [theme.breakpoints.down("sm")]: {},
}));

export const StyledWorkerShiftText = styled(Typography)(({ theme }) => ({
  color: COLORS.black,
}));

export const StyledFormButtonsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1.25rem",
  width: "100%",
  marginTop: "15px",

  [theme.breakpoints.down("sm")]: {
    marginTop: "0"
  },
}));
