import axios from "axios";
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../../constants/localStorageKeys";

const apiClient = axios.create({
  baseURL: "https://petifyapp.com",
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`,
  },
});

apiClient.interceptors.request.use((request) => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  if (accessToken) {
    request.headers["Authorization"] = `Bearer ${accessToken}`;
  }
  return request;
});

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response.status === 401 &&
      error?.response?.data?.errorMessage !== "There is no such user!"
    ) {
      try {
        const res = await apiClient.post("/api/salons-auth/refresh", {
          refreshToken: localStorage.getItem(REFRESH_TOKEN),
        });
        if (res.status === 200) {
          localStorage.setItem(ACCESS_TOKEN, res.data?.result?.accessToken);
          localStorage.setItem(REFRESH_TOKEN, res.data?.result?.refreshToken);

          apiClient.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${res.data?.result?.accessToken}`;
          originalRequest.headers[
            "Authorization"
          ] = `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`;

          return axios.request(originalRequest);
        }
      } catch (refreshError: any) {
        if (refreshError.response && refreshError.response.status === 400) {
          localStorage.removeItem(ACCESS_TOKEN);
          localStorage.removeItem(REFRESH_TOKEN);
          window.location.href = "/login";
        }

        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default apiClient;
