import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { COLORS } from "../../constants/colors";

const AnalyticsServicesChart = ({ searchedData }: { searchedData: any[] }) => {
  const data = searchedData?.map((data) => ({
    name: data.serviceName,
    count: data.count,
  }));

  return (
    searchedData && (
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="1 1" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="count" fill={COLORS.secondary} />
        </BarChart>
      </ResponsiveContainer>
    )
  );
};

export default AnalyticsServicesChart;
