import React, { useState } from "react";
import UniversalModal from "./UniversalModal";
import { ReactComponent as Xicon } from "../../assets/X-ico.svg";
import {
  StyledContainer,
  StyledContentContainer,
  StyledErrorMessageContainer,
  StyledHeaderContainer,
  StyledHeaderTitle,
} from "./DaysOffModal.styles";
import { StyledDatePicker } from "../dateTimePicker/DateTimePicker.styles";
import dayjs, { Dayjs } from "dayjs";
import { PrimaryButton } from "../buttons/primaryButton/PrimaryButton";
import { useMutation } from "react-query";
import {
  ISalonDaysOff,
  createDaysOffForSalon,
  createDaysOffForWorker,
  editDaysOffForSalon,
  editDaysOffForWorker,
} from "../../services/daysOff";
import {
  Severity,
  useNotificationContext,
} from "../../context/NotificationContext";
import {
  StyledErrorMessage,
  StyledXButton,
} from "../calendar/reservationModal/ReservationModal.styles";

interface DaysOffModalProps {
  isModalOpen: boolean;
  handleClose: () => void;
  workerId?: number;
  title: string;
  data?: ISalonDaysOff;
}

const DaysOffModal = ({
  isModalOpen,
  handleClose,
  workerId,
  title,
  data,
}: DaysOffModalProps) => {
  const [startDate, setStartDate] = useState<Dayjs | null>(
    data?.startDate ? dayjs(data?.startDate) : null
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(
    data?.endDate ? dayjs(data?.endDate) : null
  );
  const { showNotification } = useNotificationContext();
  const [datesError, setDatesError] = useState<string | null>(null);

  const { mutate: createDaysOffForSalonMutation } = useMutation(
    createDaysOffForSalon,
    {
      onSuccess: () => {
        handleClose();
        showNotification(
          "Neradni dani salona uspesno dodati.",
          Severity.Success
        );
      },
      onError: (error) => {
        showNotification(
          "Greška prilikom kreiranja neradnih dana salona",
          Severity.Error
        );
      },
    }
  );

  const { mutate: editDaysOffForSalonMutation } = useMutation(
    editDaysOffForSalon,
    {
      onSuccess: () => {
        handleClose();
        showNotification("Novi datumi uspesno izmenjeni.", Severity.Success);
      },
      onError: (error) => {
        showNotification(
          "Greška prilikom izmene neradnih dana salona",
          Severity.Error
        );
      },
    }
  );

  const { mutate: createDaysOffForWorkerMutation } = useMutation(
    createDaysOffForWorker,
    {
      onSuccess: () => {
        handleClose();
        showNotification("Godisnji odmor uspesno kreiran", Severity.Success);
      },
      onError: (error) => {
        showNotification(
          "Greška prilikom kreiranja godisnjeg odmora",
          Severity.Error
        );
      },
    }
  );

  const { mutate: editDaysOffForWorkerMutation } = useMutation(
    editDaysOffForWorker,
    {
      onSuccess: () => {
        handleClose();
        showNotification("Godisnji odmor uspesno izmenjen", Severity.Success);
      },
      onError: (error) => {
        showNotification(
          "Greška prilikom izmene godisnjeg odmora",
          Severity.Error
        );
      },
    }
  );

  const validateDates = (startDate: Dayjs | null, endDate: Dayjs | null) => {
    if (!startDate || !endDate) {
      setDatesError("Molimo vas odaberite datum početka i kraja.");
      return true;
    }
    if (startDate.isAfter(endDate)) {
      setDatesError("Datum početka ne može biti posle datuma kraja.");
      return true;
    }
    return false;
  };

  const handleSave = () => {
    const start = dayjs(startDate).format("YYYY-MM-DD");
    const end = dayjs(endDate).format("YYYY-MM-DD");

    const validationError = validateDates(startDate, endDate);
    if (validationError) {
      return;
    }
    setDatesError(null);
    if (data) {
      handleEdit(start, end);
      return;
    }
    handleAdd(start, end);
  };

  const handleAdd = (start: string, end: string) => {
    const data = {
      startDate: start,
      endDate: end,
    };
    if (workerId) {
      createDaysOffForWorkerMutation({ ...data, workerId });
      return;
    }
    createDaysOffForSalonMutation(data);
  };

  const handleEdit = (start: string, end: string) => {
    const dataEdit = {
      newStartDate: start,
      newEndDate: end,
      oldStartDate: data?.startDate || "",
      oldEndDate: data?.endDate || "",
    };
    if (workerId && data) {
      editDaysOffForWorkerMutation({ ...dataEdit, workerId });
      return;
    }
    data && editDaysOffForSalonMutation(dataEdit);
  };

  return (
    <UniversalModal
      width={
        window.innerWidth >= 900
          ? "34.375rem"
          : window.innerWidth >= 600
          ? "60%"
          : "90%"
      }
      isModalOpen={isModalOpen}
      handleClose={handleClose}
    >
      <StyledContainer>
        <StyledHeaderContainer>
          <StyledHeaderTitle>{title}</StyledHeaderTitle>
          <StyledXButton onClick={handleClose}>
            <Xicon />
          </StyledXButton>
        </StyledHeaderContainer>
        <StyledContentContainer>
          <StyledDatePicker
            label="Od"
            value={startDate}
            onChange={(newValue) => setStartDate(newValue as Dayjs | null)}
          />
          <StyledDatePicker
            label="Do"
            value={endDate}
            onChange={(newValue) => setEndDate(newValue as Dayjs | null)}
          />
        </StyledContentContainer>
        <StyledErrorMessageContainer>
          {datesError && <StyledErrorMessage>{datesError}</StyledErrorMessage>}
        </StyledErrorMessageContainer>
        <PrimaryButton
          text={"Sačuvaj"}
          width={"100%"}
          onClick={() => handleSave()}
        />
      </StyledContainer>
    </UniversalModal>
  );
};

export default DaysOffModal;
