export const COLORS = {
  primary: "#CE4257",
  primary_light: "#D5536C",
  primary_transparent: "#ce425748",
  secondary: "#FF9B54",
  additional_secondary: "#FFC8B4",
  cloudText: "#333138",
  disabled: "#E0E3E6",
  text: "#223241",
  whiteText: "#FEFEFE",
  link: "#2D4356",
  input: "#A9384F",
  action: "",
  black: "black",
  white: "#FFFFFF",
  grey: "#AAAAAA",
  gray_border: "#BEC5CB",
  calendar_border: "#E4E4E4",
  placeholderGray: "#DEDEDE",
  success_green: "#D5F7F2",
  success_green_dark: "#008B68",
  background_light_green: "#E3F7E9",
  background_gray: "#FEFEFED9",
  background_white: "#fff",
  shadow_black: "#000",
  error_700: "#DB1F35",
  error_pink: "#FFEBEF",
  blue_100: "#5FB6FF",
  blue_200: "#0496FF",
  blue_400: "#38A6FF",
  blue_600: "#0B88F0",
  blue_700: "#0C76DD",
  light_blue: "#E3F2FF",
  gray_100: "#EEEEEE",
  gray_200: "#DEDEDE",
  gray_300: "#CCCCCC",
  gray_400: "#BBBBBB",
  gray_500: "#7B7B7B",
  gray_600: "#555657",
  gray_700: "#333138",
  gray_800: "#262728",
  gray_900: "#000103",
  light_pink: "#fff0f3",
  error_pink_background: "#F9E4E9",
  error_pink_border: "#4F000B",
  error_yellow_background: "#A9384F",
  error_yellow_border: "#FFEDC1",
  brick_50: "#F9E4E9",
  brick_100: "#F1BCC9",
  brick_300: "#DE6C84",
  brick_900: "#6E2C41",
  orange_light: "#FFEBDD",
  orange_50: "#FCE7E4",
  orange_100: "#FFC8B4",
  orange_200: "#FFA483",
  ornage_300: "#FF7F51",
  orange_400: "#FE6228",
  yellow_300: "#FFE39B",
  yellow_600: "#FFBB5A",
  yellow_800: "#FF9B54",
  white_opacity: "#FFFFFF59",
  green_100: "#77DD77",
  event_green: "#33D9B180",
  event_yellow: "#FFD95480",
  event_blue: "#54CCFF80",
  event_orange: "#FF9B5480",
  event_primary: "#CE425780",
  mediumGray: "#6D6D6D",
  mediumGray_opacity: "#6d6d6d80",
  lightGray: "#F6F6F6",
  darkGray: "#1D090C",
  baby_pink: "#fff7f9",
  medium_gray: "#969696",
};
