import { AppBar, Drawer, IconButton } from "@mui/material";
import { TOOLBAR_HEIGHT } from "../../styles/theme";
import { styled } from "@mui/material/styles";
import { COLORS } from "../../constants/colors";

export const DRAWER_WIDTH = "fit-content";

export const CLOSED_DRAWER_WITH = 60;

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer,
  background: theme.palette.common.white,
  backgroundColor: theme.palette.common.white,
  boxShadow: "none",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.up("sm")]: {
    boxShadow: "none",
    width: `calc(100% - ${CLOSED_DRAWER_WITH}px)`,
  },
}));

export const StyledAppBarShift = styled(AppBar)(({ theme }) => ({
  marginLeft: DRAWER_WIDTH,
  width: `calc(100% - ${DRAWER_WIDTH}px)`,
  boxShadow: "none",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
}));

export const StyledHideMenuButton = styled(IconButton)({
  display: "none",
});

export const StyledMenuButton = styled(IconButton)(
  ({ buttoncolor }: { buttoncolor?: string }) => ({
    marginRight: 3,
    backgroundColor: `${buttoncolor} !important`,
  })
);

export const StyledDrawer = styled(Drawer)(({ theme, open }) => {
  return {
    "& .MuiDrawer-paper": {
      flexShrink: 0,
      whiteSpace: "nowrap",
      backgroundColor: COLORS.baby_pink,
      "& .MuiGrid-container": { height: "100%" },
      zIndex: 1200,
      ...(open && {
        width: "100%",
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.up("sm")]: { width: DRAWER_WIDTH },
        "& .MuiGrid-root": { flexWrap: "nowrap" },
      }),
      ...(!open && {
        width: 0,
        transition: theme.transitions.create("width", {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: "hidden",
        [theme.breakpoints.up("sm")]: { width: CLOSED_DRAWER_WITH },
      }),
    },
  };
});

export const StyledToolBar = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  height: TOOLBAR_HEIGHT,
  minHeight: TOOLBAR_HEIGHT,
  "&.MuiChip-root": {
    fontWeight: "bold",
    color: theme.palette.common.white,
    backgroundColor: "#b712c3",
    flexGrow: 1,
  },
}));

export const StyledLogo = styled("div")({
  display: "flex",
  justifyContent: "center",
});
