import { createTheme } from "@mui/material/styles";

// declare module '@mui/styles' {
//     // eslint-disable-next-line @typescript-eslint/no-empty-interface
//     interface DefaultTheme extends Theme { }
// }

export const TOOLBAR_HEIGHT = 56;

export const TABLE_ROW_HEIGHT = 56;

const theme = createTheme({
  typography: {
    fontFamily: ["Montserrat", "serif"].join(","),
  },
});

export default theme;
