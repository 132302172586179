import React, { useState } from "react";
import { PageContainer } from "../pageContainer/PageContainer";
import {
  Container404Numbers,
  Page404Description,
  Page404Heading,
  Page404MainContainer,
  Page404Numbers,
} from "./404Page.styles";
import { StyledLogo } from "../../components/navigation/NavigationMenu.styles";
import PetifyLogo from "../../assets/svg/PetifyLogo";
import { useMediaQuery } from "@mui/material";

export const Page404 = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <PageContainer>
      <Page404MainContainer>
        <Page404Heading>Greška - Stranica nije pronađena</Page404Heading>
        <Page404Description>
          Izvinjavamo se, ali stranica koju tražite nije pronađena. Možda ste
          uneli pogrešnu adresu ili je stranica privremeno nedostupna. Molimo
          vas da proverite adresu ili pokušate ponovo kasnije.
        </Page404Description>
        <Container404Numbers>
          <Page404Numbers>4</Page404Numbers>
          <StyledLogo>
            <PetifyLogo
              width={isMobile ? "6rem" : "13.5rem"}
              height={isMobile ? "6rem" : "13.5rem"}
            />
          </StyledLogo>
          <Page404Numbers>4</Page404Numbers>
        </Container404Numbers>
      </Page404MainContainer>
    </PageContainer>
  );
};
