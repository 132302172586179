import { Validated } from "../Validator";

export default function isFilledNumber<TError>(
  value: string,
  error: TError
): Validated<string, TError> {
  const trimmedValue = value?.trim();

  if (trimmedValue === "") {
    return {
      isValid: false,
      error,
    };
  }

  const firstChar = trimmedValue.charAt(0);

  if (isNaN(Number(firstChar)) || firstChar === "0") {
    return {
      isValid: false,
      error,
    };
  }

  return {
    isValid: true,
    value: trimmedValue,
  };
}
