import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers";
import { COLORS } from "../../constants/colors";

export const StyledDateTimeBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "2rem",
  marginTop: "1rem",
});

export const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  borderRadius: "0.5625rem",
  backgroundColor: COLORS.lightGray,
  border: "none",
  width: "100%",

  "& .MuiOutlinedInput-notchedOutline ": {
    border: "none !important",
  },

  "& .Mui-focused": {
    color: COLORS.darkGray,
  },

  [theme.breakpoints.down("md")]: {
    "& .MuiInputBase-input": {
      fontSize: "0.9rem",
    },
  },
}));

export const StyledTimePickerWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: "0.5rem",

  "& .MuiFormControl-root": {
    borderRadius: "0.5625rem",
    backgroundColor: COLORS.lightGray,
  },

  "& .MuiOutlinedInput-notchedOutline ": {
    border: "none !important",
  },

  [theme.breakpoints.down("md")]: {
    gap: "0.5rem",

    "& .MuiInputBase-input": {
      padding: "0.7rem 0.8rem",
      fontSize: "0.9rem",
      width: "45%",
    },
  },
}));
