export const notificationMessages = [
  {
    key: 1,
    message: "Možete dovesti ljubimca za 15 min",
  },
  {
    key: 2,
    message: "Možete dovesti ljubimca za 30 min",
  },
  {
    key: 3,
    message: "Možete dovesti ljubimca za 60 min",
  },
  {
    key: 4,
    message: "Možete doći po ljubimca za 15 min",
  },
  {
    key: 5,
    message: "Možete doći po ljubimca za 30 min",
  },
  {
    key: 6,
    message: "Možete doći po ljubimca za 60 min",
  },
];
