import React from "react";
import PetifyLogo from "../../assets/svg/PetifyLogo";
import { LoaderContainer, LogoWrapper } from "./Loader.styles";
import { useMediaQuery } from "@mui/material";

const LoaderWithLogo = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const logoSize = isMobile ? "5rem" : "7rem";

  return (
    <LoaderContainer>
      <LogoWrapper>
        <PetifyLogo width={logoSize} height={logoSize} />
      </LogoWrapper>
    </LoaderContainer>
  );
};

export default LoaderWithLogo;
