import React, { useEffect, useState } from "react";
import UniversalModal from "../../modal/UniversalModal";
import { ReactComponent as Xicon } from "../../../assets/X-ico.svg";
import {
  StyledErrorMessage,
  StyledXButton,
} from "../reservationModal/ReservationModal.styles";
import {
  StyledNotifyClientModule,
  StyledNotifyFormActionsWrapper,
  StyledNotifyMenuItem,
  StyledNotifyModuleHeader,
  StyledNotifyModuleHeading,
} from "./NotifyClientModule.styles";
import {
  StyledCalendarEmptyButton,
  StyledCalendarFullButton,
} from "../Calendar.styles";
import { StyledDropdown } from "../../dropdown/Dropdown.styles";
import { useMutation } from "react-query";
import { postNotificationStatus } from "../../../services/notifyAboutStatus/notifyAboutStatus";
import { notificationMessages } from "../../../constants/notificationMessages";
import {
  Severity,
  useNotificationContext,
} from "../../../context/NotificationContext";
import { InputField } from "../../inputs/TextField";

interface NotifyClientModalProps {
  modalIsOpen: boolean;
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedEvent: React.Dispatch<React.SetStateAction<any>>;
  event: any;
}

const NotifyClientModal = ({
  modalIsOpen,
  setModalIsOpen,
  setSelectedEvent,
  event,
}: NotifyClientModalProps) => {
  let defaultCompletedEvent = {
    appointmentId: event?.appointmentId,
    status: "default",
    note: "",
  };

  const [completedEvent, setCompletedEvent] = useState(defaultCompletedEvent);
  const [statusError, setStatusError] = useState(false);
  const { showNotification } = useNotificationContext();

  const { mutate: postNotificationStatusMutation } = useMutation(
    postNotificationStatus,
    {
      onSuccess: (data) => {
        showNotification("Notifikacija uspesno poslata", Severity.Success);
      },
      onError: (error) => {
        showNotification("Greška prilikom slanja notifikacije", Severity.Error);
      },
    }
  );

  useEffect(() => {
    setCompletedEvent((prev) => ({
      ...prev,
      appointmentId: event?.appointmentId,
    }));
  }, [event]);

  const closeModal = () => {
    setModalIsOpen(false);
    setCompletedEvent(defaultCompletedEvent);
    setSelectedEvent(undefined);
  };

  const handleChange = (field: string, value: any) => {
    setStatusError(false);

    setCompletedEvent((prev) => ({ ...prev, [field]: value }));
  };

  const validateForm = () => {
    let newStatusError = false;
    if (
      completedEvent.status === "default" &&
      completedEvent.note.trim().length <= 0
    ) {
      newStatusError = true;
    }

    setStatusError(newStatusError);
    return newStatusError;
  };

  const populateNotification = () => {
    if (
      completedEvent.status === "default" &&
      completedEvent.note.trim().length !== 0
    ) {
      postNotificationStatusMutation({
        appointmentId: completedEvent.appointmentId,
        bodyText: completedEvent.note,
      });
    } else if (
      completedEvent.status !== "default" &&
      completedEvent.note.trim().length <= 0
    ) {
      postNotificationStatusMutation({
        appointmentId: completedEvent.appointmentId,
        bodyText: completedEvent.status,
      });
    } else {
      postNotificationStatusMutation({
        appointmentId: completedEvent.appointmentId,
        bodyText: `${completedEvent.status}, uz poruku: ${completedEvent.note}`,
      });
    }
  };

  const hanldeSubmit = (e) => {
    e.preventDefault();
    validateForm();

    const errors = validateForm();
    if (!errors) {
      populateNotification();
      closeModal();
    }
  };

  return (
    <UniversalModal isModalOpen={modalIsOpen} handleClose={closeModal}>
      <StyledNotifyClientModule>
        <StyledNotifyModuleHeader>
          <StyledNotifyModuleHeading>
            Obavesti klijenta
          </StyledNotifyModuleHeading>
          <StyledXButton onClick={closeModal}>
            <Xicon />
          </StyledXButton>
        </StyledNotifyModuleHeader>
        <form>
          {statusError && (
            <StyledErrorMessage>Molimo odaberite status</StyledErrorMessage>
          )}
          <StyledDropdown
            value={completedEvent.status}
            onChange={(e) => handleChange("status", e.target.value)}
          >
            <StyledNotifyMenuItem value={"default"} disabled>
              <em>Status</em>
            </StyledNotifyMenuItem>
            {notificationMessages.map((message) => (
              <StyledNotifyMenuItem key={message.key} value={message.message}>
                {message.message}
              </StyledNotifyMenuItem>
            ))}
          </StyledDropdown>
          <InputField
            multiline
            value={completedEvent.note}
            minRows={window.innerWidth <= 600 ? 3 : 4}
            label="Napomena"
            onChange={(e) => handleChange("note", e.target.value)}
          />
          <StyledNotifyFormActionsWrapper>
            <StyledCalendarFullButton onClick={hanldeSubmit}>
              Sačuvaj i obavesti
            </StyledCalendarFullButton>
            <StyledCalendarEmptyButton onClick={closeModal}>
              Poništi
            </StyledCalendarEmptyButton>
          </StyledNotifyFormActionsWrapper>
        </form>
      </StyledNotifyClientModule>
    </UniversalModal>
  );
};

export default NotifyClientModal;
