import React, { useState } from "react";
import { PageContainer } from "../pageContainer/PageContainer";
import {
  ButtonContainer,
  ContentContainer,
  CountReservations,
  CustomTabs,
  FieldsContainer,
  HeaderContainer,
  HeadingAnalytics,
} from "./AnalyticsPage.styles";
import { StyledDatePicker } from "../../components/dateTimePicker/DateTimePicker.styles";
import dayjs, { Dayjs } from "dayjs";
import DropdownField from "../../components/dropdownOnboarding/Dropdown";
import { PrimaryButton } from "../../components/buttons/primaryButton/PrimaryButton";
import { useMediaQuery } from "@mui/material";
import AnalyticsTable from "../../components/dataGridTable/AnalyticsTable";
import {
  AppointmentInfo,
  getAppointmentsInfo,
  getServicesInfo,
} from "../../services/analytics";
import Tab from "@mui/material/Tab";
import AnalyticsServicesChart from "../../components/charts/AnalyticsServicesChart";

export const creationModes = [
  { value: 2, label: "Petify rezervacije" },
  { value: 1, label: "Rucne rezervacije" },
  { value: 3, label: "Sve" },
];

export const cancelationModes = [
  { value: 2, label: "Validne" },
  { value: 1, label: "Otkazane" },
  { value: 3, label: "Sve" },
];

export const AnalyticsPage = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);
  const [creationMode, setCreationMode] = useState<number>(3);
  const [cancelationMode, setCancelationMode] = useState<number>(3);
  const [searchedData, setSearchedData] = useState<AppointmentInfo[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState(0);
  const [servicesData, setServicesData] = useState<any>();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setStartDate(null);
    setEndDate(null);
    setCreationMode(0);
    setCancelationMode(3);
    setServicesData(undefined);
  };

  const handleSearchReservations = async () => {
    try {
      setIsLoading(true);
      const data = await getAppointmentsInfo(
        dayjs(startDate).format("YYYY-MM-DD"),
        dayjs(endDate).format("YYYY-MM-DD"),
        creationMode && creationMode - 1,
        cancelationMode && cancelationMode - 1
      );
      setSearchedData(data?.result);
    } catch (error) {
      console.error("Error fetching appointments info:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchServices = async () => {
    try {
      setIsLoading(true);
      const data = await getServicesInfo(
        dayjs(startDate).format("YYYY-MM-DD"),
        dayjs(endDate).format("YYYY-MM-DD"),
        cancelationMode && cancelationMode - 1
      );
      setServicesData(data?.result);
    } catch (error) {
      console.error("Error fetching appointments info:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageContainer>
      <>
        <HeaderContainer>
          <HeadingAnalytics>Analitika</HeadingAnalytics>
        </HeaderContainer>
        <ContentContainer>
          <CustomTabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="analytics tabs"
          >
            <Tab label="Rezervacije" />
            <Tab label="Usluge" />
          </CustomTabs>

          <FieldsContainer>
            <StyledDatePicker
              label="Pocetni datum"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue as Dayjs | null)}
            />
            <StyledDatePicker
              label="Krajnji datum"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue as Dayjs | null)}
            />
            {tabValue === 0 && (
              <DropdownField
                label={"Nacin rezervacije"}
                options={creationModes.map((item) => ({
                  value: item.value,
                  label: item.label,
                }))}
                value={creationMode || ""}
                onChange={(event) =>
                  setCreationMode(Number(event.target.value))
                }
                style={{ width: "100%" }}
              />
            )}
            <DropdownField
              label={"Tip rezervacije"}
              options={cancelationModes.map((item) => ({
                value: item.value,
                label: item.label,
              }))}
              value={cancelationMode || ""}
              onChange={(event) =>
                setCancelationMode(Number(event.target.value))
              }
              style={{ width: "100%" }}
            />
          </FieldsContainer>
          <ButtonContainer
            style={{
              justifyContent: searchedData ? "space-between" : "end",
            }}
          >
            {searchedData && tabValue === 0 && (
              <CountReservations>
                Ukupan broj rezervacija prema selektovanim parametrima je:{" "}
                {searchedData.length}
              </CountReservations>
            )}
            <PrimaryButton
              text={"Pretrazi"}
              width={isMobile ? "100%" : "15%"}
              onClick={
                tabValue === 0 ? handleSearchReservations : handleSearchServices
              }
              inProgress={isLoading}
            />
          </ButtonContainer>
          {tabValue === 0 && (
            <AnalyticsTable searchedData={searchedData || []} />
          )}
          {tabValue === 1 && servicesData && (
            <AnalyticsServicesChart searchedData={servicesData || []} />
          )}
        </ContentContainer>
      </>
    </PageContainer>
  );
};
