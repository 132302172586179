import apiClient from "../api/api";
import { NOTIFY_STATUS } from "../routes/routes";

export const postNotificationStatus = async ({
  appointmentId,
  bodyText,
}: {
  appointmentId: number;
  bodyText: string;
}) => {
  const { data } = await apiClient.post(NOTIFY_STATUS, {
    appointmentId,
    bodyText,
  });
  return data;
};
