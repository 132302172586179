import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources from './public/resources';

const i18nOptions: InitOptions = {
    resources,
    saveMissing: true,
    missingKeyHandler(lngs, _ns, key) {
        throw new Error(`Missing key "${key}" for locales: ${lngs.join(', ')}`);
    },
    interpolation: { escapeValue: false },
    react: { useSuspense: true }, // Replace 'wait' with 'useSuspense'
};

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n
    .use(initReactI18next)
    .init(i18nOptions);

export default i18n;
