import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

import { COLORS } from "../../../constants/colors";

export const StyledSecondaryButton = styled(Button)(({ theme }) => ({
  borderRadius: "0.5625rem",
  height: "56px",
  fontSize: "1rem",
  color: `${COLORS.primary} !important`,
  borderColor: `${COLORS.primary} !important`,
  "&:hover": {
    backgroundColor: COLORS.white,
    outline: `0.00625rem solid ${COLORS.primary}`,
  },
}));
