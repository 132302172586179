import { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import AddIcon from "@mui/icons-material/Add";
import {
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Radio,
} from "@mui/material";

import {
  DropdownContainer,
  StyledButtomContainer,
  StyledSelect,
} from "./CustomDropdown.style";
import { IconButton } from "../buttons/iconButton/IconButton";
import { COLORS } from "../../constants/colors";
import { IDropdownType } from "../../pages/onBoarding/Step4";

export const defaultDropdownOption = {
  key: -1,
  value: "",
};
interface CustomDropdownProps {
  options: IDropdownType[];
  label: string | null;
  onSelect: (newSelectedOption: IDropdownType) => void;
  openModal: () => void;
  selectLast: any;
  error?: boolean;
  onBlur?: () => boolean;
  onFocus?: () => void;
  autoSelectOption?: IDropdownType;
}

const CustomDropdown = ({
  options,
  label,
  onSelect,
  openModal,
  selectLast,
  error,
  onFocus,
  onBlur,
  autoSelectOption,
}: CustomDropdownProps) => {
  const [selectedOption, setSelectedOption] = useState<IDropdownType>(
    autoSelectOption ? autoSelectOption : defaultDropdownOption
  );

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    const selectedKey = options.find(
      (option) => option.value === selectedValue
    )?.key;

    const newSelectedOption: IDropdownType = {
      key: selectedKey || -1,
      value: selectedValue,
    };

    setSelectedOption(newSelectedOption);

    onSelect(newSelectedOption);
  };

  useEffect(() => {
    if (autoSelectOption) setSelectedOption(autoSelectOption);
  }, [autoSelectOption]);

  useEffect(() => {
    if (selectLast) {
      setSelectedOption(selectLast);
    }
  }, [selectLast]);

  return (
    <DropdownContainer>
      <FormControl fullWidth>
        <InputLabel
          style={{
            color: error ? COLORS.primary : COLORS.gray_500,
          }}
        >
          {label}
        </InputLabel>
        <StyledSelect
          onFocus={onFocus}
          onBlur={onBlur}
          error={error}
          defaultValue={selectLast}
          style={{
            borderRadius: "0.5625rem",
            fontSize: "1rem",
            backgroundColor: COLORS.lightGray,
            border: `none`,
          }}
          value={selectedOption.value || ""}
          onChange={handleSelectChange}
          label={label}
          renderValue={(selected: any) => selected}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: "18.75rem",
              },
            },
          }}
        >
          <StyledButtomContainer>
            <IconButton onClick={() => openModal()}>
              <AddIcon
                style={{
                  color: COLORS.white,
                }}
              />
            </IconButton>
            <div style={{ marginLeft: "0.688rem" }}>
              <p>Nova grupa</p>
            </div>
          </StyledButtomContainer>
          {options.map((option) => (
            <MenuItem key={option.key} value={option.value}>
              <ListItemIcon>
                <Radio
                  checked={selectedOption.key === option.key}
                  style={{ color: COLORS.primary }}
                />
              </ListItemIcon>
              <ListItemText primary={option.value} />
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </DropdownContainer>
  );
};

export default CustomDropdown;
