import React from "react";

import { StyledSecondaryButton } from "./SecondaryButton.styles";

interface SecondaryButtonProps {
  text: string;
  onClick: () => void;
  width?: number | string;
}

export const SecondaryButton = ({
  text,
  onClick,
  width,
}: SecondaryButtonProps) => {
  return (
    <StyledSecondaryButton
      variant="outlined"
      onClick={onClick}
      sx={{ width: width || "26rem" }}
    >
      {text}
    </StyledSecondaryButton>
  );
};
