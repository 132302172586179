import { Box, Paper, styled } from "@mui/material";
import { DataGrid, DataGridProps } from "@mui/x-data-grid";

import { COLORS } from "../../constants/colors";

export const StyledBox = styled(Box)(({ theme }) => ({
  width: "100%",
  borderRadius: 9,
  [theme.breakpoints.down("sm")]: {},
}));
export const StyledTableContainer = styled("div")(({ theme }) => ({
  width: "100%",
  borderRadius: 9,
}));

export const StyledPaper = styled(Paper)({
  boxShadow: "none",
  borderColor: COLORS.white,
  "& .MuiDataGrid-root": { borderColor: COLORS.white },
});

interface StyledDataGridProps extends DataGridProps {
  justifyContent?: string;
}

export const StyledDataGrid = styled(DataGrid)<StyledDataGridProps>(({ theme, justifyContent }) => ({
  "& .MuiDataGrid-cell:focus": {
    outline: "none",
  },
  "& .MuiDataGrid-cell--withRenderer.MuiDataGrid-cell.MuiDataGrid-cell--textLeft.MuiDataGrid-withBorderColor" : {
    justifyContent: justifyContent || 'end',
  },
  "& .MuiDataGrid-overlay": {
    color: COLORS.white,
  },
  "& .MuiDataGrid-row:nth-child(even)": {
    backgroundColor: COLORS.lightGray,
  },
  "& .MuiDataGrid-row:nth-child(odd)": {
    backgroundColor: COLORS.white,
  },
  "& .MuiDataGrid-columnHeaders": {
    borderBottom: `solid 0rem ${COLORS.white}`,
    backgroundColor: COLORS.lightGray,
  },
  "& .MuiDataGrid-columnHeader": {
    color: COLORS.darkGray,
    fontSize: "1rem",
  },
  "& .MuiDataGrid-cell": {
    color: COLORS.darkGray,
    fontSize: "0.875rem",
    display: "flex",
  },
  "& .MuiDataGrid-cell[data-field='actions']:focus-within": {
    outline: "none !important",
  },
  "& .MuiDataGrid-withBorderColor": {
    borderColor: COLORS.white,
  },
  "& .expanded-row .MuiDataGrid-cell": {
    padding: "1rem",
    alignItems: "flex-start",
  },
  [theme.breakpoints.down("sm")]: {
    "& .MuiDataGrid-row:nth-child(even)": {
      backgroundColor: COLORS.white,
    },
    "& .MuiDataGrid-row:nth-child(odd)": {
      backgroundColor: COLORS.lightGray,
    },
    "& .expanded-row .MuiDataGrid-cell": {
      alignItems: "flex-start",
    },
  },
}));

export const Separator = styled("hr")({
  width: "100%",
  margin: "0.5rem 0",
  color: COLORS.mediumGray,
});

export const TimeTableCol = styled("div")({
  display: "flex",
  justifyContent: "start",
  width: "100%"
});

export const TableContainerMobile = styled("div")({
  marginTop: "1rem",
  marginBottom: "1rem",
})

export const ActionsMobile = styled("div")({
  display: "flex",
  flexDirection: "column",
});
