import React, { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMutation } from "react-query";

import { PrimaryButton } from "../../components/buttons/primaryButton/PrimaryButton";
import {
  StepperHeader,
  StepperSubHeader,
  StyledSupportButton,
} from "../../constants/customStyles";
import { COLORS } from "../../constants/colors";
import { InputField } from "../../components/inputs/TextField";
import {
  CreatedService,
  OnboardingMainContainerStep4,
  ColumnContainer,
  ServicesText,
} from "./Step4.styles";
import CustomDropdown from "../../components/dropdown/CustomDropdown";
import CreateGroupModal from "../../components/modal/CreateGroupModal";
import {
  createServiceGroup,
  useServiceGroups,
  createServices,
} from "../../services/services";
import { SecondaryButton } from "../../components/buttons/secondaryButton/SecondaryButton";
import DropdownField from "../../components/dropdownOnboarding/Dropdown";
import { hours, minutes } from "./Step4.helper";
import { IconButton } from "../../components/buttons/iconButton/IconButton";
import isFilledString from "../../helpers/inputValidation/validators/isFilledString";
import { InputError } from "../../helpers/inputValidation/InputError";
import allValid from "../../helpers/inputValidation/validators/allValid";
import isFilledNumber from "../../helpers/inputValidation/validators/isFilledNumber";
import { useNavigate } from "react-router-dom";
import { IS_ONBOARDED } from "../../constants/localStorageKeys";
import { HELP } from "../../constants/urlPaths";

export interface IServiceInfo {
  serviceGroupId: number;
  name: string;
  priceAmount: number;
  duration: string;
  description: string;
}

export interface IDropdownType {
  key: number;
  value: string;
}

export const Step4 = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:1200px)");
  const [group, setGroup] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [price, setPrice] = useState<string>("");
  const [selectedHours, setSelectedHours] = useState<string>("");
  const [selectedMinutes, setSelectedMinutes] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const [groups, setGroups] = useState<IDropdownType[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [serviceInfos, setServiceInfos] = useState<IServiceInfo[]>([]);

  const [lastSelectedOption, setLastSelectedOption] = useState<IDropdownType>();
  const [isCheckedNewGroup, setIsCheckedNewGroup] = useState<boolean>(false);

  const [groupError, setGroupError] = useState<InputError | null>(null);
  const [nameError, setNameError] = useState<InputError | null>(null);
  const [priceError, setPriceError] = useState<InputError | null>(null);

  //api get services groups
  const { data, refetch: refetchServiceGroupMutation } = useServiceGroups();
  useEffect(() => {
    if (data) {
      setGroups(data.result ?? []);
      if (isCheckedNewGroup) {
        setLastSelectedOption({
          key: data.result[data.result.length - 1].key,
          value: data.result[data.result.length - 1].value,
        });
        setGroup(data.result[data.result.length - 1].key);
      }
    }
  }, [data]);

  //api create service group
  const { mutate: createGroupMutation } = useMutation(createServiceGroup, {
    onSuccess: (data) => {
      refetchServiceGroupMutation();
    },
    onError: (error) => {
      console.error("Greška prilikom prijavljivanja grupe:", error);
    },
  });

  const handleSaveGroup = (newGroupName: string, checkGroup: boolean) => {
    createGroupMutation({ name: newGroupName });
    setIsCheckedNewGroup(checkGroup);
    closeModal();
  };
  //api create services
  const { mutate: createServicesListMutation } = useMutation(createServices, {
    onSuccess: (data) => {
      localStorage.setItem(IS_ONBOARDED, "true");
      navigate("/");
    },
    onError: (error) => {
      console.error("Greška prilikom prijavljivanja servisa:", error);
    },
  });

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleAddNewGroup = () => {
    if (validateFormFields()) {
      const hours = selectedHours ? `${selectedHours}:` : "00:";
      const minutes = selectedMinutes ? `${selectedMinutes}:` : "00:";
      const newServiceInfo = {
        serviceGroupId: Number(group),
        name: name,
        priceAmount: Number(price),
        duration: `${hours}${minutes}00`,
        description: description,
      };

      setServiceInfos((prevServiceInfos) => [
        ...prevServiceInfos,
        newServiceInfo,
      ]);

      setName("");
      setPrice("");
      setDescription("");
    }
  };

  const groupedServiceInfos: {
    serviceGroupId: number;
    services: IServiceInfo[];
  }[] = serviceInfos.reduce((acc, service) => {
    const groupIndex = acc.findIndex(
      (group) => group.serviceGroupId === service.serviceGroupId
    );
    if (groupIndex !== -1) {
      acc[groupIndex].services.push(service);
    } else {
      acc.push({ serviceGroupId: service.serviceGroupId, services: [service] });
    }

    return acc;
  }, [] as { serviceGroupId: number; services: IServiceInfo[] }[]);

  const findGroupName = (groupId: number) => {
    const group = groups.find((option) => option.key === groupId);
    return group ? group.value : "";
  };

  const handelDeleteService = (index: number) => {
    const updatedServices = [...serviceInfos];
    updatedServices.splice(index, 1);
    setServiceInfos(updatedServices);
  };

  const validateFormFields = (): boolean => {
    const validated = {
      group: isFilledString(group, InputError.EmptyServiceGroup),
      name: isFilledString(name, InputError.EmptyServiceName),
      price: isFilledNumber(price, InputError.EmptyPrice),
    };
    if (!allValid(validated)) {
      setGroupError(validated.group.isValid ? null : validated.group.error);
      setNameError(validated.name.isValid ? null : validated.name.error);
      setPriceError(validated.price.isValid ? null : validated.price.error);
      return false;
    }
    setGroupError(null);
    setNameError(null);
    setPriceError(null);
    return true;
  };

  const handlePriceChange = (event) => {
    const priceValue = event.target.value;

    if (
      /^\d*\.?\d*$/.test(priceValue) &&
      (priceValue === "" || priceValue[0] !== "0")
    ) {
      setPrice(priceValue);
    }
  };

  const finishBoarding = () => {
    createServicesListMutation(serviceInfos);
  };

  return (
    <OnboardingMainContainerStep4
      style={{
        flexDirection: isMobile ? "column-reverse" : "row",
      }}
    >
      <StyledSupportButton onClick={() => navigate(HELP)} />
      <div
        style={{
          ...(!isMobile && { width: "30%" }),
        }}
      >
        {serviceInfos.length > 0 && (
          <ColumnContainer>
            <p>Dodate usluge</p>
            {groupedServiceInfos?.map((group, groupIndex) => (
              <div key={groupIndex}>
                <ServicesText>
                  {findGroupName(group.serviceGroupId)}
                </ServicesText>
                {group.services?.map((service, index) => (
                  <CreatedService key={index}>
                    <span>{service.name}</span>
                    <IconButton
                      style={{ backgroundColor: COLORS.orange_light }}
                      onClick={() => handelDeleteService(index)}
                    >
                      <DeleteIcon
                        style={{
                          color: COLORS.secondary,
                          fontSize: isMobile ? "1rem" : "1.5rem",
                        }}
                      />
                    </IconButton>
                  </CreatedService>
                ))}
              </div>
            ))}
          </ColumnContainer>
        )}
      </div>

      <ColumnContainer
        style={{
          ...(!isMobile && { width: "32%" }),
          marginTop: "1.25rem",
        }}
      >
        <StepperHeader>Korak 4/4</StepperHeader>
        <StepperSubHeader>Usluge</StepperSubHeader>

        {isModalOpen && (
          <CreateGroupModal
            isModalOpen={isModalOpen}
            handleClose={closeModal}
            onSave={handleSaveGroup}
          />
        )}
        <div
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              ...(isMobile && {
                padding: "10px",
                width: "330px",
              }),
            }}
          >
            <ColumnContainer>
              <CustomDropdown
                options={groups}
                label={Boolean(groupError) ? groupError : "Grupa"}
                onSelect={(selected) => {
                  setGroup(selected.key.toString());
                }}
                openModal={openModal}
                selectLast={lastSelectedOption}
                error={Boolean(groupError)}
                onFocus={() => setGroupError(null)}
                onBlur={() => validateFormFields()}
              />
              <InputField
                label={Boolean(nameError) ? nameError : "Ime usluge"}
                placeholder="Ime usluge"
                value={name}
                onChange={(event) => setName(event.target.value)}
                error={Boolean(nameError)}
                onBlur={() => validateFormFields()}
                onFocus={() => setNameError(null)}
              />
              <InputField
                label={Boolean(priceError) ? priceError : "Cena"}
                placeholder="Cena"
                value={price}
                onChange={handlePriceChange}
                error={Boolean(priceError)}
                onBlur={() => validateFormFields()}
                onFocus={() => setPriceError(null)}
              />
              <div style={{ display: "flex", width: "100%" }}>
                <DropdownField
                  style={{ width: "12.938rem", marginRight: "0.625rem" }}
                  label={"Sati"}
                  options={hours?.map((hour) => ({
                    value: hour.value,
                    label: hour.value,
                  }))}
                  value={selectedHours || ""}
                  onChange={(event) => setSelectedHours(event.target.value)}
                />
                <DropdownField
                  style={{ width: "12.938rem" }}
                  label={"Minuti"}
                  options={minutes?.map((minutes) => ({
                    value: minutes.value,
                    label: minutes.value,
                  }))}
                  value={selectedMinutes || ""}
                  onChange={(event) => setSelectedMinutes(event.target.value)}
                />
              </div>

              <InputField
                label="Opis"
                multiline
                rows={4}
                placeholder="Opis"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <div
                style={{
                  marginTop: isMobile ? "1rem" : "2.375rem",
                  marginBottom: "1.188rem",
                }}
              >
                <PrimaryButton
                  text={"Sacuvaj i dodaj jos jedan servis"}
                  onClick={handleAddNewGroup}
                  width={isMobile ? "21rem" : "26.5625rem"}
                />
              </div>
              <div
                style={{
                  marginBottom: "2.063rem",
                }}
              >
                <SecondaryButton
                  text={"Zavrsi"}
                  onClick={finishBoarding}
                  width={isMobile ? "21rem" : "26.5625rem"}
                />
              </div>
            </ColumnContainer>
          </div>
        </div>
      </ColumnContainer>
    </OnboardingMainContainerStep4>
  );
};
