import { useMediaQuery } from "@mui/material";
import { PageContainer } from "../pageContainer/PageContainer";
import { HeaderContainerWithBottomMargin } from "../services/Services.styles";
import { HeaderPage } from "../../constants/customStyles";
import { PrimaryButton } from "../../components/buttons/primaryButton/PrimaryButton";
import DaysOffModal from "../../components/modal/DaysOffModal";
import { useState } from "react";
import { useDaysOfForSalon, useDaysOfForWorker } from "../../services/daysOff";
import { IS_WORKER } from "../../constants/localStorageKeys";
import SalonDaysOffTable from "../../components/dataGridTable/SalonDaysOffTable";
import WorkerDaysOffTable from "../../components/dataGridTable/WorkersDaysOffTable";
import LoaderWithLogo from "../../components/loader/loader";

const DaysOff = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [isDaysOffModalOpen, setIsDaysOffModalOpen] = useState<boolean>(false);
  const isWorker = JSON.parse(localStorage.getItem(IS_WORKER) ?? "false");

  const { isLoading, refetch } = useDaysOfForSalon();

  const handleDaysOffModal = (open: boolean) => {
    setIsDaysOffModalOpen(open);
  };

  return (
    <PageContainer>
      {isLoading ? (
        <LoaderWithLogo></LoaderWithLogo>
      ) : (
        <>
          <HeaderContainerWithBottomMargin>
            <HeaderPage>Neradni dani</HeaderPage>
            {!isWorker && (
              <PrimaryButton
                width={isMobile ? 125 : 255}
                text="Dodaj godisnji odmor"
                onClick={() => handleDaysOffModal(true)}
                fontSize={isMobile ? "0.75rem" : "1rem"}
              />
            )}
          </HeaderContainerWithBottomMargin>
          <WorkerDaysOffTable />
          <SalonDaysOffTable />
          {isDaysOffModalOpen && (
            <DaysOffModal
              isModalOpen={isDaysOffModalOpen}
              handleClose={() => {
                handleDaysOffModal(false);
                refetch();
              }}
              title="Neradni dani salona"
            />
          )}
        </>
      )}
    </PageContainer>
  );
};

export default DaysOff;
