import { styled } from "@mui/material/styles";
import { COLORS } from "../../constants/colors";
import { Box } from "@mui/material";

export const StyledContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "1.25rem",
  width: "100%",
});

export const StyledHeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: "15px",

  [theme.breakpoints.down("sm")]: {
    marginBottom: "0"
  },
}));

export const StyledHeaderTitle = styled("span")(({ theme }) => ({
  color: COLORS.darkGray,
  fontSize: "1.5rem",
  fontWeight: "500",

  [theme.breakpoints.down("sm")]: {
    fontSize: "1.125rem"
  },
}));

export const StyledFormFieldsContainer = styled("div")({
  width: "100%"
});

export const StyledFormButtonsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1.25rem",
  width: "100%",
  marginTop: "15px",

  [theme.breakpoints.down("sm")]: {
    marginTop: "0"
  },
}));

export const StyledModalFieldsDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "15px",
  width: "100%",
});
