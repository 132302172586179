import React from "react";
import { PageContainer } from "./pageContainer/PageContainer";

const Home = () => {
  return (
    <PageContainer>
      <div>
        <div>evo ga home</div>
      </div>
    </PageContainer>
  );
};

export default Home;
