import { useQuery } from "react-query";
import apiClient from "./api/api";
import { DAYS_OFF_ALL, DAYS_OFF_SALON, DAYS_OFF_WORKER } from "./routes/routes";
import { DAYS_OFF_ALL_KEY, DAYS_OFF_SALON_KEY } from "../constants/queryKeys";

export interface ISalonDaysOff {
  startDate: string;
  endDate: string;
}

export interface IChangeDays {
  workerId?: number;
  newStartDate: string;
  newEndDate: string;
  oldStartDate: string;
  oldEndDate: string;
}

export interface IWorkerDaysOff {
  workerId: number;
  startDate: string;
  endDate: string;
}

export const createDaysOffForSalon = async (daysOff: ISalonDaysOff) => {
  const { data } = await apiClient.post(DAYS_OFF_SALON, daysOff);
  return data;
};

export const deleteDaysOffForSalon = async (data: ISalonDaysOff) => {
    await apiClient.delete(DAYS_OFF_SALON, {data})
}

export const editDaysOffForSalon = async (days: IChangeDays) => {
  const { data } = await apiClient.put(DAYS_OFF_SALON, days);
  return data;
};

export const createDaysOffForWorker = async (daysOff: IWorkerDaysOff) => {
  const { data } = await apiClient.post(DAYS_OFF_WORKER, daysOff);
  return data;
};

export const editDaysOffForWorker = async (days: IChangeDays) => {
  const { data } = await apiClient.put(DAYS_OFF_WORKER, days);
  return data;
};

export const deleteDaysOffForWorker = async (data: IWorkerDaysOff) => {
    await apiClient.delete(DAYS_OFF_WORKER, {data})
}

export const getDaysOfForSalon = async () => {
    const { data } = await apiClient.get(DAYS_OFF_SALON);
    return data;
}

export const useDaysOfForSalon = (enabled = true) => {
  const { data, isLoading, isError, isFetching, refetch, isSuccess } = useQuery(
    [DAYS_OFF_SALON_KEY],
    () => getDaysOfForSalon(),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled,
    }
  );

  return {
    data: data ?? [],
    isLoading,
    isError,
    isFetching,
    refetch,
    isSuccess
  };
};

export const getDaysOfForWorker = async (workerId: number) => {
    const { data } = await apiClient.get( `${DAYS_OFF_WORKER}?workerId=${workerId}`);
    return data;
}

export const useDaysOfForWorker = (workerId) => {
  const { data, isLoading, isError, isFetching, refetch } = useQuery(
    [`days-off-worker-${workerId}`],
    () => getDaysOfForWorker(workerId),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  return {
    data: data || [],
    isLoading,
    isError,
    isFetching,
    refetch,
  };
};

export const getDaysOfForAllWorkers = async () => {
    const { data } = await apiClient.get(DAYS_OFF_ALL);
    return data;
}

export const useDaysOfForAll = (enabled = true) => {
  const { data, isLoading, isError, isFetching, refetch, isSuccess } = useQuery(
    [DAYS_OFF_ALL_KEY],
    () => getDaysOfForAllWorkers(),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled,
    }
  );

  return {
    data: data ?? [],
    isLoading,
    isError,
    isFetching,
    refetch,
    isSuccess
  };
};
