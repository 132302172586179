import React from "react";

import { StyledLinkButton } from "./LinkButton.styles";

interface LinkButtonProps {
  text: string;
  onClick: () => void;
}

export const LinkButton = ({ text, onClick }: LinkButtonProps) => {
  return (
    <StyledLinkButton variant={"text"} onClick={onClick}>
      {text}
    </StyledLinkButton>
  );
};
