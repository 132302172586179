import styled from "@emotion/styled";
import { MenuItem, Select } from "@mui/material";

export const StyledDropdown = styled(Select)({
  padding: 0,
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  height: "100%",
  backgroundColor: "#F6F6F6",
  borderRadius: "0.5625rem",

  "@media (max-width: 600px)": {
    height: "45px",
  },

  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },

  "& .MuiSelect-select": {
    "& .MuiButtonBase-root": {
      textTransform: "capitalize",
      fontSize: "16px",
      color: "#6D6D6D",
      padding: "0"
    },
  },

  "& .css-3qbkez-MuiSelect-icon": {
    position: "absolute",
    top: "27%",

     "@media (max-width: 600px)": {
      top: "25%",
  },
  },

  "& .MuiMenu-paper ": {
    backgroundColor: "#F6F6F6",
  },
});

export const StyledMenuItem = styled(MenuItem)({
  textTransform: "capitalize",
  backgroundColor: "#F6F6F6",
});
