import { styled } from "@mui/system";
import { COLORS } from "../../constants/colors";

export const SupportMainContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  gap: "5rem",
  height:"100%",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column-reverse",
    gap: "1rem",
  },
}));

export const ImageSupport = styled("img")(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
    padding: "5rem",
    width: "450px",

    [theme.breakpoints.down("sm")]: {
    padding: "0",
    width: "100px",
  },
}));

export const SupportContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "1rem",
  padding: "3rem",
  width: "70%",
  height:"100%",
  marginTop: "10%",

  [theme.breakpoints.down("sm")]: {
    padding: "1rem",
    width: "100%",
    marginTop: "0",
  },
}));

export const SupportHeading = styled("h1")(({ theme }) => ({
  color: COLORS.primary,
  margin: "0",
  fontSize: "2rem",

  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
}));

export const SupportDescription = styled("h1")(({ theme }) => ({
  margin: "0",
  fontSize: "1rem",
  fontWeight: "500",

  [theme.breakpoints.down("sm")]: {
    fontSize: "0.5rem",
  },
}));

export const SupportEmail = styled("a")(({ theme }) => ({
  color: `${COLORS.primary}!important`,
  textDecoration: "none!important",
    cursor: "pointer!important",
}));
