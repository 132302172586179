import React, { useState } from "react";
import { PageContainer } from "../pageContainer/PageContainer";
import { useMediaQuery } from "@mui/material";
import { Page404Description, Page404Heading } from "../404Page/404Page.styles";
import {
  ImageSupport,
  SupportContentContainer,
  SupportDescription,
  SupportEmail,
  SupportHeading,
  SupportMainContainer,
} from "./SupportPage.styles";
import ServiceImage from "../../assets/customer-service.png";
import { COLORS } from "../../constants/colors";

export const SupportPage = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <>
      <SupportMainContainer>
        <SupportContentContainer>
          <SupportHeading>Potrebna vam je podrška?</SupportHeading>
          <SupportDescription>
            Naša misija je da budemo tu za vas, pružajući podršku koja vam je
            potrebna. Bez obzira da li imate pitanja o povratnim informacijama
            našeg sistema ili se suočavate s bilo kakvim izazovima, naš tim za
            podršku je na raspolaganju. Dozvolite nam da vam pružimo podršku na
            putu ka uspehu!
          </SupportDescription>
          <SupportDescription>
            Email adresa podrške:{" "}
            <SupportEmail
              href="mailto:petifyserbia@gmail.com"
              style={{ color: COLORS.primary }}
            >
              petifyserbia@gmail.com
            </SupportEmail>
          </SupportDescription>
        </SupportContentContainer>
        <ImageSupport src={ServiceImage} alt="Support" />
      </SupportMainContainer>
    </>
  );
};
