import apiClient from "./api/api";
import { CITIES, CITY_PARTS, GENERAL_INFO, IMAGES } from "./routes/routes";

export const getCities = async () => {
  try {
    const response = await apiClient.get(CITIES);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getCityParts = async (cityId: number) => {
  try {
    const response = await apiClient.get(CITY_PARTS(cityId));
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addSalonGeneralInfo = async ({
  name,
  address,
  cityId,
  cityPartId,
  country,
  mobilePhone,
  pib,
  coverImage,
}: {
  name: string;
  address: string;
  cityId: number;
  cityPartId: number;
  country: string;
  mobilePhone: string;
  pib: string;
  coverImage: string;
}) => {
  const requestData = {
    name,
    address,
    cityId,
    cityPartId,
    country,
    mobilePhone,
    pib,
    coverImage,
  };

  const { data } = await apiClient.post(GENERAL_INFO, requestData);
  return data;
};

export const addSalonImages = async ({
  description,
  images,
}: {
  description?: string;
  images: string[];
}) => {
  const requestData = {
    description,
    images,
  };

  const { data } = await apiClient.post(IMAGES, requestData);
  return data;
};
