import React, { useState } from "react";
import { useMediaQuery } from "@mui/material";

import { PageContainer } from "../pageContainer/PageContainer";
import { HeaderPage } from "../../constants/customStyles";
import { PrimaryButton } from "../../components/buttons/primaryButton/PrimaryButton";
import CreateEditServiceModal from "../../components/modal/CreateEditServiceModal";
import { HeaderContainer } from "./Services.styles";
import ServicesTable, {
  IServiceGroup,
} from "../../components/dataGridTable/ServicesTable";
import { useServicesByGroup } from "../../services/services";
import LoaderWithLogo from "../../components/loader/loader";

const Services = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const { refetch, isLoading } = useServicesByGroup();
  const [isCreateEditServiceModalOpen, setIsCreateEditServiceModalOpen] =
    useState(false);

  const openServiceModal = () => {
    setIsCreateEditServiceModalOpen(true);
  };

  const closeServiceModal = () => {
    refetch();
    setIsCreateEditServiceModalOpen(false);
  };

  return (
    <PageContainer>
      {isLoading ? (
        <LoaderWithLogo></LoaderWithLogo>
      ) : (
        <>
          <HeaderContainer>
            <HeaderPage>Usluge</HeaderPage>
            <PrimaryButton
              width={isMobile ? 125 : 255}
              text="Dodaj novu uslugu"
              onClick={openServiceModal}
              fontSize={isMobile ? "0.75rem" : "1rem"}
            />
          </HeaderContainer>
          <ServicesTable />
          {isCreateEditServiceModalOpen && (
            <CreateEditServiceModal
              isModalOpen={isCreateEditServiceModalOpen}
              handleClose={() => closeServiceModal()}
              service={undefined}
            />
          )}
        </>
      )}
    </PageContainer>
  );
};

export default Services;
