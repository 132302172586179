import React from "react";
import { styled } from "@mui/system";
import { Select } from "@mui/material";

import { COLORS } from "../../constants/colors";

export const DropdownContainer = styled("div")(({ theme }) => ({
  display: "flex",
  border: `0rem solid ${COLORS.lightGray}`,
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
  borderRadius: "0.5625rem",
  fontSize: "1rem",
  fontFamily: "Poppins",
  backgroundColor: COLORS.lightGray,

  "&:focus": {
    borderColor: COLORS.primary,
    outline: `0.00625rem solid ${COLORS.primary}`,
  },
   "& .MuiOutlinedInput-notchedOutline": {
    border: "none !important",
  },
}));
