import { Navigate } from "react-router-dom";

import { IS_ONBOARDED } from "../../constants/localStorageKeys";
import { CALENDAR } from "../../constants/urlPaths";

interface ProtectedOnboardRouteProps {
  redirectPath?: string;
  children: JSX.Element;
}

export const ProtectedOnboardRoute = ({
  redirectPath = CALENDAR,
  children,
}: ProtectedOnboardRouteProps) => {
  const isOnboardedUser = JSON.parse(
    localStorage.getItem(IS_ONBOARDED) ?? "false"
  );

  if (isOnboardedUser) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};
