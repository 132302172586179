import React from "react";

import { StyledPrimaryButton } from "./PrimaryButton.styles";
import { CircularProgress, circularProgressClasses } from "@mui/material";
import { COLORS } from "../../../constants/colors";

interface PrimaryButtonProps {
  text: string;
  onClick: () => void;
  width?: number | string;
  fontSize?: string;
  inProgress?: boolean;
}

export const PrimaryButton = ({
  text,
  onClick,
  width,
  fontSize,
  inProgress,
}: PrimaryButtonProps) => {
  return (
    <StyledPrimaryButton
      variant={"contained"}
      onClick={onClick}
      sx={{ width: width || "26rem" }}
      fontSize={fontSize}
    >
      {inProgress ? (
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: COLORS.secondary,
            animationDuration: "550ms",
          }}
          size={40}
          thickness={4}
        />
      ) : (
        text
      )}
    </StyledPrimaryButton>
  );
};
