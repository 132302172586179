import React, { useState } from "react";
import { Checkbox, Typography, useMediaQuery } from "@mui/material";
import { IconButton } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import DeleteIcon from "@mui/icons-material/Delete";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import dayjs from "dayjs";

import { LinkButton } from "../../components/buttons/linkButton/LinkButton";
import { PrimaryButton } from "../../components/buttons/primaryButton/PrimaryButton";
import {
  OnboardingCenterContainer,
  OnboardingMainContainer,
} from "./Onboarding.styles";
import {
  StepperHeader,
  StepperSubHeader,
  StyledSupportButton,
} from "../../constants/customStyles";
import { COLORS } from "../../constants/colors";
import { SalonWorkingDay } from "./types/step2";
import { addSalonsWorkingHours } from "../../services/salons";
import {
  CustomSwitch,
  DayContainer,
  Dot,
  FormControlLabelStyled,
  IconButtonStyled,
  InfoContainer,
  SameDaysDivStyled,
  Separator,
  TimeDivStyled,
} from "./Step2.styles";
import { HELP } from "../../constants/urlPaths";

function parseScheduleWithSecondShift(scheduleObj) {
  let parsedSchedule: SalonWorkingDay[] = [];
  for (const day in scheduleObj) {
    if (scheduleObj[day].isIncluded) {
      const dayOfWeek = scheduleObj[day].dayOfWeek;
      const firstShiftOpeningTime = scheduleObj[day].firstShift.startTime
        ?.format("YYYY-MM-DDTHH:mm:ss")
        .split("T")[1];
      const firstShiftClosingTime = scheduleObj[day].firstShift.endTime
        ?.format("YYYY-MM-DDTHH:mm:ss")
        .split("T")[1];

      const secondShiftOpeningTime = scheduleObj[day].secondShift.startTime
        ?.format("YYYY-MM-DDTHH:mm:ss")
        .split("T")[1];
      const secondShiftClosingTime = scheduleObj[day].secondShift.endTime
        ?.format("YYYY-MM-DDTHH:mm:ss")
        .split("T")[1];

      parsedSchedule.push({
        dayOfWeek: dayOfWeek,
        openingTime: firstShiftOpeningTime,
        closingTime: firstShiftClosingTime,
      });

      if (secondShiftOpeningTime && secondShiftClosingTime) {
        parsedSchedule.push({
          dayOfWeek: dayOfWeek,
          openingTime: secondShiftOpeningTime,
          closingTime: secondShiftClosingTime,
        });
      }
    }
  }

  return parsedSchedule;
}

const formatTime = (startTime, endTime) => {
  const localStartTime = dayjs(startTime).format("YYYY-MM-DDTHH:mm:ss");
  const localEndTime = dayjs(endTime).format("YYYY-MM-DDTHH:mm:ss");
  const timeStart = startTime
    ? localStartTime.split("T")[1].split(":").slice(0, 2).join(":")
    : "";
  const timeEnd = endTime
    ? localEndTime.split("T")[1].split(":").slice(0, 2).join(":")
    : "";
  return startTime && endTime && `${timeStart} - ${timeEnd}`;
};

const createInitialDayInfo = (name: string, dayOfWeek: string) => ({
  isOpenTime: false,
  isIncluded: false,
  isSecondShiftOpen: false,
  firstShift: {
    startTime: null,
    endTime: null,
  },
  secondShift: {
    startTime: null,
    endTime: null,
  },
  labelName: name,
  dayOfWeek,
});

const Step2 = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const navigate = useNavigate();
  const [schedule, setSchedule] = useState({
    mon: createInitialDayInfo("Ponedeljak", "Monday"),
    tue: createInitialDayInfo("Utorak", "Tuesday"),
    wed: createInitialDayInfo("Sreda", "Wednesday"),
    thu: createInitialDayInfo("Cetvrtak", "Thursday"),
    fri: createInitialDayInfo("Petak", "Friday"),
    sat: createInitialDayInfo("Subota", "Saturday"),
    sun: createInitialDayInfo("Nedelja", "Sunday"),
  });

  const { mutate: addSalonsWorkingHoursMutation } = useMutation(
    addSalonsWorkingHours,
    {
      onSuccess: (data) => {
        navigate("/onboard/galery");
        // handle notification
      },
      onError: (error) => {
        // handle notification
      },
    }
  );

  const handleValueChange = (day, key) => {
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [day]: {
        ...prevSchedule[day],
        [key]: !prevSchedule[day][key],
        isOpenTime:
          key === "isIncluded"
            ? !prevSchedule[day]["isIncluded"]
            : key === "isOpenTime"
            ? !prevSchedule[day]["isOpenTime"]
            : prevSchedule[day]["isOpenTime"],
        isSecondShiftOpen:
          key === "isSecondShiftOpen"
            ? true
            : prevSchedule[day]["isSecondShiftOpen"],
      },
    }));
  };

  const handleShiftTime = (day, shift, partOfShift, newStartTime) => {
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [day]: {
        ...prevSchedule[day],
        [shift]: {
          ...prevSchedule[day][shift],
          [partOfShift]: newStartTime,
        },
      },
    }));
  };

  const handleSameForAllDays = (day, isChecked) => {
    const newSchedule = { ...schedule };
    if (isChecked) {
      Object.keys(newSchedule).forEach((key) => {
        newSchedule[key] = {
          ...schedule[day],
          labelName: newSchedule[key].labelName,
          dayOfWeek: newSchedule[key].dayOfWeek,
          isOpenTime: key === "mon" ? true : false,
        };
      });
      setSchedule(newSchedule);
    }
  };

  const handleDeleteShift = (day) => {
    setSchedule((prevSchedule) => ({
      ...prevSchedule,
      [day]: {
        ...prevSchedule[day],
        isSecondShiftOpen: false,
        secondShift: {
          startTime: null,
          endTime: null,
        },
      },
    }));
  };

  const handleSubmit = () => {
    const parsedSchedule = parseScheduleWithSecondShift(schedule);
    addSalonsWorkingHoursMutation(parsedSchedule);
  };

  return (
    <OnboardingMainContainer>
      <StyledSupportButton onClick={() => navigate(HELP)} />
      <OnboardingCenterContainer>
        <StepperHeader>Korak 2/4</StepperHeader>
        <StepperSubHeader>Radno vreme</StepperSubHeader>
        <InfoContainer>
          {Object.entries(schedule).map(([day, dayInfo]) => {
            return (
              <DayContainer key={day}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <FormControlLabelStyled
                    value={dayInfo.isIncluded}
                    control={
                      <CustomSwitch
                        checked={dayInfo.isIncluded}
                        onChange={() => handleValueChange(day, "isIncluded")}
                      />
                    }
                    label={dayInfo.labelName}
                  />
                  <Typography>
                    {formatTime(
                      dayInfo.firstShift.startTime,
                      dayInfo.firstShift.endTime
                    )}
                  </Typography>
                  {dayInfo.secondShift.startTime &&
                    dayInfo.secondShift.endTime && (
                      <>
                        <Dot />
                        <Typography>
                          {formatTime(
                            dayInfo.secondShift.startTime,
                            dayInfo.secondShift.endTime
                          )}
                        </Typography>
                      </>
                    )}
                  <IconButton
                    style={{ color: COLORS.primary, cursor: "pointer" }}
                    onClick={() => handleValueChange(day, "isOpenTime")}
                  >
                    {dayInfo.isOpenTime ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </IconButton>
                </div>
                {dayInfo.isOpenTime && (
                  <>
                    <TimeDivStyled>
                      <TimePicker
                        value={dayInfo.firstShift.startTime}
                        onChange={(newValue) =>
                          handleShiftTime(
                            day,
                            "firstShift",
                            "startTime",
                            newValue
                          )
                        }
                        ampm={false}
                      />
                      <Separator>do</Separator>
                      <TimePicker
                        value={dayInfo.firstShift.endTime}
                        onChange={(newValue) =>
                          handleShiftTime(
                            day,
                            "firstShift",
                            "endTime",
                            newValue
                          )
                        }
                        ampm={false}
                      />
                      <IconButton>
                        <DeleteIcon
                          style={{
                            color: COLORS.primary,
                            fontSize: "1.5rem",
                            visibility: "hidden",
                          }}
                        />
                      </IconButton>
                    </TimeDivStyled>
                    {dayInfo.isSecondShiftOpen && (
                      <TimeDivStyled>
                        <TimePicker
                          value={dayInfo.secondShift.startTime}
                          onChange={(newValue) =>
                            handleShiftTime(
                              day,
                              "secondShift",
                              "startTime",
                              newValue
                            )
                          }
                          ampm={false}
                        />
                        <Separator>do</Separator>
                        <TimePicker
                          value={dayInfo.secondShift.endTime}
                          onChange={(newValue) =>
                            handleShiftTime(
                              day,
                              "secondShift",
                              "endTime",
                              newValue
                            )
                          }
                          ampm={false}
                        />
                        <IconButton onClick={() => handleDeleteShift(day)}>
                          <DeleteIcon
                            style={{
                              color: COLORS.primary,
                              fontSize: "1.5rem",
                            }}
                          />
                        </IconButton>
                      </TimeDivStyled>
                    )}
                    <SameDaysDivStyled isSameDay={day === "mon"}>
                      {day === "mon" && (
                        <FormControlLabelStyled
                          control={
                            <Checkbox
                              value={dayInfo.isIncluded}
                              onChange={(event) =>
                                handleSameForAllDays(day, event.target.checked)
                              }
                            />
                          }
                          label="Isto za sve dane"
                        />
                      )}
                      <IconButtonStyled
                        onClick={() =>
                          handleValueChange(day, "isSecondShiftOpen")
                        }
                      >
                        <AddOutlinedIcon
                          style={{ fontSize: "1.5rem", color: COLORS.white }}
                        />
                      </IconButtonStyled>
                    </SameDaysDivStyled>
                  </>
                )}
              </DayContainer>
            );
          })}
        </InfoContainer>
        <div style={{ marginTop: "1.875rem" }}>
          <PrimaryButton
            text={"Sledeći korak"}
            width={isMobile ? "20rem" : "30rem"}
            onClick={handleSubmit}
          />
        </div>
        <div style={{ marginBottom: "1.25rem" }}>
          <LinkButton text={"Preskoči"} onClick={() => {}} />
        </div>
      </OnboardingCenterContainer>
    </OnboardingMainContainer>
  );
};

export default Step2;
