import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { styled } from "@mui/material/styles";
import { NavLink } from "react-router-dom";
import { COLORS } from "../../constants/colors";
import { isMatchPath } from "../../helpers/isMatchPath";

const StyledNavLink = styled(NavLink)({
  textDecoration: "none",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const StyledListItem = styled(ListItemButton)({
  padding: "1rem",
  "&:hover": { background: `${COLORS.brick_50} !important` },
  "&.Mui-selected": {
    backgroundColor: "inherit",
    "& > div": {
      color: COLORS.primary,
      "&  > span": {
        color: COLORS.primary,
      },
    },
  },
});

const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: "2.8rem",
  "& .MuiSvgIcon-root": {
    fontSize: "2rem",
  },
});

const StyledListItemText = styled(ListItemText)({
  fontWeight: 600,
  "& > div": { paddingLeft: ".1rem" },
});

interface MenuListItemProps {
  icon: JSX.Element;
  label: string;
  href?: string;
  onClick?: () => void;
}

const MenuListItem = ({ icon, label, href, onClick }: MenuListItemProps) => {
  const isCurrentPath = href ? isMatchPath(href) : false;

  return (
    <StyledNavLink to={href || ""}>
      <StyledListItem onClick={onClick} selected={isCurrentPath}>
        <StyledListItemIcon>{icon}</StyledListItemIcon>
        <StyledListItemText>{label}</StyledListItemText>
      </StyledListItem>
    </StyledNavLink>
  );
};

export default MenuListItem;
