import React, { useState } from "react";
import { useMediaQuery } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import UniversalModal from "../../../components/modal/UniversalModal";
import { InputField } from "../../../components/inputs/TextField";
import { COLORS } from "../../../constants/colors";
import { PrimaryButton } from "../../../components/buttons/primaryButton/PrimaryButton";
import { SecondaryButton } from "../../../components/buttons/secondaryButton/SecondaryButton";
import DropdownField from "../../../components/dropdownOnboarding/Dropdown";
import {
  ModalHeader,
  StyledAddIcon,
  StyledFormButtonsWrapper,
  StyledIconContainer,
  StyledModalHeaderwrapper,
  StyledWorkerModalWrapper,
  StyledWorkerShiftText,
} from "./WorkerModal.styles";
import { StyledXButton } from "../../../components/calendar/reservationModal/ReservationModal.styles";
import { ReactComponent as Xicon } from "../../../assets/X-ico.svg";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import WorkerShiftModal from "../workerShiftModal/WorkerShiftModal";
import { useMutation } from "react-query";
import { postNewWorker, putWorker } from "../../../services/workers";
import {
  Severity,
  useNotificationContext,
} from "../../../context/NotificationContext";

interface AddWorkerModalProps {
  isModalOpen: boolean;
  handleClose: () => void;
  employeeData: any;
  setEmployeeData: any;
  refetchTable: () => void;
}

const WorkerDataModal = ({
  isModalOpen,
  handleClose,
  employeeData,
  setEmployeeData,
  refetchTable,
}: AddWorkerModalProps) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const [isShiftModalOpen, setIsShiftModalOpen] = useState<boolean>(false);
  const { showNotification } = useNotificationContext();

  const { mutate: postNewWorkerMutation } = useMutation(postNewWorker, {
    onSuccess: (data) => {
      showNotification("Radnik uspesno dodat", Severity.Success);
      refetchTable();
    },
    onError: (error) => {
      showNotification("Greška prilikom dodavanja radnika", Severity.Error);
    },
  });

  const { mutate: putWorkerMutation } = useMutation(putWorker, {
    onSuccess: (data) => {
      showNotification("Radnik uspesno izmenjen", Severity.Success);
      refetchTable();
    },
    onError: (error) => {
      showNotification("Greška prilikom izmenjivanja radnika", Severity.Error);
    },
  });

  const handleChange = (field, value) => {
    setEmployeeData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSubmit = () => {
    if (employeeData.id === -1) {
      const newWorker = {
        email: employeeData.email,
        fullName: employeeData.fullName,
        workerType: employeeData.workerType,
        workingHours: employeeData.workingHours,
      };

      postNewWorkerMutation(newWorker);
    } else {
      const editedWorker = {
        id: employeeData.id,
        fullName: employeeData.fullName,
        workerType: employeeData.workerType,
        workingHours: employeeData.workingHours,
      };

      putWorkerMutation(editedWorker);
    }
    handleClose();
  };

  return (
    <UniversalModal
      width={
        window.innerWidth >= 900
          ? "34.375rem"
          : window.innerWidth >= 600
          ? "60%"
          : "90%"
      }
      isModalOpen={isModalOpen}
      handleClose={handleClose}
    >
      <StyledWorkerModalWrapper>
        <StyledModalHeaderwrapper>
          <ModalHeader>
            {employeeData.id !== -1 ? "Izmeni radnika" : "Dodaj radnika"}
          </ModalHeader>
          <StyledXButton onClick={handleClose}>
            <Xicon />
          </StyledXButton>
        </StyledModalHeaderwrapper>
        <div style={{ width: "100%" }}>
          <DropdownField
            label={"Tip zapolsenja"}
            options={[
              { value: "Worker", label: "Zaposlen" },
              { value: "Assistant", label: "Asistent" },
            ]}
            value={employeeData?.workerType}
            onChange={(e) => handleChange("workerType", e.target.value)}
          />
        </div>
        <div style={{ width: "100%" }}>
          <InputField
            disabled={employeeData.id !== -1}
            label="Email adresa"
            placeholder="Email adresa"
            value={employeeData?.email}
            onChange={(e) => handleChange("email", e.target.value)}
          />
        </div>
        <div style={{ width: "100%" }}>
          <InputField
            label="Ime i prezime"
            placeholder="Ime i prezime"
            value={employeeData?.fullName}
            onChange={(e) => handleChange("fullName", e.target.value)}
          />
        </div>
        <StyledIconContainer onClick={() => setIsShiftModalOpen(true)}>
          {employeeData?.workingHours[0]?.openingTime.length ? (
            <>
              <BorderColorIcon
                style={{
                  color: COLORS.yellow_800,
                  fontSize: "1.125rem",
                  marginRight: "1rem",
                  marginTop: "0.15rem",
                }}
              />
              <StyledWorkerShiftText>Izmeni radno vreme</StyledWorkerShiftText>
            </>
          ) : (
            <>
              <StyledAddIcon>
                <AddIcon style={{ fontSize: 18, color: COLORS.white }} />
              </StyledAddIcon>
              <StyledWorkerShiftText>Dodaj radno vreme</StyledWorkerShiftText>
            </>
          )}
        </StyledIconContainer>

        <StyledFormButtonsWrapper>
          <PrimaryButton
            text={"Sačuvaj"}
            width={"100%"}
            onClick={handleSubmit}
          />
          <SecondaryButton
            text={"Otkaži"}
            width={"100%"}
            onClick={handleClose}
          />
        </StyledFormButtonsWrapper>
        <WorkerShiftModal
          isOpen={isShiftModalOpen}
          setOpenState={setIsShiftModalOpen}
          employeeData={employeeData?.workingHours}
          setEmployeeData={setEmployeeData}
        />
      </StyledWorkerModalWrapper>
    </UniversalModal>
  );
};

export default WorkerDataModal;
