import React, { Children, useEffect, useState } from "react";
import { StyledDropdown, StyledMenuItem } from "./Dropdown.styles";
import { ReactComponent as CaretDown } from "../../assets/caret-down.svg";

interface DropdownComponentProps {
  selectValue: string;
  children: any;
}

const Dropdown = ({ children, selectValue }: DropdownComponentProps) => {
  const [selectedValue, setSelectedValue] = useState<string>(
    children.find((child) => child.props.value === selectValue)?.props.children
  );

  useEffect(() => {
    setSelectedValue(
      children.find((child) => child.props.value === selectValue)?.props
        .children
    );
  }, [selectValue]);

  const handleSelect = (value) => {
    setSelectedValue(value);
  };

  return (
    <StyledDropdown value={selectedValue} IconComponent={CaretDown}>
      {Children.map(children, (child) => (
        <StyledMenuItem
          value={child.props.children}
          onClick={() => handleSelect(child.props.children)}
          disabled={child.props.disabled}
        >
          {child}
        </StyledMenuItem>
      ))}
    </StyledDropdown>
  );
};

export default Dropdown;
