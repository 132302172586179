/* eslint-disable  react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Calendar as BigCalendar, dayjsLocalizer } from "react-big-calendar";
import {
  StyledCalendarHeaderWrapper,
  StyledCalendarHeadingContainer,
  StyledNavButtonsWrapper,
  StyledCalendarHeaderDropdownAndBtnWrapper,
  StyledNavButton,
  StyledCalendarHeading,
  StyledCalendarMonthName,
  StyledViewButton,
  StyledCalendarComponentWrapper,
  StyledCalendarContainer,
  StyledCalendarFullButton,
  StyledCalendarEmptyButton,
  StyledDropdownContainer,
  StyledCalendarEmptyButtonDisabled,
} from "./Calendar.styles";
import { ReactComponent as ArrowLeft } from "../../assets/Arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../assets/Arrow-right.svg";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/sr";
import Dropdown from "../dropdown/Dropdown";
import ReservationModal from "./reservationModal/ReservationModal";
import { ViewUnits, eventStyleGetter, getMinOpeningAndMaxClosingTimes, getViewUnit } from "./calendarHelpers";
import { DATE_FORMATS, TIME_FORMATS } from "../../constants/dateFormats";
import EventModal from "./eventModal/EventModal";
import NotifyClientModal from "./notifyClientModal/NotifyClientModal";
import { useSalonAppointments } from "../../services/appointments/appointments";
import { Appointment } from "../../types/Types";
import { useDaysOfForSalon } from "../../services/daysOff";
import LoaderWithLogo from "../loader/loader";
import { useSalonWorkingHours } from "../../services/salons";

const Calendar = () => {
  dayjs.locale("sr");
  const localizer = dayjsLocalizer(dayjs);
  localizer.formats.timeGutterFormat = "HH";
  const [view, setView] = useState<string>(ViewUnits.month);
  const [currentDate, setCurrentDate] = useState<Dayjs>(dayjs());
  const [monthFirstDate, setMonthFirstDate] = useState<string>(
    `${dayjs(currentDate).year()}.${dayjs(currentDate).month() + 1}.${dayjs(
      currentDate
    )
      .startOf("month")
      .date()}`
  );
  const [monthLastDate, setMonthLastDate] = useState<string>(
    `${dayjs().year()}.${dayjs().month() + 1}.${dayjs().endOf("month").date()}`
  );
  const [selectedEvent, setSelectedEvent] = useState<Appointment | undefined>(
    undefined
  );
  const [formModalIsOpen, setFormModalIsOpen] = useState<boolean>(false);
  const [eventModalIsOpen, setEventModalIsOpen] = useState<boolean>(false);
  const [notifyClientModalIsOpen, setNotifyClientModalIsOpen] =
    useState<boolean>(false);
  const [minOpeningTime, setMinOpeningTime] = useState('07:00am');
  const [maxClosingTime, setMaxOpeningTime]  = useState('19:00pm');
  const calendarRef = useRef();
  const { data: dataDaysOffSalon } = useDaysOfForSalon();

  useEffect(() => {
    refetch();
  }, [monthFirstDate, monthLastDate]);

  const { data, isLoading, refetch } = useSalonAppointments(
    monthFirstDate,
    monthLastDate
  );

  const {
    data: dataHours,
  } = useSalonWorkingHours();

  useEffect(() => {
    if(dataHours?.result !== undefined && dataHours?.isSuccess){
    const { minOpeningTime, maxClosingTime } = getMinOpeningAndMaxClosingTimes( dataHours?.result);
    setMinOpeningTime(`${minOpeningTime}am`)
    setMaxOpeningTime(`${maxClosingTime}pm`)
  }
  }, [dataHours])


  const handleViewChange = (newView) => {
    setView(newView);
  };

  const handleTodayClick = () => {
    setView(ViewUnits.day);
    setCurrentDate(dayjs());
    getCurrentStartEndDate(dayjs());
  };

  const getCurrentStartEndDate = (newDate) => {
    setMonthFirstDate(
      `${dayjs(newDate).year()}.${dayjs(newDate).month() + 1}.${dayjs(newDate)
        .startOf("month")
        .date()}`
    );
    setMonthLastDate(
      `${dayjs(newDate).year()}.${dayjs(newDate).month() + 1}.${dayjs(newDate)
        .endOf("month")
        .date()}`
    );
  };

  const handlePrevClick = () => {
    setCurrentDate((prevDate) => prevDate.subtract(1, getViewUnit(view)));
    getCurrentStartEndDate(currentDate.subtract(1, getViewUnit(view)));
  };

  const handleNextClick = () => {
    setCurrentDate((prevDate) => prevDate.add(1, getViewUnit(view)));
    getCurrentStartEndDate(currentDate.add(1, getViewUnit(view)));
  };

  const getWeekNumber = (date) => {
    return date.week() - date.startOf("month").week() + 1;
  };

  const handleNavigate = (date) => {
    setCurrentDate(dayjs(date));
  };

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setEventModalIsOpen(true);
  };

  const handleCloseEvent = (e) => {
    if (!e.target.classList.contains("rbc-event-content")) {
      setSelectedEvent(undefined);
    }
  };

  const openModal = () => {
    setFormModalIsOpen(true);
  };

  const closeReservationModal = () => {
    setEventModalIsOpen(false);
    refetch();
  };

  const dayPropGetter = (date) => {
    let style = {};
    const isDayOff = dataDaysOffSalon?.result?.some((item) => {
      const startDate = dayjs(item?.startDate).startOf("day").toDate();
      const endDate = dayjs(item?.endDate).endOf("day").toDate();
      const currentDate = dayjs(date).startOf("day").toDate();
      return currentDate >= startDate && currentDate <= endDate;
    });

    if (isDayOff) {
      style = {
        backgroundColor: "#EEEEEE",
      };
    }
    return {
      style: style,
    };
  };

  return (
    <>
      {isLoading ? (
        <LoaderWithLogo></LoaderWithLogo>
      ) : (
        <StyledCalendarComponentWrapper>
          <StyledCalendarHeaderWrapper>
            <StyledCalendarHeadingContainer>
              <StyledCalendarHeading>Termini</StyledCalendarHeading>
              {currentDate.format("DD.MM.YYYY") ===
                dayjs().format("DD.MM.YYYY") && view === "day" ? (
                <StyledCalendarEmptyButtonDisabled>
                  Danas
                </StyledCalendarEmptyButtonDisabled>
              ) : (
                <StyledCalendarEmptyButton onClick={handleTodayClick}>
                  Danas
                </StyledCalendarEmptyButton>
              )}
            </StyledCalendarHeadingContainer>
            <StyledCalendarHeaderDropdownAndBtnWrapper>
              <StyledDropdownContainer>
                <Dropdown selectValue={view}>
                  <StyledViewButton
                    value={"month"}
                    onClick={() => handleViewChange(ViewUnits.month)}
                  >
                    Mesecni Prikaz
                  </StyledViewButton>
                  <StyledViewButton
                    value={"week"}
                    onClick={() => handleViewChange(ViewUnits.week)}
                  >
                    Nedeljni Prikaz
                  </StyledViewButton>
                  <StyledViewButton
                    value={"day"}
                    onClick={() => handleViewChange(ViewUnits.day)}
                  >
                    Dnevni Prikaz
                  </StyledViewButton>
                </Dropdown>
              </StyledDropdownContainer>
              {window.innerWidth < 600 ? null : (
                <StyledCalendarFullButton onClick={openModal}>
                  Rucno Zakazivanje
                </StyledCalendarFullButton>
              )}
            </StyledCalendarHeaderDropdownAndBtnWrapper>
          </StyledCalendarHeaderWrapper>
          <StyledNavButtonsWrapper>
            <StyledNavButton onClick={handlePrevClick}>
              <ArrowLeft />
            </StyledNavButton>
            <StyledCalendarMonthName>
              {view === "month"
                ? currentDate.format(DATE_FORMATS["MMMM YYYY"])
                : view === "week"
                ? `
              ${currentDate.format(DATE_FORMATS["MMMM YYYY"])}
              , Nedelja ${getWeekNumber(currentDate)}`
                : currentDate.format(DATE_FORMATS["dddd, D MMMM YYYY"])}
            </StyledCalendarMonthName>
            <StyledNavButton onClick={handleNextClick}>
              <ArrowRight />
            </StyledNavButton>
          </StyledNavButtonsWrapper>
          <StyledCalendarContainer
            onMouseDown={handleCloseEvent}
            ref={calendarRef}
            className={view === "day" ? "dayViewContainer" : ""}
          >
            <BigCalendar
              localizer={localizer}
              events={data}
              views={[ViewUnits.month, ViewUnits.week, ViewUnits.day]}
              onView={handleViewChange}
              view={view}
              date={currentDate.toDate()}
              onNavigate={handleNavigate}
              onSelectEvent={handleEventClick}
              toolbar={false}
              min={dayjs(minOpeningTime, TIME_FORMATS["h:mma"]).toDate()}
              max={dayjs(maxClosingTime, TIME_FORMATS["h:mma"]).toDate()}
              startAccessor="start"
              endAccessor="end"
              dayPropGetter={dayPropGetter}
              eventPropGetter={(event) => eventStyleGetter(event, data, view)}
            />
          </StyledCalendarContainer>
        </StyledCalendarComponentWrapper>
      )}
      <ReservationModal
        modalIsOpen={formModalIsOpen}
        setModalIsOpen={setFormModalIsOpen}
        refetch={refetch}
      />
      <EventModal
        modalIsOpen={eventModalIsOpen}
        setNotifyClientModalIsOpen={setNotifyClientModalIsOpen}
        eventData={selectedEvent}
        handleClose={() => closeReservationModal()}
      />
      <NotifyClientModal
        modalIsOpen={notifyClientModalIsOpen}
        setModalIsOpen={setNotifyClientModalIsOpen}
        event={selectedEvent}
        setSelectedEvent={setSelectedEvent}
      />
    </>
  );
};

export default Calendar;
