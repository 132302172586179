import { ChangeEvent } from "react";
import { convertDataUrlToBase64 } from "../pages/onBoarding/Step3.helper";

export const handleImageUpload = (
  handlefilechange: (event: ChangeEvent<HTMLInputElement>) => void, isMultipleUpload: boolean
) => {
  const input: HTMLInputElement = document.createElement("input");
  input.type = "file";
  input.accept = "image/*";
  input.multiple = isMultipleUpload;
  input.addEventListener("change", (event: Event) => {
    handlefilechange(event as unknown as ChangeEvent<HTMLInputElement>);
  });
  input.click();
};

type SetImagesFunction = React.Dispatch<React.SetStateAction<string[]>>;

export const handleFileChange = (event: ChangeEvent<HTMLInputElement>, setImagesFunction: SetImagesFunction) => {
  const files = event.target.files as FileList;
  if (files && files.length > 0) {
    Array.from(files).forEach((file) => {
      const reader = new FileReader();
      
      reader.onload = () => {
        const imageUrl = reader.result?.toString() || "";
        setImagesFunction((prevImages) => [
          ...prevImages,
          convertDataUrlToBase64(imageUrl),
        ]);
      };

      reader.readAsDataURL(file);
    });
  }
};