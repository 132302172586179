import { useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import UniversalModal from "./UniversalModal";
import { InputField } from "../inputs/TextField";
import { PrimaryButton } from "../buttons/primaryButton/PrimaryButton";
import CustomDropdown from "../dropdown/CustomDropdown";
import DropdownField from "../dropdownOnboarding/Dropdown";
import { IDropdownType } from "../../pages/onBoarding/Step4";
import { InputError } from "../../helpers/inputValidation/InputError";
import { hours, minutes } from "../../pages/onBoarding/Step4.helper";
import isFilledString from "../../helpers/inputValidation/validators/isFilledString";
import isFilledNumber from "../../helpers/inputValidation/validators/isFilledNumber";
import allValid from "../../helpers/inputValidation/validators/allValid";
import CreateGroupModal from "./CreateGroupModal";
import { ReactComponent as Xicon } from "../../assets/X-ico.svg";
import {
  createServiceGroup,
  createServices,
  editService,
  useServiceGroups,
} from "../../services/services";
import { SecondaryButton } from "../buttons/secondaryButton/SecondaryButton";
import {
  StyledContainer,
  StyledHeaderContainer,
  StyledHeaderTitle,
  StyledModalFieldsDiv,
} from "./CreateEditServiceModal.styles";
import { IServiceGroup } from "../dataGridTable/ServicesTable";
import { SERVICE_GROUPS_KEY } from "../../constants/queryKeys";
import {
  Severity,
  useNotificationContext,
} from "../../context/NotificationContext";
import {
  StyledFormButtonsWrapper,
  StyledFormFieldsContainer,
} from "./CreateGroupModal.styles";
import { StyledXButton } from "../calendar/reservationModal/ReservationModal.styles";

interface ChangedValues {
  id: number;
  serviceGroupId?: number;
  name?: string;
  priceAmount?: number;
  duration?: string;
  description?: string;
}

interface CreateGroupModalProps {
  isModalOpen: boolean;
  handleClose: () => void;
  service?: IServiceGroup;
}

const CreateEditServiceModal = ({
  isModalOpen,
  handleClose,
  service,
}: CreateGroupModalProps) => {
  const queryClient = useQueryClient();
  const { showNotification } = useNotificationContext();
  const isMobile = useMediaQuery("(max-width:600px)");
  const [group, setGroup] = useState<string>(service?.groupName ?? "");
  const [name, setName] = useState<string>(service?.service?.serviceName ?? "");
  const [price, setPrice] = useState<string>(
    service?.service?.price.toString() ?? ""
  );
  const [selectedHours, setSelectedHours] = useState<string>(
    service?.service?.duration.slice(0, 2) ?? ""
  );
  const [selectedMinutes, setSelectedMinutes] = useState<string>(
    service?.service?.duration.slice(3, 5) ?? ""
  );
  const [description, setDescription] = useState<string>(
    service?.service?.description ?? ""
  );

  const [groups, setGroups] = useState<IDropdownType[]>([]);
  const [isModalNewServiceOpen, setIsModalNewServiceOpen] = useState(false);
  const [lastSelectedOption, setLastSelectedOption] = useState<IDropdownType>();

  const [groupError, setGroupError] = useState<InputError | null>(null);
  const [nameError, setNameError] = useState<InputError | null>(null);
  const [priceError, setPriceError] = useState<InputError | null>(null);

  const [isCheckedNewGroup, setIsCheckedNewGroup] = useState<boolean>(false);
  const [autoSelectedGroup, setAutoSelectedGroup] = useState<IDropdownType>();

  const { data } = useServiceGroups();

  useEffect(() => {
    if (data) {
      setGroups(data.result ?? []);
      if (isCheckedNewGroup) {
        setLastSelectedOption({
          key: data.result[data.result?.length - 1].key,
          value: data.result[data.result?.length - 1].value,
        });
        setGroup(data.result[data.result.length - 1].key);
      }
    }
  }, [data]);

  useEffect(() => {
    service &&
      setAutoSelectedGroup({
        key: Number(service.groupId),
        value: service.groupName,
      });
  }, [groups]);

  const { mutate: createGroupMutation } = useMutation(createServiceGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: SERVICE_GROUPS_KEY });
    },
    onError: (error) => {
      console.error("Greška prilikom prijavljivanja grupe:", error);
    },
  });

  const { mutate: createServicesListMutation } = useMutation(createServices, {
    onSuccess: () => {
      handleClose();
      showNotification("Usluga uspesno dodata", Severity.Success);
    },
    onError: (error) => {
      showNotification("Greška prilikom kreiranja usluge", Severity.Error);
    },
  });

  const { mutate: editServiceMutation } = useMutation(editService, {
    onSuccess: () => {
      handleClose();
      showNotification("Usluga uspesno izmenjena", Severity.Success);
    },
    onError: (error) => {
      showNotification("Greška prilikom menjanja usluge", Severity.Error);
    },
  });

  const openModal = (isOpen: boolean) => {
    setIsModalNewServiceOpen(isOpen);
  };

  const handlePriceChange = (event) => {
    const priceValue = event.target.value;

    if (
      /^\d*\.?\d*$/.test(priceValue) &&
      (priceValue === "" || priceValue[0] !== "0")
    ) {
      setPrice(priceValue);
    }
  };

  const validateFormFields = (): boolean => {
    const validated = {
      group: isFilledString(group, InputError.EmptyServiceGroup),
      name: isFilledString(name, InputError.EmptyServiceName),
      price: isFilledNumber(price, InputError.EmptyPrice),
    };
    if (!allValid(validated)) {
      setGroupError(validated.group.isValid ? null : validated.group.error);
      setNameError(validated.name.isValid ? null : validated.name.error);
      setPriceError(validated.price.isValid ? null : validated.price.error);
      return false;
    }
    setGroupError(null);
    setNameError(null);
    setPriceError(null);
    return true;
  };

  const handleSaveGroup = (newGroupName: string, checkGroup: boolean) => {
    createGroupMutation({ name: newGroupName });
    setIsCheckedNewGroup(checkGroup);
    openModal(false);
  };

  const areValuesChanged = (): boolean => {
    return (
      service?.groupName !== group ||
      service?.service.serviceName !== name ||
      service?.service.price !== price ||
      service?.service.duration.slice(0, 2) !== selectedHours ||
      service?.service.duration.slice(3, 5) !== selectedMinutes ||
      service?.service.description !== description
    );
  };

  const generateChangedValues = (): ChangedValues => {
    const changedValues: ChangedValues = {
      id: service ? service.service.serviceId : -1,
    };

    if (service?.groupName !== group) {
      changedValues.serviceGroupId = Number(group);
    }
    if (service?.service.serviceName !== name) {
      changedValues.name = name;
    }
    if (service?.service.price !== price) {
      changedValues.priceAmount = Number(price);
    }
    if (
      service?.service.duration.slice(0, 2) !== selectedHours ||
      service?.service.duration.slice(3, 5) !== selectedMinutes
    ) {
      changedValues.duration = `${selectedHours}:${selectedMinutes}:00`;
    }
    if (service?.service.description !== description) {
      changedValues.description = description;
    }

    return changedValues;
  };

  const handleAddNewGroup = () => {
    if (validateFormFields()) {
      if (service === undefined) {
        const hours = selectedHours ? `${selectedHours}:` : "00:";
        const minutes = selectedMinutes ? `${selectedMinutes}:` : "00:";
        const newServiceInfo = {
          serviceGroupId: Number(group),
          name: name,
          priceAmount: Number(price),
          duration: `${hours}${minutes}00`,
          description: description,
        };

        createServicesListMutation([newServiceInfo]);

        setName("");
        setPrice("");
        setDescription("");
        return;
      }
      areValuesChanged() && editServiceMutation(generateChangedValues());
    }
  };

  return (
    <UniversalModal
      width={
        window.innerWidth >= 900
          ? "34.375rem"
          : window.innerWidth >= 600
          ? "75%"
          : "90%"
      }
      isModalOpen={isModalOpen}
      handleClose={handleClose}
    >
      <StyledFormFieldsContainer>
        {isModalOpen && (
          <CreateGroupModal
            isModalOpen={isModalNewServiceOpen}
            handleClose={() => openModal(false)}
            onSave={(groupName, checkGroup) => {
              handleSaveGroup(groupName, checkGroup);
              setIsCheckedNewGroup(checkGroup);
            }}
          />
        )}
        <StyledContainer>
          <StyledHeaderContainer>
            {service ? (
              <StyledHeaderTitle>Izmena usluge</StyledHeaderTitle>
            ) : (
              <StyledHeaderTitle>Dodavanje nove usluge</StyledHeaderTitle>
            )}
            <StyledXButton onClick={handleClose}>
              <Xicon />
            </StyledXButton>
          </StyledHeaderContainer>
          <StyledModalFieldsDiv>
            <CustomDropdown
              options={groups}
              label={Boolean(groupError) ? groupError : "Grupa"}
              onSelect={(selected) => {
                setGroup(selected.key.toString());
              }}
              openModal={() => openModal(true)}
              selectLast={lastSelectedOption}
              error={Boolean(groupError)}
              onFocus={() => setGroupError(null)}
              onBlur={() => validateFormFields()}
              autoSelectOption={autoSelectedGroup}
            />
            <InputField
              label={Boolean(nameError) ? nameError : "Ime usluge"}
              placeholder="Ime usluge"
              value={name}
              onChange={(event) => setName(event.target.value)}
              error={Boolean(nameError)}
              onBlur={() => validateFormFields()}
              onFocus={() => setNameError(null)}
            />
            <InputField
              label={Boolean(priceError) ? priceError : "Cena"}
              placeholder="Cena"
              value={price}
              onChange={handlePriceChange}
              error={Boolean(priceError)}
              onBlur={() => validateFormFields()}
              onFocus={() => setPriceError(null)}
            />
            <div style={{ display: "flex", width: "100%", gap: "1.25rem" }}>
              <DropdownField
                style={{
                  width: "50%",
                }}
                label={"Sati"}
                options={hours?.map((hour) => ({
                  value: hour.value,
                  label: hour.value,
                }))}
                value={selectedHours || ""}
                onChange={(event) => setSelectedHours(event.target.value)}
              />
              <DropdownField
                style={{ width: "50%" }}
                label={"Minuti"}
                options={minutes?.map((minutes) => ({
                  value: minutes.value,
                  label: minutes.value,
                }))}
                value={selectedMinutes || ""}
                onChange={(event) => setSelectedMinutes(event.target.value)}
              />
            </div>
            <InputField
              label="Opis"
              multiline
              rows={4}
              placeholder="Opis"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </StyledModalFieldsDiv>
          <StyledFormButtonsWrapper>
            <PrimaryButton
              text={"Sačuvaj"}
              width={"100%"}
              onClick={handleAddNewGroup}
            />
            <SecondaryButton
              text={"Otkaži"}
              width={"100%"}
              onClick={handleClose}
            />
          </StyledFormButtonsWrapper>
        </StyledContainer>
      </StyledFormFieldsContainer>
    </UniversalModal>
  );
};

export default CreateEditServiceModal;
