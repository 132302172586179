import { Navigate } from "react-router-dom";

import { ACCESS_TOKEN } from "../../constants/localStorageKeys";
import { LOGIN } from "../../constants/urlPaths";

interface ProtectedRouteProps {
  redirectPath?: string;
  children: JSX.Element;
}

export const ProtectedRoute = ({
  redirectPath = LOGIN,
  children,
}: ProtectedRouteProps) => {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};
