import React from "react";
import { IconButtonBorder } from "./IconButton.styles";

interface IconButtonProps {
  onClick: () => void;
  style?: React.CSSProperties;
  children?: JSX.Element;
}

export const IconButton = ({ style, onClick, children }: IconButtonProps) => {
  return (
    <IconButtonBorder style={style} onClick={onClick}>
      {children}
    </IconButtonBorder>
  );
};
