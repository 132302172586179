import React, { useState } from "react";
import { PageContainer } from "../pageContainer/PageContainer";
import { SupportPage } from "./SupportPage";

export const SupportPageProtected = () => {
  return (
    <PageContainer>
      <SupportPage />
    </PageContainer>
  );
};
