import React from "react";
import { styled } from "@mui/system";
import { Grid, Icon, Typography } from "@mui/material";

import { COLORS } from "../../constants/colors";

export const ProfileMainContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  height: "100vh",
  [theme.breakpoints.up("md")]: {
    marginTop: "3.75rem",
    minHeight: "auto",
  },
}));

export const ProfileCenterContainer = styled("div")(({ theme }) => ({
  marginTop: "0.108rem",
  width: "56.875rem",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    width: "23.75rem",
    marginTop: "2.108rem",
  },
}));

export const ImagesContainer = styled("div")({
  display: "flex",
  marginTop: "1rem",
  marginBottom: "1.438rem",
  overflow: "auto",
  height: "120px",
});

export const OnboardingCenterContainer = styled("div")(({ theme }) => ({
  marginTop: "1.25rem",
  width: "65rem",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "22rem",
  },
}));

export const ProfileImageContainer = styled("div")(({ theme }) => ({
  position: "relative",
  width: "55.625rem",
  height: "10.3125rem",
  marginTop: "3.625rem",
  marginLeft: "0.625rem",
  border: `1px solid ${COLORS.lightGray}`,
  borderRadius: "0.5625rem",
  [theme.breakpoints.down("sm")]: {
    width: "21rem",
    height: "4.375rem",
    marginLeft: "1.6rem",
    borderRadius: "0.5625rem",
    flexShrink: "0rem",
    marginTop: "1.25rem",
    objectFit: "contain",
    backgroundColor: COLORS.lightGray,
  },
}));

export const ProfileIcon = styled(Icon)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    marginLeft: "0.7rem",
    width: "19.6875rem",
    height: "4.375rem",
    borderRadius: "0.5625rem",
    objectFit: "contain",
    backgroundColor: COLORS.lightGray,
  },
}));

export const ProfileAddIcon = styled(Icon)(({ theme }) => ({
  position: "absolute",
  top: "-0.9375rem",
  right: "-0.9375rem",
  width: "1.6875rem",
  height: "1.6875rem",
  backgroundColor: COLORS.secondary,
  borderRadius: "50%",
  padding: "0.3125rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "1.3rem",
  [theme.breakpoints.down("sm")]: {
    color: COLORS.white,
    width: "1.5rem",
    height: "1.5rem",
    backgroundColor: COLORS.secondary,
    borderRadius: "50%",
    marginLeft: "1.6rem",
    fontSize: "0.6rem",
  },
}));

export const ProfileSelectedImage = styled("img")(({ theme }) => ({
  position: "absolute",
  top: "0rem",
  left: "0rem",
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: "0.5625rem",
  [theme.breakpoints.down("sm")]: {
    width: "19.6875rem",
    height: "4.375rem",
    borderRadius: "0.5625rem",
    flexShrink: "0rem",
    objectFit: "fill",
    backgroundColor: COLORS.lightGray,
  },
}));

export const ProfileAddPhotoIcon = styled(Icon)(({ theme }) => ({
  position: "absolute",
  width: "1.3rem",
  height: "1.3rem",
  backgroundColor: COLORS.secondary,
  borderRadius: "50%",
  padding: "0.3125rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    top: "auto",
    bottom: "0rem",
    right: "-0.625rem",
    width: "1rem",
    height: "1rem",
  },
}));

export const ProfileStyledGallery = styled("div")(({ theme }) => ({
  marginTop: "1rem",
  marginLeft: "0.525rem",
  overflowX: "auto",
  width: "26.6rem",
  whiteSpace: "nowrap",
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    marginTop: "1rem",
    marginLeft: "1.8rem",
    overflowX: "auto",
    width: "21rem",
    whiteSpace: "nowrap",
    display: "flex",
  },
}));

export const StyledAddPhotos = styled("div")(({ theme }) => ({
  marginTop: "1rem",
  marginLeft: "0.525rem",
  width: "26.6rem",
  whiteSpace: "nowrap",
  display: "flex",
  [theme.breakpoints.down("sm")]: {
    display: "flex",
    alignItems: "center",
    marginTop: "1rem",
    marginLeft: "1rem",
    width: "23rem",
  },
}));

export const HeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: '100%',

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "start",
    gap: "1rem"
  },
}));

export const ProfileHeader = styled(Typography)(({ theme }) => ({
  position: "absolute",
  top: "-1.0625rem",
  left: "0rem",
  marginTop: "3.5rem",
  fontSize: "2rem",
  color: COLORS.darkGray,
  fontWeight: "600",
  marginLeft: "13.75rem",
  [theme.breakpoints.down("sm")]: {
    marginTop: "1.875rem",
    top: "2.25rem",
    fontSize: "1.5rem",
    marginLeft: "2.1875rem",
  },
}));

export const StyledAddIcon = styled("div")({
  height: "1.5rem",
  width: "1.5rem",
  borderRadius: "50%",
  backgroundColor: COLORS.secondary,
  marginLeft: "1rem",
});

export const StyledColumnGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const ButtonsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: "10px",
  
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export const StyledContentContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    gap: "1rem",
});

export const WorkDayContainer = styled("div")({
    fontSize: "1rem",
    padding: 0,
    margin: 0,
});

