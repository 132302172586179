import { Navigate } from "react-router-dom";
import { IS_WORKER } from "../../constants/localStorageKeys";
import { CALENDAR } from "../../constants/urlPaths";

interface ProtectedSalonRouteProps {
  redirectPath?: string;
  children: JSX.Element;
}

export const ProtectedSalonRoute = ({
  redirectPath = CALENDAR,
  children,
}: ProtectedSalonRouteProps) => {
  const isWorker = JSON.parse(localStorage.getItem(IS_WORKER) ?? "false");

  if (isWorker) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};
