import React, { useEffect, useState } from "react";
import { Grid, IconButton, useMediaQuery } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { GridColDef } from "@mui/x-data-grid";
import ConfirmModal from "../modal/ConfirmModal";
import {
  ActionsMobile,
  Separator,
  StyledDataGrid,
  StyledPaper,
  StyledTableContainer,
  TableContainerMobile,
} from "./TableDataGrid.styles";
import { COLORS } from "../../constants/colors";
import { useDeleteWorker, useWorkers } from "../../services/workers";
import { CalendarIcon } from "@mui/x-date-pickers/icons";
import DaysOffModal from "../modal/DaysOffModal";
import { EmployeeData } from "../../types/Types";

interface StaffTableProps {
  setWorkerData: React.Dispatch<React.SetStateAction<EmployeeData>>;
  setIsWorkerModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  stuffData: any;
  refetchTable: () => void;
}

const StaffTable = ({
  setWorkerData,
  setIsWorkerModalOpen,
  stuffData,
  refetchTable,
}: StaffTableProps) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isMiniTablet = useMediaQuery("(max-width:800px)");
  const [isDaysOffModalOpen, setIsDaysOffModalOpen] = useState<boolean>(false);
  const [workerId, setWorkerId] = useState<number>();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [selectedWorkerId, setSelectedWorkerId] = useState<number | undefined>(
    undefined
  );
  const deleteWorkerMutation = useDeleteWorker();
  const defaultRowHeight = 55;

  const result = stuffData;

  const openConfirmModal = () => {
    setIsConfirmModalOpen(true);
  };

  const closeConfirmModal = () => {
    refetchData();
    setIsConfirmModalOpen(false);
  };

  const handleEdit = (row) => {
    setWorkerData(row);
    setIsWorkerModalOpen(true);
  };

  const handleDaysOffModal = (open: boolean, id?: number) => {
    open && id && setWorkerId(id);
    setIsDaysOffModalOpen(open);
  };

  const handleDelete = (workerId: number) => {
    setSelectedWorkerId(workerId);
    openConfirmModal();
  };

  const handleArrowClick = (rowId: number) => {
    setExpandedRow((prevRow) => (prevRow === rowId ? null : rowId));
  };

  const getRowHeight = (params: any) => {
    return expandedRow === params.id ? "auto" : defaultRowHeight;
  };

  const handleDeleteWorker = async () => {
    if (typeof selectedWorkerId === "number") {
      try {
        await deleteWorkerMutation.mutateAsync(selectedWorkerId);
        closeConfirmModal();
      } catch (error) {
        console.error("Došlo je do greške prilikom brisanja radnika:", error);
      }
    }
  };

  const sortWorkingHours = (workingHours) => {
    if (!workingHours) return [];

    const sortedWorkingHours = [...workingHours].sort((a, b) => {
      const days = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ];
      return days.indexOf(a.dayOfWeek) - days.indexOf(b.dayOfWeek);
    });

    return sortedWorkingHours;
  };

  const groupWorkingHoursByDayOfWeek = (sortedWorkingHours) => {
    return sortedWorkingHours.reduce((acc, curr) => {
      const index = acc.findIndex((item) => item.dayOfWeek === curr.dayOfWeek);
      if (index !== -1) {
        acc[index].slots.push(curr);
      } else {
        acc.push({ dayOfWeek: curr.dayOfWeek, slots: [curr] });
      }
      return acc;
    }, []);
  };

  const renderWorkingHoursDesktop = (workingHours) => {
    const sortedWorkingHours = sortWorkingHours(workingHours);

    const groupedWorkingHours =
      groupWorkingHoursByDayOfWeek(sortedWorkingHours);

    return groupedWorkingHours.map((group, index) => (
      <div key={index}>
        {group.dayOfWeek}:
        {group.slots.map((slot, slotIndex) => (
          <span
            key={slotIndex}
            style={{ color: COLORS.mediumGray, marginLeft: "0.2rem" }}
          >
            {slot.openingTime}-{slot.closingTime}
            {slotIndex !== group.slots.length - 1 && " · "}
          </span>
        ))}
      </div>
    ));
  };

  const renderWorkingHoursMobile = (workingHours) => {
    const sortedWorkingHours = sortWorkingHours(workingHours);

    const groupedWorkingHours =
      groupWorkingHoursByDayOfWeek(sortedWorkingHours);

    return groupedWorkingHours.map((group, index) => (
      <div key={index}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: "0.7rem", marginBottom: "0.4rem" }}>
            {group.dayOfWeek.substring(0, 3)}:
          </div>
          <div>
            {group.slots.map((slot, slotIndex) => (
              <div
                key={slotIndex}
                style={{ color: COLORS.mediumGray, marginBottom: "0.4rem" }}
              >
                {slot.openingTime}-{slot.closingTime}
              </div>
            ))}
          </div>
        </div>
      </div>
    ));
  };

  useEffect(() => {
    if (!isMobile) {
      setExpandedRow(null);
    }
    if (isMobile) {
      setExpandedRow(null);
    }
  }, [isMobile]);

  const refetchData = () => {
    refetchTable();
  };

  const columns: GridColDef[] = isMobile
    ? [
        {
          field: "fullName",
          headerName: "",
          flex: 0.8,
          sortable: false,
          renderCell: (params) => {
            const isExpanded = expandedRow === params.row.id;
            return (
              <div style={{ marginTop: isExpanded ? "0.5rem" : undefined }}>
                <div>{params.value}</div>
                {isExpanded && (
                  <>
                    <TableContainerMobile>
                      <Separator />
                      <Grid container spacing={1}>
                        <Grid item xs={4}>
                          Tip:
                        </Grid>
                        <Grid item xs={8}>
                          {params.row.workerType}
                        </Grid>
                        <Grid item xs={4}>
                          Raspored:
                        </Grid>
                        <Grid item xs={8}>
                          {renderWorkingHoursMobile(params.row.workingHours)}
                        </Grid>
                      </Grid>
                    </TableContainerMobile>
                  </>
                )}
              </div>
            );
          },
        },
        {
          field: "actions",
          headerName: "",
          sortable: false,
          flex: 0.2,
          renderCell: ActionCellMobile,
        },
      ]
    : [
        {
          field: "workerType",
          headerName: "Tip zaposlenja",
          flex: isMiniTablet ? 0.25 : 1,
          sortable: false,
        },
        {
          field: "fullName",
          headerName: "Ime i prezime",
          flex: isMiniTablet ? 0.5 : 1,
          sortable: false,
        },
        {
          field: "workingHours",
          headerName: "Raspored",
          flex: isMiniTablet ? 0.5 : 1,
          sortable: false,
          renderCell: (params) => (
            <div>
              {params.value && expandedRow === params.row.id
                ? renderWorkingHoursDesktop(params.value)
                : renderWorkingHoursDesktop(params.value)[0]}
            </div>
          ),
        },
        {
          field: "actions",
          headerName: "",
          sortable: false,
          flex: isMiniTablet ? 0.5 : 1,
          renderCell: ActionCell,
        },
      ];

  function ActionCell(params) {
    const isExpanded = expandedRow === params.row.id;

    return (
      <div>
        <IconButton
          style={{
            color: COLORS.primary,
            fontSize: 20,
          }}
          onClick={() => handleArrowClick(params.row.id)}
        >
          {isExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </IconButton>

        <>
          <IconButton
            style={{ color: COLORS.yellow_800 }}
            onClick={(row) => handleEdit(params.row)}
          >
            <BorderColorIcon />
          </IconButton>
          <IconButton
            style={{ color: COLORS.primary }}
            onClick={() => handleDelete(params.row.id)}
          >
            <DeleteIcon />
          </IconButton>
          <IconButton
            style={{ color: COLORS.yellow_800 }}
            onClick={() => handleDaysOffModal(true, params.row.id)}
          >
            <CalendarIcon />
          </IconButton>
        </>
      </div>
    );
  }

  function ActionCellMobile(params) {
    const isExpanded = expandedRow === params.row.id;

    return (
      <ActionsMobile>
        {isExpanded && (
          <>
            <IconButton
              style={{ color: COLORS.yellow_800 }}
              onClick={(row) => handleEdit(params.row)}
            >
              <BorderColorIcon />
            </IconButton>
            <IconButton
              style={{ color: COLORS.primary }}
              onClick={() => handleDelete(params.row.id)}
            >
              <DeleteIcon />
            </IconButton>
            <IconButton
              style={{ color: COLORS.yellow_800 }}
              onClick={() => handleDaysOffModal(true, params.row.id)}
            >
              <CalendarIcon />
            </IconButton>
          </>
        )}
        <IconButton
          style={{
            color: COLORS.mediumGray,
            fontSize: 20,
          }}
          onClick={() => handleArrowClick(params.row.id)}
        >
          {isExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </IconButton>
      </ActionsMobile>
    );
  }

  return (
    <StyledTableContainer>
      <StyledPaper>
        <StyledDataGrid
          justifyContent={"start"}
          rows={result || []}
          columns={columns}
          hideFooterPagination
          hideFooter
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          disableDensitySelector
          hideFooterSelectedRowCount
          disableRowSelectionOnClick={true}
          columnHeaderHeight={isMobile ? 0 : 55}
          rowHeight={defaultRowHeight}
          getRowHeight={getRowHeight}
          getRowClassName={(params) =>
            expandedRow === params.id ? "expanded-row" : ""
          }
        />
      </StyledPaper>
      {isDaysOffModalOpen && (
        <DaysOffModal
          isModalOpen={isDaysOffModalOpen}
          handleClose={() => handleDaysOffModal(false)}
          workerId={workerId ?? undefined}
          title="Godisnji odmor"
        />
      )}

      {isConfirmModalOpen && (
        <ConfirmModal
          isModalOpen={isConfirmModalOpen}
          handleClose={closeConfirmModal}
          onSave={handleDeleteWorker}
          actionToConfirm="želite da obrišete"
          workerId={selectedWorkerId}
        />
      )}
    </StyledTableContainer>
  );
};
export default StaffTable;
