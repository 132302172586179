import { Grid } from "@mui/material";
import MenuListItem from "./MenuListItem";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import HouseRoundedIcon from "@mui/icons-material/HouseRounded";
import ContentCutRoundedIcon from "@mui/icons-material/ContentCutRounded";
import ManageAccountsRoundedIcon from "@mui/icons-material/ManageAccountsRounded";

import {
  ANALYTICS,
  CALENDAR,
  DAYS_OFF,
  LOGIN,
  PROFILE_SALON,
  SERVICES,
  STAFF,
  SUPPORT,
} from "../../constants/urlPaths";
import { IS_WORKER } from "../../constants/localStorageKeys";
import {
  AnalyticsRounded,
  EventBusyRounded,
  SupportAgentRounded,
} from "@mui/icons-material";
import { useQueryClient } from "react-query";

const MenuList = () => {
  const queryClient = useQueryClient();
  const isWorker = JSON.parse(localStorage.getItem(IS_WORKER) ?? "false");
  const handleLogOut = async () => {
    localStorage.clear();
    sessionStorage.clear();
    try {
      await queryClient.invalidateQueries();
      window.location.href = LOGIN;
    } catch (error) {
      console.error("Error invalidating queries:", error);
    }
  };

  return (
    <Grid container direction="column" justifyContent="space-between">
      <Grid item>
        <div style={{ marginTop: "4rem" }}>
          <MenuListItem
            icon={<CalendarMonthOutlinedIcon />}
            label="Termini"
            href={CALENDAR}
          />
          {!isWorker && (
            <MenuListItem
              icon={<HouseRoundedIcon />}
              label="Profil salona"
              href={PROFILE_SALON}
            />
          )}
          {!isWorker && (
            <MenuListItem
              icon={<ContentCutRoundedIcon />}
              label="Usluge"
              href={SERVICES}
            />
          )}
          {!isWorker && (
            <MenuListItem
              icon={<ManageAccountsRoundedIcon />}
              label="Zaposleni"
              href={STAFF}
            />
          )}
          {!isWorker && (
            <MenuListItem
              icon={<AnalyticsRounded />}
              label="Analitika"
              href={ANALYTICS}
            />
          )}
          <MenuListItem
            icon={<EventBusyRounded />}
            label="Neradni dani"
            href={DAYS_OFF}
          />
          <MenuListItem
            icon={<SupportAgentRounded />}
            label="Podrska"
            href={SUPPORT}
          />
        </div>
      </Grid>
      <Grid item marginBottom="32px">
        <MenuListItem
          icon={<LogoutOutlinedIcon />}
          label="Odjavi se"
          onClick={handleLogOut}
        />
      </Grid>
    </Grid>
  );
};
export default MenuList;
