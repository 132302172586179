import { styled } from "@mui/system";
import { COLORS } from "../../constants/colors";

export const Page404MainContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "1rem",
  padding: "3rem",

  [theme.breakpoints.down("sm")]: {
    padding: "1rem",
  },
}));

export const Page404Heading = styled("h1")(({ theme }) => ({
  color: COLORS.primary,
  margin: "0",
  fontSize: "2rem",

  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
}));

export const Page404Description = styled("h1")(({ theme }) => ({
  margin: "0",
  fontSize: "1rem",
  fontWeight: "500",

  [theme.breakpoints.down("sm")]: {
    fontSize: "0.5rem",
  },
}));

export const Page404Numbers = styled("h1")(({ theme }) => ({
  margin: "0",
  fontSize: "15rem",
  fontWeight: "900",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  color: COLORS.primary,

    [theme.breakpoints.down("sm")]: {
    fontSize: "8rem",
  },
}));

export const Container404Numbers = styled("h1")(({ theme }) => ({
   display: "flex",
   justifyContent: "center",
   alignSelf: "center",
   alignItems: "center",
   gap: "1rem",
   marginTop: "2rem",

   [theme.breakpoints.down("sm")]: {
    gap: "0.5rem",
    marginTop: "1rem",
  },
}));