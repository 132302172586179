import React from "react";

import { COLORS } from "../../constants/colors";
import { IconButton } from "../buttons/iconButton/IconButton";
import {
  MainContainer,
  StyledDeleteIcon,
  StyledImage,
} from "./ImageWithDelete.styles";

const ImageWithDeleteButton = ({ imageUrl, onDelete }) => {
  const handleDelete = () => {
    onDelete();
    console.log(imageUrl);
  };

  return (
    <MainContainer>
      <StyledImage src={imageUrl} alt="Image" />
      <IconButton
        style={{
          position: "absolute",
          bottom: "1.25rem",
          right: "-0.625rem",
          backgroundColor: COLORS.primary,
        }}
        onClick={handleDelete}
      >
        <StyledDeleteIcon />
      </IconButton>
    </MainContainer>
  );
};

export default ImageWithDeleteButton;
