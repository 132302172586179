import { useMutation, useQuery, useQueryClient } from "react-query";
import apiClient from "./api/api";
import {
  SERVICE_GROUPS,
  SERVICE_GROUP,
  PREFIX_SERVICES,
  SERVICES_BY_GROUP,
} from "./routes/routes";
import { IServiceInfo } from "../pages/onBoarding/Step4";
import { SERVICES_BY_GROUP_KEY, SERVICES_KEY, SERVICE_GROUPS_KEY } from "../constants/queryKeys";

const getServiceGroups = async () => {
  const { data } = await apiClient.get(SERVICE_GROUPS);
  return data;
};

export const useServiceGroups = (enabled = true) => {
  const { data, isLoading, isError, isFetching, refetch } = useQuery(
    [SERVICE_GROUPS_KEY],
    () => getServiceGroups(),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled,
    }
  );

  return {
    data: data ?? [],
    isLoading,
    isError,
    isFetching,
    refetch,
  };
};

export const createServiceGroup = async ({ name }: { name: string }) => {
  const { data } = await apiClient.post(SERVICE_GROUP, {
    name,
  });

  return data;
};

const getServices = async () => {
  const { data } = await apiClient.get(PREFIX_SERVICES);
  return data;
};

export const useServices = (enabled = true) => {
  const { data, isLoading, isError, isFetching, refetch } = useQuery(
    [SERVICES_KEY],
    () => getServices(),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled,
    }
  );

  return {
    data: data?.result ?? [],
    isLoading,
    isError,
    isFetching,
    refetch,
  };
};

export const createServices = async (servicesArray: IServiceInfo[]) => {
  const { data } = await apiClient.post(PREFIX_SERVICES, servicesArray);

  return data;
};

const getServicesByGroup = async () => {
  const { data } = await apiClient.get(SERVICES_BY_GROUP);
  return data;
};

export const useServicesByGroup = (enabled = true) => {
  const { data, isLoading, isError, isFetching, refetch } = useQuery(
    [SERVICES_BY_GROUP_KEY],
    () => getServicesByGroup(),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled,
    }
  );

  return {
    data: data?.result ?? [],
    isLoading,
    isError,
    isFetching,
    refetch,
  };
};

export const deleteService = async (serviceId: number) => {
  const { data } = await apiClient.delete(PREFIX_SERVICES, {
    data: { id: serviceId },
  });
  return data;
};

export const editService = async (serviceInfo: any) => {
  const { data } = await apiClient.put(PREFIX_SERVICES, serviceInfo);
  return data;
};