import { Typography, styled } from "@mui/material";

import { COLORS } from "../../constants/colors";

export const CenteredContainer = styled("div")(({ theme }) => ({
  textAlign: "start",
  marginTop: "-4rem",
  [theme.breakpoints.down("sm")]: {
    marginTop: "-1rem",
  },
}));

export const HeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: '100%',
}));

export const HeaderContainerWithBottomMargin = styled(HeaderContainer)(({ theme }) => ({
  marginBottom: "2.2rem",
  [theme.breakpoints.down("sm")]: {
    marginBottom: "1rem",
  },
}));

export const ServicesText = styled(Typography)(({ theme }) => ({
  color: COLORS.medium_gray,
  textTransform: "uppercase",
  fontSize: "0.9rem",
  fontWeight: "600",
  marginBottom: "1rem",
  marginTop: "2rem",
  [theme.breakpoints.down("sm")]: {
    marginTop: "1rem",
  },
}));

export const DataGridContainer = styled("div")({
  width: '100%',
  flexDirection: 'column',
  display: 'flex'
});