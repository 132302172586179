import { styled } from "@mui/system";

export const OnboardingStep3CenterContainer = styled("div")(({ theme }) => ({
  marginTop: "1.25rem",
  alignItems: "flex-start",
}));

export const ImagesContainer = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  marginTop: "1rem",
  marginBottom: "1.438rem",
});

export const MainContentContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  marginTop: "1.6rem",
  gap: "1rem",
});
