import React from "react";
import { styled } from "@mui/system";

import { COLORS } from "../../constants/colors";
import { Select } from "@mui/material";

type DropdownContainerProps = {
  error?: boolean;
};

export const DropdownContainer = styled("div")({
  display: "flex",
  width: "100%",
  border: `0.0625rem solid ${COLORS.lightGray}`,
});

export const StyledSelect = styled(Select)<DropdownContainerProps>(
  ({ error }) => ({
    borderRadius: "0.5625rem",
    fontSize: "1rem",
    backgroundColor: COLORS.lightGray,
    border: "none",

   "& fieldset.MuiOutlinedInput-notchedOutline": {
    border: "none !important",
  },
  })
);

export const StyledButtomContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  marginLeft: "0.625rem",
});
