import { Box, Button, Typography, styled } from "@mui/material";
import { COLORS } from "../../../constants/colors";


export const StyledHeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
}));

export const StyledHeaderTitle = styled("span")(({ theme }) => ({
  color: COLORS.darkGray,
  fontSize: "1.5rem",
  fontWeight: "500",

  [theme.breakpoints.down("sm")]: {
    fontSize: "1.125rem"
  },
}));

export const StyledEventModalWrapper = styled(Box)(
  ({ theme, borderColor }) => ({
    position: "relative",
    display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  gap: "1.25rem",
    borderRadius: "0.5625rem",

    [theme.breakpoints.down("lg")]: {
      paddingTop: "1rem",
      border: "none",
    },
  })
);

export const StyledeEventContentWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "0.9rem",
  alignItems: "flex-start",
  width: "100%",
}));

export const StyledEventLabel = styled(Typography)(({ theme }) => ({
  fontSize: "1.2rem",
  [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
}));

export const StyledEventDescriptionLabel = styled(Typography)(({ theme }) => ({
  fontSize: "1.2rem",
  color: COLORS.primary,
  [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
}));

export const StyledDescriptionContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  width: "100%",
  gap: "5px"
}));

export const StyledEventDescription = styled(Box)(({ theme }) => ({
  fontSize: "1.2rem",
  [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
}));

export const StyledEventActionWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  height: "3.4rem",
  marginTop: "1rem",
  width: "100%",

  "& button": {
    width: "48%",
  },

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "center",
    height: "fit-content",
    width: "100%",
    gap: "0.5rem",
    marginBottom: "0.5rem",

    "& button": {
      width: "100%",
    },
  },
}));

export const StyledCalendarCancelButton = styled(Button)(({ theme }) => ({
  minWidth: "10rem",
  minHeight: "3.40rem",
  padding: "0.75rem",
  letterSpacing: "0.08rem",
  borderRadius: "0.5rem",
  flexShrink: 0,
  backgroundColor: COLORS.secondary,
  color: "#fff",
  boxShadow: "none",
  textTransform: "capitalize",
  fontSize: "1rem",
  lineHeight: "1.2",

  "&:hover": {
    border: `1px solid  ${COLORS.secondary}`,
    backgroundColor: COLORS.white,
    color: COLORS.secondary,
  },

  [theme.breakpoints.down("md")]: {
    minWidth: "6.375rem",
    minHeight: "2.8125rem",
    padding: "0.7rem",
  },
}));
