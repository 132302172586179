import styled from "@emotion/styled";
import { IconButton } from "@mui/material";

export const StyledLoginFieldsDiv = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "15px",
  width: "100%",
});

export const StyledIconButton = styled(IconButton)`
  margin: 0;
`;