import { styled } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import { COLORS } from "../../constants/colors";
import { IconButton } from "../buttons/iconButton/IconButton";

export const MainContainer = styled("div")({
  position: "relative",
  width: "auto",
  height: "auto",
  paddingBottom: "1.25rem",
  marginRight: "1.25rem",
});

export const StyledImage = styled("img")({
  width: "5.875rem",
  height: "5.875rem",
  objectFit: "cover",
  borderRadius: "0.5rem",
});

export const StyledDeleteIcon = styled(DeleteIcon)({
  bottom: "0.5rem",
  right: "0.5rem",
  background: "transparent",
  border: "none",
  fontSize: "1rem",
  cursor: "pointer",
  color: COLORS.white,
});
