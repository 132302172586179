import { IDropdownType } from "./Step4";

export const hours: IDropdownType[] = [
  { key: 1, value: "00" },
  { key: 2, value: "01" },
  { key: 3, value: "02" },
  { key: 4, value: "03" },
  { key: 5, value: "04" },
  { key: 6, value: "05" },
];

export const minutes: IDropdownType[] = [
  { key: 0, value: "00" },
  { key: 1, value: "05" },
  { key: 2, value: "10" },
  { key: 3, value: "15" },
  { key: 4, value: "20" },
  { key: 5, value: "25" },
  { key: 6, value: "30" },
  { key: 7, value: "35" },
  { key: 8, value: "40" },
  { key: 9, value: "45" },
  { key: 10, value: "50" },
  { key: 11, value: "55" },
];
