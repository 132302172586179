import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";

import PetifyLogo from "../../assets/svg/PetifyLogo";
import { InputField } from "../../components/inputs/TextField";
import { COLORS } from "../../constants/colors";
import { PrimaryButton } from "../../components/buttons/primaryButton/PrimaryButton";
import { LinkButton } from "../../components/buttons/linkButton/LinkButton";

import {
  Header,
  StyledCenteredContainer,
  StyledMasterContainer,
  StyledSupportButton,
} from "../../constants/customStyles";
import { sendConfirmationEmail } from "../../services/auth";
import isFilledString from "../../helpers/inputValidation/validators/isFilledString";
import { InputError } from "../../helpers/inputValidation/InputError";
import isEmail from "../../helpers/inputValidation/validators/isEmail";
import allValid from "../../helpers/inputValidation/validators/allValid";
import and from "../../helpers/inputValidation/validators/and";
import {
  Severity,
  useNotificationContext,
} from "../../context/NotificationContext";
import { StyledRegistrationFieldsDiv } from "./RegistrationEmail.styles";
import { HELP } from "../../constants/urlPaths";

export const RegistrationEmail = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState<InputError | null>(null);
  const { showNotification } = useNotificationContext();

  const { mutate: sendConfirmationEmailMutation, isLoading } = useMutation(
    sendConfirmationEmail,
    {
      onSuccess: (data) => {
        showNotification("Email je uspešno poslat.", Severity.Success);
        setEmail("");
      },
      onError: (error) => {
        showNotification("Email ne postoji u Petify bazi.", Severity.Error);
      },
    }
  );

  const validateFormFields = (): boolean => {
    const validated = {
      email: and(isFilledString(email, InputError.EmptyEmail), () =>
        isEmail(email, InputError.InvalidEmail)
      ),
    };
    if (!allValid(validated)) {
      setEmailError(validated.email.isValid ? null : validated.email.error);
      return false;
    }
    setEmailError(null);
    return true;
  };

  const handleSendEmail = () => {
    if (validateFormFields()) {
      sendConfirmationEmailMutation({ email });
    }
  };

  return (
    <StyledMasterContainer>
      <StyledSupportButton onClick={() => navigate(HELP)} />
      <StyledCenteredContainer>
        <PetifyLogo />
        <Header>Dobrodošli u Petify!</Header>
        <div
          style={{
            display: "inline-block",
            alignItems: "center",
            flexDirection: "column",
            color: "#6D6D6D",
            fontSize: "14px",
            fontWeight: "400",
            marginBottom: "0.93rem",
            marginTop: "0.8rem",
            textAlign: "justify",
          }}
        >
          <span>Kako biste se registrovali unesite Vašu email adresu a</span>
          <span> mi ćemo Vam poslati konfirmacioni link</span>
        </div>
        <StyledRegistrationFieldsDiv>
          <InputField
            label={"email"}
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <PrimaryButton
            width={"100%"}
            text={"Pošalji"}
            onClick={handleSendEmail}
          />
        </StyledRegistrationFieldsDiv>
        <div style={{ marginTop: "40px" }}>
          <span style={{ color: COLORS.mediumGray, fontSize: "1.rem" }}>
            Već imate nalog?
          </span>
          <LinkButton
            text={"Prijavite se"}
            onClick={() => navigate("/login")}
          />
        </div>
      </StyledCenteredContainer>
    </StyledMasterContainer>
  );
};
