import React, { useState } from "react";
import UniversalModal from "./UniversalModal";
import {
  StyledContainer,
  StyledHeaderContainer,
  StyledHeaderTitle,
} from "./DaysOffModal.styles";
import { StyledXButton } from "../calendar/reservationModal/ReservationModal.styles";
import { ReactComponent as Xicon } from "../../assets/X-ico.svg";
import {
  StyledContentContainer,
  WorkDayContainer,
} from "../../pages/profile/Profile.styles";

interface WorkingHoursModalProps {
  isModalOpen: boolean;
  handleClose: () => void;
  data: any;
}

const WorkingHoursModal = ({
  isModalOpen,
  handleClose,
  data,
}: WorkingHoursModalProps) => {
  console.log(data);
  return (
    <UniversalModal
      width={
        window.innerWidth >= 900
          ? "28.375rem"
          : window.innerWidth >= 600
          ? "60%"
          : "90%"
      }
      isModalOpen={isModalOpen}
      handleClose={handleClose}
    >
      <StyledContainer>
        <StyledHeaderContainer>
          <StyledHeaderTitle>Radno vreme salona</StyledHeaderTitle>
          <StyledXButton onClick={handleClose}>
            <Xicon />
          </StyledXButton>
        </StyledHeaderContainer>
        <StyledContentContainer>
          {data.map((day) => (
            <div key={day.id}>
              <WorkDayContainer>
                {day.dayOfWeek}: {day.openingTime} - {day.closingTime}
              </WorkDayContainer>
            </div>
          ))}
        </StyledContentContainer>
      </StyledContainer>
    </UniversalModal>
  );
};

export default WorkingHoursModal;
