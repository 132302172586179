import { styled } from "@mui/material/styles";
import { Container } from "@mui/material";

import { TOOLBAR_HEIGHT } from "./theme";
import { HTMLAttributes } from "react";

export const StyledContainer = styled(Container)(({ theme }) => ({
  minHeight: "100%",
  paddingLeft: "0 !important",
  paddingRight: "0 !important",
}));

interface StyledContentProps extends HTMLAttributes<HTMLElement> {
  mobileNoPadding?: boolean;
}

export const StyledContent = styled("main")<StyledContentProps>(({ theme, mobileNoPadding }) => ({
  flexGrow: 1,
  height: `calc(100% - ${TOOLBAR_HEIGHT}px)`,
  marginTop: 0,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: "0",
  [theme.breakpoints.down("xs")]: {
    marginBottom: "4rem",
    padding: "1em 1em",
  },
  [theme.breakpoints.down("sm")]: {
    marginTop: TOOLBAR_HEIGHT,
    padding:mobileNoPadding ? '0': '1rem'
  },
  [theme.breakpoints.up("sm")]: {
    marginLeft: "4rem",
    padding: "2rem",
  },
}));

export const StyledContentShift = styled("main")(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    padding: "2rem",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: "175px",
  },
}));
