import { Box, MenuItem, Typography, styled } from "@mui/material";
import { COLORS } from "../../../constants/colors";

export const StyledNotifyClientModule = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  padding: "2.57rem 3.6rem",
  gap: "1.25rem",
  width: "27.0625rem",

  "& form": {
    display: "flex",
    flexDirection: "column",
    gap: "1.25rem",
  },

  [theme.breakpoints.down("lg")]: {
    width: "75vw",
    padding: "1rem 1.5rem",
  },
}));

export const StyledNotifyModuleHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const StyledNotifyModuleHeading = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: 500,
}));

export const StyledTimepickerContainer = styled(Box)(({ theme }) => ({
  "& .MuiFormControl-root": {
    borderRadius: "0.5625rem",
    backgroundColor: COLORS.lightGray,
    width: "100%",
  },

  "& .MuiOutlinedInput-notchedOutline ": {
    border: "none !important",
  },
}));

export const StyledNotifyMenuItem = styled(MenuItem)(({ theme }) => ({
  textTransform: "capitalize",
  backgroundColor: "#F6F6F6",
  padding: "0.5rem !important",
}));

export const StyledNotifyFormActionsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1.37rem",
  marginTop: "0.63rem",

  // "& button": {
  //   width: "45%",
  // },
}));
