import React, { useState } from "react";

import { PageContainer } from "../pageContainer/PageContainer";
import { HeaderPage } from "../../constants/customStyles";
import { PrimaryButton } from "../../components/buttons/primaryButton/PrimaryButton";
import { useMediaQuery } from "@mui/material";
import { HeaderContainerWithBottomMargin } from "../services/Services.styles";
import StaffTable from "../../components/dataGridTable/StaffTable";
import { EmployeeData } from "../../types/Types";
import WorkerDataModal from "./workerDataModal/WorkerDataModal";
import { useWorkers } from "../../services/workers";
import LoaderWithLogo from "../../components/loader/loader";

const defaultWorkerData: EmployeeData = {
  id: -1,
  email: "",
  fullName: "",
  workerType: "",
  workingHours: [
    {
      dayOfTheWeek: "",
      openingTime: "",
      closingTime: "",
    },
  ],
};

const Staff = () => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const [isWorkerModalOpen, setIsWorkerModalOpen] = useState(false);
  const [workerData, setWorkerData] = useState<EmployeeData>(defaultWorkerData);

  const { data, isLoading, refetch } = useWorkers();

  const openAddWorkerModal = () => {
    setIsWorkerModalOpen(true);
  };

  const closeWorkerModal = () => {
    setIsWorkerModalOpen(false);
    setWorkerData(defaultWorkerData);
  };

  return (
    <PageContainer>
      {isLoading ? (
        <LoaderWithLogo></LoaderWithLogo>
      ) : (
        <>
          <HeaderContainerWithBottomMargin>
            <HeaderPage>Zaposleni</HeaderPage>
            <PrimaryButton
              width={isMobile ? 125 : 255}
              text="Dodaj novog zaposlenog"
              onClick={openAddWorkerModal}
              fontSize={isMobile ? "0.75rem" : "1rem"}
            />
          </HeaderContainerWithBottomMargin>
          <StaffTable
            stuffData={data.result}
            refetchTable={refetch}
            setWorkerData={setWorkerData}
            setIsWorkerModalOpen={setIsWorkerModalOpen}
          />
          {isWorkerModalOpen && (
            <WorkerDataModal
              refetchTable={refetch}
              isModalOpen={isWorkerModalOpen}
              handleClose={closeWorkerModal}
              employeeData={workerData}
              setEmployeeData={setWorkerData}
            />
          )}
        </>
      )}
    </PageContainer>
  );
};

export default Staff;
