import React, { ChangeEvent, useState } from "react";
import { useMediaQuery } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useMutation } from "react-query";

import { PrimaryButton } from "../../components/buttons/primaryButton/PrimaryButton";
import {
  StepperHeader,
  StepperSubHeader,
  StyledSupportButton,
} from "../../constants/customStyles";
import { COLORS } from "../../constants/colors";
import {
  ImagesContainer,
  MainContentContainer,
  OnboardingStep3CenterContainer,
} from "./Step3.styles";
import { IconButton } from "../../components/buttons/iconButton/IconButton";
import ImageWithDeleteButton from "../../components/imageWithDelete/imageWithDelete";
import { InputField } from "../../components/inputs/TextField";
import { addSalonImages } from "../../services/onboarding";
import { convertDataUrlToBase64 } from "./Step3.helper";
import { OnboardingMainContainer } from "./Onboarding.styles";
import { useNavigate } from "react-router-dom";
import { handleFileChange, handleImageUpload } from "../../helpers/imageUpload";
import { HELP } from "../../constants/urlPaths";

export const Step3 = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [images, setImages] = useState<string[]>([]);
  const [description, setDescription] = useState<string>("");
  const navigate = useNavigate();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleFileChange(event, setImages);
  };

  const handleDeleteImage = (index: number) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const { mutate: addSalonImagesMutation } = useMutation(addSalonImages, {
    onSuccess: (data) => {
      navigate("/onboard/services");
    },
    onError: (error) => {
      console.error("Greška prilikom dodavanja slika i opisa:", error);
    },
  });

  const finishStep3 = () => {
    addSalonImagesMutation({ description: description, images: images });
  };

  return (
    <OnboardingMainContainer>
      <StyledSupportButton onClick={() => navigate(HELP)} />
      <OnboardingStep3CenterContainer>
        <StepperHeader>Korak 3/4</StepperHeader>
        <StepperSubHeader>Dodatne informacije</StepperSubHeader>

        <MainContentContainer>
          <span>Dodajte kratak opis Vašeg salona</span>
          <InputField
            label="Opis"
            multiline
            rows={6}
            placeholder="Opis"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <span>Foto galerija</span>
          <ImagesContainer>
            {images.map((imageUrl, index) => (
              <ImageWithDeleteButton
                key={index}
                imageUrl={`data:image/png;base64, ${imageUrl}`}
                onDelete={() => handleDeleteImage(index)}
              />
            ))}
          </ImagesContainer>
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton onClick={() => handleImageUpload(handleChange, true)}>
              <AddIcon
                style={{
                  color: COLORS.white,
                  fontSize: isMobile ? "1rem" : "1.5rem",
                }}
              />
            </IconButton>
            <div style={{ marginLeft: "0.7rem" }}>
              <StepperSubHeader>Dodajte fotografije</StepperSubHeader>
            </div>
          </div>
        </MainContentContainer>

        <div
          style={{
            marginTop: isMobile ? "2.6rem" : "3.6rem",
            marginBottom: "2rem",
          }}
        >
          <PrimaryButton
            text={"Sledeći korak"}
            width={isMobile ? "23rem" : "30rem"}
            onClick={finishStep3}
          />
        </div>
      </OnboardingStep3CenterContainer>
    </OnboardingMainContainer>
  );
};
