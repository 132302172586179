import { useQuery } from "react-query";
import { SalonWorkingDay } from "../pages/onBoarding/types/step2";
import apiClient from "./api/api";
import { PREFIX_SALONS_WEB, SALONS_WEB_HOURS, SALONS_WEB_IMAGES, SALONS_WEB_INFO, SALONS_WORKING_HOURS } from "./routes/routes";
import { SALONS_WEB_IMAGES_KEY, SALONS_WEB_KEY } from "../constants/queryKeys";

export interface ISalonWebInfo {
    name: string;
    address: string;
    mobilePhone: string;
    description?: string;
    cityPartId: number | undefined;
    cityId: number | undefined;
    coverImage?: string;
}

export interface SalonImage {
  id: number;
  salonImagesUrl: string;
}

export const addSalonsWorkingHours = async (workingDays: SalonWorkingDay[]) => {
    const { data } = await apiClient.post(SALONS_WORKING_HOURS, workingDays);
    return data;
};

export const getSalonsWebInfo = async () => {
    const { data } = await apiClient.get(SALONS_WEB_INFO);
    return data;
}

export const useSalonWebInfo = (enabled = true) => {
  const { data, isLoading, isError, isFetching, refetch, isSuccess } = useQuery(
    [SALONS_WEB_KEY],
    () => getSalonsWebInfo(),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled,
    }
  );

  return {
    data: data ?? [],
    isLoading,
    isError,
    isFetching,
    refetch,
    isSuccess
  };
};

export const editSalonWebInfo = async (salonInfo: ISalonWebInfo) => {
  const { data } = await apiClient.put(PREFIX_SALONS_WEB, salonInfo);
  return data;
};

export const getSalonsWebImages = async () => {
    const { data } = await apiClient.get(SALONS_WEB_IMAGES);
    return data;
}

export const useSalonWebImages = (enabled = true) => {
  const { data, isLoading, isError, isFetching, refetch, isSuccess } = useQuery(
    [SALONS_WEB_IMAGES_KEY],
    () => getSalonsWebImages(),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled,
    }
  );

  return {
    data: data ?? [],
    isLoading,
    isError,
    isFetching,
    refetch,
    isSuccess
  };
};

export const deleteSalonWebImages = async (ids: number[]) => {
    await apiClient.delete(SALONS_WEB_IMAGES, {data: {ids: ids}})
}

export const getSalonWorkingHours = async () => {
    const { data } = await apiClient.get(SALONS_WEB_HOURS);
    return data;
}

export const useSalonWorkingHours = (enabled = true) => {
  const { data, isLoading, isError, isFetching, refetch, isSuccess } = useQuery(
    [SALONS_WEB_HOURS],
    () => getSalonWorkingHours(),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled,
    }
  );

  return {
    data: data ?? [],
    isLoading,
    isError,
    isFetching,
    refetch,
    isSuccess
  };
};
