import { styled } from "@mui/system";
import { Icon } from "@mui/material";

import { COLORS } from "../../constants/colors";

export const OnboardingMainContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  padding: "20px",
}));

export const OnboardingScrollableContainer = styled("div")(({ theme }) => ({
  overflowY: "auto",
  overflowX: "hidden",
  maxHeight: "calc(100vh - 12.5rem)",
  [theme.breakpoints.down("sm")]: {
    maxHeight: "calc(100vh - 6.25rem)",
  },
}));

export const OnboardingCenterContainer = styled("div")(({ theme }) => ({
  marginTop: "1.25rem",
  maxWidth: "50rem",
  width: "100%",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    maxWidth: "22rem",
  },
}));

export const OnboardingImageContainer = styled("div")(({ theme }) => ({
  cursor: "pointer",
  position: "relative",
  width: "100%",
  height: "10rem",
  marginTop: "2rem",

  border: `1px solid ${COLORS.lightGray}`,
  borderRadius: "0.5625rem",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "7rem",
    borderRadius: "0.5625rem",
    flexShrink: "0rem",
    marginTop: "1.25rem",
    objectFit: "containe",
    backgroundColor: COLORS.lightGray,
  },
}));

export const OnboardingIcon = styled(Icon)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    width: "19.6875rem",
    borderRadius: "0.5625rem",
    objectFit: "contain",
    backgroundColor: COLORS.lightGray,
  },
}));

export const OnboardingAddIcon = styled(Icon)(({ theme }) => ({
  position: "absolute",
  top: "-0.9375rem",
  right: "-0.9375rem",
  width: "1.6875rem",
  height: "1.6875rem",
  backgroundColor: COLORS.secondary,
  borderRadius: "50%",
  padding: "0.3125rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    top: "auto",
    bottom: "0rem",
    right: "-0.625rem",
    width: "1rem",
    height: "1rem",
  },
}));

export const OnboardingSelectedImage = styled("img")(({ theme }) => ({
  position: "absolute",
  top: "0rem",
  left: "0rem",
  width: "100%",
  height: "100%",
  objectFit: "cover",
  borderRadius: "0.5625rem",
  [theme.breakpoints.down("sm")]: {
    borderRadius: "0.5625rem",
    objectFit: "cover",
    backgroundColor: COLORS.lightGray,
  },
}));
