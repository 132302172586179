import { Grid, IconButton, useMediaQuery } from "@mui/material";
import { AppointmentInfo } from "../../services/analytics";
import {
  ActionsMobile,
  Separator,
  StyledDataGrid,
  StyledPaper,
  StyledTableContainer,
  TableContainerMobile,
} from "./TableDataGrid.styles";
import { ServicesText } from "../../pages/services/Services.styles";
import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { useState } from "react";
import { Close, Done } from "@mui/icons-material";
import {
  PriceCol,
  StatusReservation,
} from "../../pages/analyticsPage/AnalyticsPage.styles";
import { COLORS } from "../../constants/colors";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface AnalyticsTableProps {
  searchedData: AppointmentInfo[];
}

const AnalyticsTable = ({ searchedData }: AnalyticsTableProps) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const searchResult = {
    manual: searchedData?.filter(
      (appointment) => appointment.isManualReservation
    ),
    petify: searchedData?.filter(
      (appointment) => !appointment.isManualReservation
    ),
  };

  const getRowHeight = (params: any) => {
    return expandedRow === params.id ? "auto" : 85;
  };

  const manualRows = searchResult?.manual?.map((row, index) => ({
    ...row,
    id: `${row.appointmentId}-${index}`,
  }));

  const petifyRows = searchResult?.petify?.map((row, index) => ({
    ...row,
    id: `${row.appointmentId}-${index}`,
  }));

  const handleArrowClick = (rowId: number) => {
    setExpandedRow((prevRow) => (prevRow === rowId ? null : rowId));
  };

  function ActionCellMobile(params) {
    const isExpanded = expandedRow === params.row.id;

    return (
      <ActionsMobile>
        <IconButton
          style={{
            color: COLORS.mediumGray,
            fontSize: 20,
          }}
          onClick={() => handleArrowClick(params.row.id)}
        >
          {isExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </IconButton>
      </ActionsMobile>
    );
  }

  function getTime(params: GridValueGetterParams) {
    return `${params.row.appointmentTime} ${params.row.appointmentDate}`;
  }

  const columnsManual: GridColDef[] = isMobile
    ? [
        {
          field: "dateTime",
          headerName: "",
          sortable: false,
          flex: 0.8,
          valueGetter: getTime,
          renderCell: (params) => {
            const isExpanded = expandedRow === params.row.id;
            const isManual = params.row.isManualReservation;
            const isCanceled = params.row.isCanceled;
            return (
              <div style={{ marginTop: isExpanded ? "0.5rem" : undefined }}>
                <div>{params.value}</div>
                {isExpanded && (
                  <>
                    <TableContainerMobile>
                      <Separator />
                      <Grid container spacing={1}>
                        <Grid item xs={4}>
                          Vlasnik:
                        </Grid>
                        <Grid item xs={8}>
                          {params.row.owner.name}
                        </Grid>
                        <Grid item xs={4}>
                          Cena:
                        </Grid>
                        <Grid item xs={8}>
                          {isCanceled ? (
                            <Grid item xs={12}>
                              0
                            </Grid>
                          ) : (
                            <Grid container spacing={0.5}>
                              <Grid item xs={12}>
                                {params.row.priceAnalytics.totalAmount} RSD -
                                Ukupno
                              </Grid>
                              <Grid item xs={12} color={COLORS.secondary}>
                                {isManual
                                  ? 0
                                  : params.row.priceAnalytics.petifyShare}{" "}
                                RSD - Petify deo
                              </Grid>
                              <Grid item xs={12} color={COLORS.primary}>
                                {isManual
                                  ? params.row.priceAnalytics.totalAmount
                                  : params.row.priceAnalytics.salonShare}{" "}
                                RSD - Zarada
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                        <Grid item xs={4}>
                          Usluge:
                        </Grid>
                        <Grid item xs={8}>
                          {params.row.services.map((service) => (
                            <div
                              style={{ paddingBottom: "5px" }}
                              key={service.key}
                            >
                              {service.value}
                            </div>
                          ))}
                        </Grid>
                        <Grid item xs={4}>
                          Status:
                        </Grid>
                        <Grid item xs={8}>
                          {isCanceled ? (
                            <StatusReservation color={COLORS.error_700}>
                              Otkazano
                            </StatusReservation>
                          ) : (
                            <StatusReservation
                              color={COLORS.success_green_dark}
                            >
                              Ispunjeno
                            </StatusReservation>
                          )}
                        </Grid>
                      </Grid>
                    </TableContainerMobile>
                  </>
                )}
              </div>
            );
          },
        },
        {
          field: "actions",
          headerName: "",
          sortable: false,
          flex: 0.2,
          renderCell: ActionCellMobile,
        },
      ]
    : [
        {
          field: "appointmentDateTime",
          headerName: "Datum i vreme",
          flex: 0.5,
          sortable: false,
          renderCell: (params) => {
            return (
              <PriceCol>
                <span>{params.row.appointmentTime}</span>
                <span>{params.row.appointmentDate}</span>
              </PriceCol>
            );
          },
        },
        {
          field: "owner.name",
          headerName: "Vlasnik",
          flex: 0.6,
          sortable: false,
          valueGetter: (params: GridValueGetterParams) =>
            `${params.row.owner?.name}`,
        },
        {
          field: "pricing",
          headerName: "Cena",
          flex: 0.6,
          sortable: false,
          renderCell: (params) => {
            const { petifyShare, salonShare, totalAmount } =
              params.row.priceAnalytics || {};
            const isManual = params.row.isManualReservation;
            const isCanceled = params.row.isCanceled;
            return (
              <PriceCol>
                {isCanceled ? (
                  <span>0 RSD</span>
                ) : (
                  <>
                    <span>Ukupno: {totalAmount} RSD</span>
                    <span style={{ color: COLORS.secondary }}>
                      Petify deo: {isManual ? 0 : petifyShare} RSD
                    </span>
                    <span style={{ color: COLORS.primary }}>
                      Zarada: {isManual ? totalAmount : salonShare} RSD
                    </span>
                  </>
                )}
              </PriceCol>
            );
          },
        },
        {
          field: "services",
          headerName: "Usluge",
          flex: 1,
          sortable: false,
          renderCell: (params) => {
            return (
              <div>
                {params.row.services.map((service) => (
                  <span key={service.key}>
                    {service.value}
                    <br />
                  </span>
                ))}
              </div>
            );
          },
        },
        {
          field: "isCanceled",
          headerName: "Izvršeno",
          flex: 0.25,
          sortable: false,
          renderCell: (params) => {
            return params.row.isCanceled ? (
              <Close style={{ color: COLORS.error_700 }} />
            ) : (
              <Done style={{ color: COLORS.success_green_dark }} />
            );
          },
        },
      ];

  return (
    <div>
      {searchResult.petify.length > 0 && (
        <StyledTableContainer>
          <StyledPaper>
            <Grid container alignItems="center">
              <Grid item xs={10}>
                <ServicesText>Petify Rezervacije</ServicesText>
              </Grid>
            </Grid>
            <StyledDataGrid
              justifyContent={"start"}
              rows={petifyRows || []}
              columns={columnsManual}
              hideFooterPagination
              hideFooter
              disableColumnFilter
              disableColumnMenu
              disableColumnSelector
              disableDensitySelector
              hideFooterSelectedRowCount
              disableRowSelectionOnClick={true}
              columnHeaderHeight={isMobile ? 0 : 55}
              rowHeight={85}
              getRowHeight={getRowHeight}
              getRowClassName={(params) =>
                expandedRow === params.id ? "expanded-row" : ""
              }
              style={{ width: "100%" }}
            />
          </StyledPaper>
        </StyledTableContainer>
      )}
      {searchResult.manual.length > 0 && (
        <StyledTableContainer>
          <StyledPaper>
            <Grid container alignItems="center">
              <Grid item xs={10}>
                <ServicesText>Rucne Rezervacije</ServicesText>
              </Grid>
            </Grid>
            <StyledDataGrid
              justifyContent={"start"}
              rows={manualRows || []}
              columns={columnsManual}
              hideFooterPagination
              hideFooter
              disableColumnFilter
              disableColumnMenu
              disableColumnSelector
              disableDensitySelector
              hideFooterSelectedRowCount
              disableRowSelectionOnClick={true}
              columnHeaderHeight={isMobile ? 0 : 55}
              rowHeight={85}
              getRowHeight={getRowHeight}
              getRowClassName={(params) =>
                expandedRow === params.id ? "expanded-row" : ""
              }
              style={{ width: "100%" }}
            />
          </StyledPaper>
        </StyledTableContainer>
      )}
    </div>
  );
};

export default AnalyticsTable;
