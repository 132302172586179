import React from "react";
import { useNavigate } from "react-router-dom";

import PetifyLogo from "../../assets/svg/PetifyLogo";
import { PrimaryButton } from "../../components/buttons/primaryButton/PrimaryButton";
import { SecondaryButton } from "../../components/buttons/secondaryButton/SecondaryButton";
import {
  Header,
  StyledCenterContainer,
  StyledMainContainer,
  StyledSupportButton,
  SubHeader,
} from "../../constants/customStyles";
import { HELP } from "../../constants/urlPaths";

export const RegistrationSuccess = () => {
  const navigate = useNavigate();

  return (
    <StyledMainContainer>
      <StyledSupportButton onClick={() => navigate(HELP)} />
      <StyledCenterContainer>
        <PetifyLogo />
        <Header>Čestitamo, uspešno ste se registrovali!</Header>
        <SubHeader>
          Sada je vreme da u nekoliko jednostavnih koraka napravite profil Vašeg
          salona.
        </SubHeader>
        <div style={{ marginTop: "1.3125rem" }}>
          <PrimaryButton text={"Ok"} onClick={() => navigate("/onboard")} />
        </div>
        <div style={{ marginTop: "1.3125rem" }}>
          <SecondaryButton text={"Uradiću to kasnije"} onClick={() => {}} />
        </div>
      </StyledCenterContainer>
    </StyledMainContainer>
  );
};
