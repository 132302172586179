import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useState } from "react";
import UniversalModal from "./UniversalModal";
import { InputField } from "../inputs/TextField";
import { COLORS } from "../../constants/colors";
import { PrimaryButton } from "../buttons/primaryButton/PrimaryButton";
import {
  StyledContainer,
  StyledHeaderContainer,
  StyledHeaderTitle,
} from "./CreateGroupModal.styles";
import { IconButton } from "../buttons/iconButton/IconButton";
import { ClearIcon } from "@mui/x-date-pickers";

interface CreateGroupModalProps {
  isModalOpen: boolean;
  handleClose: any;
  onSave: (groupName: string, checkGroup: boolean) => void;
}

const CreateGroupModal = ({
  isModalOpen,
  handleClose,
  onSave,
}: CreateGroupModalProps) => {
  const [groupName, setGroupName] = useState<string>("");
  const [checked, setChecked] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  return (
    <UniversalModal
      width={
        window.innerWidth >= 900
          ? "28.75rem"
          : window.innerWidth >= 600
          ? "28.75rem"
          : "90%"
      }
      isModalOpen={isModalOpen}
      handleClose={handleClose}
    >
      <StyledContainer>
        <StyledHeaderContainer>
          <StyledHeaderTitle>Dodavanje nove usluge</StyledHeaderTitle>
          <IconButton
            style={{ background: COLORS.white }}
            onClick={handleClose}
          >
            <ClearIcon />
          </IconButton>
        </StyledHeaderContainer>
        <InputField
          label="Ime grupe"
          placeholder="Ime grupe"
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
        />
        <FormControlLabel
          control={
            <Checkbox
              style={{ color: COLORS.primary }}
              checked={checked}
              onChange={handleChange}
            />
          }
          label="Selektuj ovu grupu nakon dodavanja"
        />
        <PrimaryButton
          text={"Sačuvaj"}
          width={"100%"}
          onClick={() => onSave(groupName, checked)}
        />
      </StyledContainer>
    </UniversalModal>
  );
};

export default CreateGroupModal;
