import { IS_PASSWORD } from "../../regexRules";
import { Validated } from "../Validator";

export default function isPasswordStrong<TError>(
  value: string,
  error: TError
): Validated<string, TError> {
  return !IS_PASSWORD.test(value)
    ? {
        isValid: false,
        error,
      }
    : {
        isValid: true,
        value,
      };
}
