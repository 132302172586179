import * as React from "react";
import { useEffect, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Grid, IconButton, useMediaQuery } from "@mui/material";
import { COLORS } from "../../constants/colors";
import {
  Separator,
  StyledDataGrid,
  StyledPaper,
  StyledTableContainer,
} from "./TableDataGrid.styles";
import { ServicesText } from "../../pages/services/Services.styles";
import {
  Severity,
  useNotificationContext,
} from "../../context/NotificationContext";
import ConfirmModal from "../modal/ConfirmModal";
import {
  IWorkerDaysOff,
  deleteDaysOffForWorker,
  useDaysOfForAll,
} from "../../services/daysOff";
import { useMutation } from "react-query";
import DaysOffModal from "../modal/DaysOffModal";
import { IS_WORKER } from "../../constants/localStorageKeys";
import { useWorkers } from "../../services/workers";

interface WorkerDayOff {
  workerId: number;
  startDate: string;
  endDate: string;
  name?: string;
}

const WorkerDaysOffTable = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isWorker = JSON.parse(localStorage.getItem(IS_WORKER) ?? "false");
  const { showNotification } = useNotificationContext();
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const defaultRowHeight = 55;
  const [newDataWorkersDaysOff, setNewDataWorkersDaysOff] = useState<
    WorkerDayOff[]
  >([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isDaysOffModalOpen, setIsDaysOffModalOpen] = useState<boolean>(false);
  const [deleteDaysoff, setDeleteDaysOff] = useState<IWorkerDaysOff>({
    startDate: "",
    endDate: "",
    workerId: 0,
  });
  const { data: dataWorkersDaysOff, isSuccess, refetch } = useDaysOfForAll();
  const { data: dataWorkers } = useWorkers();

  useEffect(() => {
    if (dataWorkersDaysOff.result && dataWorkers.result) {
      const newDataWorkersDaysOff = dataWorkersDaysOff.result.map(
        (workerDayOff) => {
          const worker = dataWorkers.result.find(
            (worker) => worker.id === workerDayOff.workerId
          );
          if (worker) {
            return {
              ...workerDayOff,
              name: worker.fullName,
            };
          }
          return workerDayOff;
        }
      );
      setNewDataWorkersDaysOff(newDataWorkersDaysOff);
    }
  }, [isSuccess, dataWorkersDaysOff.result, dataWorkers.result]);

  const { mutate: deleteDaysOffWorkerMutation } = useMutation(
    deleteDaysOffForWorker,
    {
      onSuccess: () => {
        setIsConfirmModalOpen(false);
        showNotification("Uspesno obrisano.", Severity.Success);
        refetch();
      },
      onError: (error) => {
        showNotification("Greška prilikom brisanja", Severity.Error);
      },
    }
  );

  const handleArrowClick = (rowId: number) => {
    setExpandedRow((prevRow) => (prevRow === rowId ? null : rowId));
  };

  const getRowHeight = (params: any) => {
    return expandedRow === params.id ? "auto" : defaultRowHeight;
  };

  const rowsWithIdsWorker = newDataWorkersDaysOff?.map((row, index) => ({
    ...row,
    id: `${row.startDate}-${row.endDate}-${index}`,
  }));

  const openConfirmModal = (
    open: boolean,
    start?: string,
    end?: string,
    id?: number
  ) => {
    open &&
      start &&
      end &&
      id &&
      setDeleteDaysOff({ startDate: start, endDate: end, workerId: id });
    setIsConfirmModalOpen(open);
  };

  const handleDaysOffModal = (
    open: boolean,
    start?: string,
    end?: string,
    id?: number
  ) => {
    open &&
      start &&
      end &&
      id &&
      setDeleteDaysOff({ startDate: start, endDate: end, workerId: id });
    setIsDaysOffModalOpen(open);
  };

  const columnsWorkers: GridColDef[] = isMobile
    ? [
        {
          field: "name",
          headerName: "",
          width: 200,
          sortable: false,
          renderCell: (params) => {
            const isExpanded = expandedRow === params.row.id;
            return (
              <div style={{ marginTop: isExpanded ? "0.5rem" : undefined }}>
                <div>{params.value}</div>
                {isExpanded && (
                  <>
                    <div
                      style={{
                        marginBottom: "1rem",
                      }}
                    >
                      <Separator />
                      <Grid container spacing={1}>
                        <Grid item xs={5}>
                          Ime radnika:
                        </Grid>
                        <Grid item xs={7}>
                          {params.row.name}
                        </Grid>
                        <Grid item xs={5}>
                          Od:
                        </Grid>
                        <Grid item xs={7}>
                          {params.row.startDate}
                        </Grid>
                        <Grid item xs={5}>
                          Do:
                        </Grid>
                        <Grid item xs={7}>
                          {params.row.endDate}
                        </Grid>
                      </Grid>
                    </div>
                  </>
                )}
              </div>
            );
          },
        },
        {
          field: "actions",
          headerName: "",
          sortable: false,
          width: 150,
          renderCell: isWorker ? undefined : ActionCellMobile,
        },
      ]
    : [
        {
          field: "name",
          headerName: "Ime",
          flex: 1,
          sortable: false,
        },
        {
          field: "startDate",
          headerName: "Od",
          flex: 1,
          sortable: false,
        },
        {
          field: "endDate",
          headerName: "Do",
          flex: 1,
          sortable: false,
        },
        {
          field: "actions",
          headerName: "",
          sortable: false,
          flex: 1,
          renderCell: isWorker ? undefined : ActionCell,
        },
      ];

  function ActionCell(params) {
    return (
      <div>
        <IconButton
          style={{ color: COLORS.yellow_800 }}
          onClick={() =>
            handleDaysOffModal(
              true,
              params.row.startDate,
              params.row.endDate,
              params.row.workerId
            )
          }
        >
          <BorderColorIcon />
        </IconButton>
        <IconButton
          style={{ color: COLORS.primary }}
          onClick={() =>
            openConfirmModal(
              true,
              params.row.startDate,
              params.row.endDate,
              params.row.workerId
            )
          }
        >
          <DeleteIcon />
        </IconButton>
      </div>
    );
  }

  function ActionCellMobile(params) {
    const isExpanded = expandedRow === params.row.id;

    return (
      <div
        style={{
          marginLeft: isExpanded ? undefined : "5rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {isExpanded && (
          <>
            <IconButton
              style={{ color: COLORS.yellow_800 }}
              onClick={() =>
                handleDaysOffModal(
                  true,
                  params.row.startDate,
                  params.row.endDate,
                  params.row.workerId
                )
              }
            >
              <BorderColorIcon />
            </IconButton>
            <IconButton
              style={{ color: COLORS.primary }}
              onClick={() =>
                openConfirmModal(
                  true,
                  params.row.startDate,
                  params.row.endDate,
                  params.row.workerId
                )
              }
            >
              <DeleteIcon />
            </IconButton>
          </>
        )}
        <IconButton
          style={{
            color: COLORS.mediumGray,
            fontSize: 20,
          }}
          onClick={() => handleArrowClick(params.row.id)}
        >
          {isExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </IconButton>
      </div>
    );
  }

  return (
    <StyledTableContainer>
      <StyledPaper>
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <ServicesText>Neradni dani radnika</ServicesText>
          </Grid>
        </Grid>
        <StyledDataGrid
          rows={rowsWithIdsWorker || []}
          columns={columnsWorkers}
          hideFooterPagination
          hideFooter
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          disableDensitySelector
          hideFooterSelectedRowCount
          disableRowSelectionOnClick={true}
          columnHeaderHeight={isMobile ? 0 : 55}
          rowHeight={defaultRowHeight}
          getRowHeight={getRowHeight}
          getRowClassName={(params) =>
            expandedRow === params.id ? "expanded-row" : ""
          }
          style={{ width: "100%" }}
        />
      </StyledPaper>
      {isConfirmModalOpen && (
        <ConfirmModal
          isModalOpen={isConfirmModalOpen}
          handleClose={() => {
            openConfirmModal(false);
            refetch();
          }}
          onSave={() => deleteDaysOffWorkerMutation(deleteDaysoff)}
          actionToConfirm="želite da obrišete"
        />
      )}
      {isDaysOffModalOpen && (
        <DaysOffModal
          workerId={deleteDaysoff.workerId}
          isModalOpen={isDaysOffModalOpen}
          handleClose={() => {
            handleDaysOffModal(false);
            refetch();
          }}
          title="Godisnji odmor"
          data={deleteDaysoff}
        />
      )}
    </StyledTableContainer>
  );
};
export default WorkerDaysOffTable;
