import * as React from "react";
import { useState } from "react";
import {
  GridColDef,
  GridSortItem,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Grid, IconButton, useMediaQuery } from "@mui/material";

import { COLORS } from "../../constants/colors";
import {
  ActionsMobile,
  Separator,
  StyledDataGrid,
  StyledPaper,
  StyledTableContainer,
  TableContainerMobile,
  TimeTableCol,
} from "./TableDataGrid.styles";
import { ServicesText } from "../../pages/services/Services.styles";
import CreateEditServiceModal from "../modal/CreateEditServiceModal";
import ConfirmModal from "../modal/ConfirmModal";
import { deleteService, useServicesByGroup } from "../../services/services";
import { IGroupServiceList } from "../../pages/services/ServiceType";
import { useMutation } from "react-query";
import { formatDuration } from "./ServiceTable.Helper";
import {
  Severity,
  useNotificationContext,
} from "../../context/NotificationContext";

export interface IServiceGroup {
  groupId: string;
  groupName: string;
  service: {
    serviceId: number;
    serviceName: string;
    description: string;
    duration: string;
    price: string;
  };
}

const sortTableByName: GridSortItem[] = [
  {
    field: "name",
    sort: "asc",
  },
];

const ServicesTable = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:1400px)");
  const isMiniTablet = useMediaQuery("(max-width:1000px)");
  const { showNotification } = useNotificationContext();
  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [isCreateEditServiceModalOpen, setIsCreateEditServiceModalOpen] =
    useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [serviceInfo, setServiceInfo] = useState<IServiceGroup | null>(null);
  const defaultRowHeight = 55;
  const [deleteServiceId, setDeleteServiceId] = useState<number>(-1);

  const { data, refetch } = useServicesByGroup();

  const { mutate: deleteServiceMutation } = useMutation(deleteService, {
    onSuccess: () => {
      setIsConfirmModalOpen(false);
      refetch();
      showNotification("Usluga uspesno obrisana.", Severity.Success);
    },
    onError: (error) => {
      showNotification("Greška prilikom brisanja servisa", Severity.Error);
    },
  });

  const handleArrowClick = (rowId: number) => {
    setExpandedRow((prevRow) => (prevRow === rowId ? null : rowId));
  };

  const getRowHeight = (params: any) => {
    return expandedRow === params.id ? "auto" : defaultRowHeight;
  };

  const closeServiceModal = () => {
    setIsCreateEditServiceModalOpen(false);
    refetch();
  };

  const openConfirmModal = (open: boolean, serviceId?: number) => {
    open && serviceId && setDeleteServiceId(serviceId);
    setIsConfirmModalOpen(open);
  };

  const getGroupInfo = (serviceId: string) => {
    for (const group of data) {
      for (const service of group.services) {
        if (service.id === serviceId) {
          return {
            groupId: group.id,
            groupName: group.name,
          };
        }
      }
    }
    return {
      groupId: "",
      groupName: "",
    };
  };

  const handleEdit = (service: any) => {
    const { groupId, groupName } = getGroupInfo(service.id);
    const formattedServiceInfo: IServiceGroup = {
      groupId: groupId,
      groupName: groupName,
      service: {
        serviceId: service.id,
        serviceName: service.name,
        description: service.description,
        duration: formatDuration(service.duration),
        price: service.price.amount,
      },
    };

    setServiceInfo(formattedServiceInfo);
    setIsCreateEditServiceModalOpen(true);
  };

  function getPrice(params: GridValueGetterParams) {
    return `${params.row.price?.amount} ${params.row.price?.currency.alphaCode}`;
  }

  const columns: GridColDef[] = isMobile
    ? [
        {
          field: "name",
          headerName: "",
          flex: 0.8,
          sortable: false,
          renderCell: (params) => {
            const isExpanded = expandedRow === params.row.id;
            return (
              <div style={{ marginTop: isExpanded ? "0.5rem" : undefined }}>
                <div>{params.value}</div>
                {isExpanded && (
                  <>
                    <TableContainerMobile>
                      <Separator />
                      <Grid container spacing={1}>
                        <Grid item xs={4}>
                          Vreme trajanja:
                        </Grid>
                        <Grid item xs={8}>
                          {params.row.duration} {"min"}
                        </Grid>
                        <Grid item xs={4}>
                          Cena:
                        </Grid>
                        <Grid item xs={8}>
                          {params.row.price.amount} {"RSD"}
                        </Grid>
                        <Grid item xs={4}>
                          Opis:
                        </Grid>
                        <Grid item xs={8}>
                          {params.row.description}
                        </Grid>
                      </Grid>
                    </TableContainerMobile>
                  </>
                )}
              </div>
            );
          },
        },
        {
          field: "actions",
          headerName: "",
          sortable: false,
          flex: 0.2,
          renderCell: ActionCellMobile,
        },
      ]
    : [
        {
          field: "name",
          headerName: "Usluga",
          sortable: false,
          flex: 1,
        },
        {
          field: "duration",
          headerName: "Vreme trajanja",
          sortable: false,
          flex: 0.5,
          renderCell: (params) => {
            return <TimeTableCol>{params.row.duration} min</TimeTableCol>;
          },
        },
        {
          field: "price.amount",
          headerName: "Cena",
          sortable: false,
          valueGetter: getPrice,
          flex: 0.5,
        },
        {
          field: "description",
          headerName: "Opis",
          sortable: false,
          flex: isMiniTablet ? 1 : 1.5,
        },
        {
          field: "actions",
          headerName: "",
          sortable: false,
          renderCell: ActionCell,
          flex: 0.5,
        },
      ];

  function ActionCell(params) {
    return (
      <div>
        <IconButton
          style={{ color: COLORS.yellow_800 }}
          onClick={() => handleEdit(params.row)}
        >
          <BorderColorIcon />
        </IconButton>
        <IconButton
          style={{ color: COLORS.primary }}
          onClick={() => openConfirmModal(true, params.row.id)}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    );
  }

  function ActionCellMobile(params) {
    const isExpanded = expandedRow === params.row.id;

    return (
      <ActionsMobile>
        {isExpanded && (
          <>
            <IconButton
              style={{ color: COLORS.yellow_800 }}
              onClick={() => handleEdit(params.row)}
            >
              <BorderColorIcon />
            </IconButton>
            <IconButton
              style={{ color: COLORS.primary }}
              onClick={() => openConfirmModal(true, params.row.id)}
            >
              <DeleteIcon />
            </IconButton>
          </>
        )}
        <IconButton
          style={{
            color: COLORS.mediumGray,
            fontSize: 20,
          }}
          onClick={() => handleArrowClick(params.row.id)}
        >
          {isExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </IconButton>
      </ActionsMobile>
    );
  }

  return (
    <StyledTableContainer>
      <StyledPaper>
        {data
          ?.filter((serviceGroup: IGroupServiceList) =>
            serviceGroup.services.some((service) => !service.isDeleted)
          )
          .map((serviceGroup: IGroupServiceList) => (
            <div key={serviceGroup.id}>
              <Grid container alignItems="center">
                <Grid item xs={10}>
                  <ServicesText>{serviceGroup.name}</ServicesText>
                </Grid>
              </Grid>
              <StyledDataGrid
                justifyContent={isMobile ? "start" : "end"}
                rows={serviceGroup.services.filter(
                  (service) => !service.isDeleted
                )}
                sortModel={sortTableByName}
                columns={columns}
                hideFooterPagination
                hideFooter
                disableColumnFilter
                disableColumnMenu
                disableColumnSelector
                disableDensitySelector
                hideFooterSelectedRowCount
                disableRowSelectionOnClick={true}
                columnHeaderHeight={isMobile ? 0 : 55}
                rowHeight={defaultRowHeight}
                getRowHeight={getRowHeight}
                getRowClassName={(params) =>
                  expandedRow === params.id ? "expanded-row" : ""
                }
                style={{ width: "100%" }}
              />
            </div>
          ))}
      </StyledPaper>
      {isCreateEditServiceModalOpen && (
        <CreateEditServiceModal
          isModalOpen={isCreateEditServiceModalOpen}
          handleClose={() => closeServiceModal()}
          service={serviceInfo ?? undefined}
        />
      )}

      {isConfirmModalOpen && (
        <ConfirmModal
          isModalOpen={isConfirmModalOpen}
          handleClose={() => openConfirmModal(false)}
          onSave={() => deleteServiceMutation(deleteServiceId)}
          actionToConfirm="želite da obrišete"
        />
      )}
    </StyledTableContainer>
  );
};
export default ServicesTable;
