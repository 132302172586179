import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Snackbar, styled } from '@mui/material';
import Alert, { AlertColor, AlertProps } from '@mui/material/Alert';
import React, { FunctionComponent } from 'react';

export type Severity = AlertColor;

export interface NotificationProps {
    severity: Severity;
    onClose: () => void;
}

// define custom colors background 
const CUSTOM_COLORS_MAPPING = {
    success: 'red',
    info: 'blue',
    error: 'blue',
    warning: 'blue',
}

interface IAlert extends AlertProps {
    background?: string
}

export const AlertStyled = styled(Alert)<IAlert>(({ background }) => ({
    background,
}))

const Notification: FunctionComponent<React.PropsWithChildren<NotificationProps>> = (
    { severity, onClose, children },
) => (
    <Snackbar
        open={true}
        autoHideDuration={5000}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        disableWindowBlurListener={true}
        onClose={(_event, reason) => {
            if (reason !== 'clickaway') {
                onClose();
            }
        }}
    >
        <AlertStyled
            variant="filled"
            iconMapping={{ success: <CheckCircleOutlineIcon fontSize="inherit" /> }}
            severity={severity}
            onClose={onClose}
            // background={CUSTOM_COLORS_MAPPING[severity]}
        >
            {children}
        </AlertStyled>
    </Snackbar>
);

export default Notification;
