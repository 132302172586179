/* eslint-disable  react-hooks/exhaustive-deps */
import React, { useState, useEffect, ChangeEvent } from "react";
import { useMutation } from "react-query";
import { Grid, useMediaQuery } from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import AddIcon from "@mui/icons-material/Add";

import { InputField } from "../../components/inputs/TextField";
import { PrimaryButton } from "../../components/buttons/primaryButton/PrimaryButton";
import {
  OnboardingAddIcon,
  OnboardingCenterContainer,
  OnboardingIcon,
  OnboardingImageContainer,
  OnboardingMainContainer,
  OnboardingSelectedImage,
} from "./Onboarding.styles";
import { COLORS } from "../../constants/colors";
import {
  StepperHeader,
  StepperSubHeader,
  StyledSupportButton,
} from "../../constants/customStyles";
import DropdownField from "../../components/dropdownOnboarding/Dropdown";
import {
  addSalonGeneralInfo,
  getCities,
  getCityParts,
} from "../../services/onboarding";

import { InputError } from "../../helpers/inputValidation/InputError";
import isFilledString from "../../helpers/inputValidation/validators/isFilledString";
import allValid from "../../helpers/inputValidation/validators/allValid";
import and from "../../helpers/inputValidation/validators/and";
import isPhoneNumber from "../../helpers/inputValidation/validators/isPhoneNumber";
import { convertDataUrlToBase64 } from "./Step3.helper";
import { useNavigate } from "react-router-dom";
import { handleImageUpload } from "../../helpers/imageUpload";
import { StyledColumnGrid } from "../profile/Profile.styles";
import { HELP } from "../../constants/urlPaths";

export interface City {
  value: string;
  key: number;
}

export interface CityPart {
  key: string;
  value: string;
}

export const Step1 = () => {
  const isMobile = useMediaQuery("(max-width:600px)");

  const [cities, setCities] = useState<City[]>([]);
  const [cityParts, setCityParts] = useState<CityPart[]>([]);
  const [cityId, setCityId] = useState<number | null>(null);
  const [cityPartId, setCityPartId] = useState<number | null>(null);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [mobilePhone, setMobilePhone] = useState("");
  const [country, setConutry] = useState("Serbia");
  const [pib, setPib] = useState("");
  const [phoneError, setPhoneError] = useState<InputError | null>(null);
  const [nameError, setNameError] = useState<InputError | null>(null);
  const [addressError, setAddressError] = useState<InputError | null>(null);
  const [countryError, setCountryError] = useState<InputError | null>(null);
  const [pibError, setPibError] = useState<InputError | null>(null);
  const [coverImage, setCoverImage] = useState<string>("");
  const navigate = useNavigate();

  const validatePhoneNumber = (): boolean => {
    const validated = {
      mobilePhone: and(isFilledString(mobilePhone, InputError.Empty), () =>
        isPhoneNumber(mobilePhone, InputError.InvalidPhoneNumber)
      ),
    };
    if (!allValid(validated)) {
      setPhoneError(
        validated.mobilePhone.isValid ? null : validated.mobilePhone.error
      );

      return false;
    }
    setPhoneError(null);

    return true;
  };

  const validateFormFields = (): boolean => {
    const validated = {
      mobilePhone: and(isFilledString(mobilePhone, InputError.Empty), () =>
        isPhoneNumber(mobilePhone, InputError.InvalidPhoneNumber)
      ),
      name: isFilledString(name, InputError.Empty),
      address: isFilledString(address, InputError.Empty),
      country: isFilledString(country, InputError.Empty),
      pib: isFilledString(pib, InputError.Empty),
    };
    if (!allValid(validated)) {
      setPhoneError(
        validated.mobilePhone.isValid ? null : validated.mobilePhone.error
      );
      setNameError(validated.name.isValid ? null : validated.name.error);
      setAddressError(
        validated.address.isValid ? null : validated.address.error
      );
      setCountryError(
        validated.country.isValid ? null : validated.country.error
      );
      setPibError(validated.pib.isValid ? null : validated.pib.error);
      return false;
    }
    setPhoneError(null);
    setNameError(null);
    setAddressError(null);
    setCountryError(null);
    setPibError(null);
    return true;
  };

  const { mutate: generalInfoMutation } = useMutation(addSalonGeneralInfo, {
    onSuccess: (data) => {
      navigate("/onboard/workingHours");
    },
    onError: (error) => {},
  });

  const handleGeneralInfo = () => {
    if (validateFormFields() && validatePhoneNumber()) {
      generalInfoMutation({
        name,
        address,
        cityId: cityId || 0,
        cityPartId: cityPartId || 0,
        country,
        mobilePhone,
        pib,
        coverImage,
      });
    }
  };

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await getCities();
        setCities(response.result);
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    };

    fetchCities();
  }, []);

  useEffect(() => {
    const fetchCityParts = async () => {
      try {
        const selectedCity = cities.find((city) => city.key === cityId);
        if (selectedCity) {
          const cityPartsResponse = await getCityParts(selectedCity.key);
          const parts = cityPartsResponse.result;
          setCityParts(parts.cityParts || []);
        } else {
          console.error("Selected city not found.");
        }
      } catch (error) {
        console.error("Error fetching city parts:", error);
      }
    };

    fetchCityParts();
  }, [cityId]);

  const handleAddIconClick = (event) => {
    event.stopPropagation();
    handleImageUpload(handleCoverImageChange, false);
  };

  const handleCoverImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageUrl = reader.result?.toString() || "";
        setCoverImage(convertDataUrlToBase64(imageUrl));
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <OnboardingMainContainer>
      <StyledSupportButton onClick={() => navigate(HELP)} />
      <OnboardingCenterContainer>
        <StepperHeader>Korak 1/4</StepperHeader>
        <StepperSubHeader>Opšte informacije</StepperSubHeader>
        <OnboardingImageContainer
          onClick={() => handleImageUpload(handleCoverImageChange, false)}
        >
          <OnboardingIcon>
            <CameraAltIcon
              style={{
                fontSize: isMobile ? "1.875rem" : "2.1875rem",
                color: COLORS.mediumGray,
              }}
            />
          </OnboardingIcon>
          {coverImage && (
            <OnboardingSelectedImage
              src={`data:image/png;base64, ${coverImage}`}
              alt="Izabrana slika"
            />
          )}
          <OnboardingAddIcon onClick={handleAddIconClick}>
            <AddIcon
              style={{
                color: COLORS.white,
                fontSize: isMobile ? "1rem" : "1.5rem",
              }}
            />
          </OnboardingAddIcon>
        </OnboardingImageContainer>

        {isMobile ? (
          <Grid container spacing={2} style={{ marginTop: "0.3125rem" }}>
            <StyledColumnGrid item xs={12}>
              <InputField
                onChange={(event) => setName(event.target.value)}
                label={Boolean(nameError) ? nameError : "Ime salona"}
                placeholder="Ime salona"
                value={name}
                error={Boolean(nameError)}
                onFocus={() => setNameError(null)}
              />
              <InputField
                label={Boolean(addressError) ? addressError : "Adresa"}
                value={address}
                onChange={(event) => setAddress(event.target.value)}
                placeholder="Adresa"
                error={Boolean(addressError)}
                onFocus={() => setAddressError(null)}
              />
              <DropdownField
                label={"Grad"}
                options={cities.map((city) => ({
                  value: city.key,
                  label: city.value,
                }))}
                value={cityId || ""}
                onChange={(event) => setCityId(event.target.value)}
              />
              <DropdownField
                label={"Deo grada"}
                options={
                  cityParts && Array.isArray(cityParts) && cityParts.length > 0
                    ? cityParts.map((part) => ({
                        value: part.key,
                        label: part.value,
                      }))
                    : [{ value: "", label: "Nema dostupnih delova grada" }]
                }
                value={cityPartId || ""}
                onChange={(event) => setCityPartId(event.target.value)}
              />
              <InputField
                label={Boolean(countryError) ? countryError : "Država"}
                value={country}
                onChange={(event) => setConutry(event.target.value)}
                placeholder="Država"
                error={Boolean(countryError)}
                onFocus={() => setCountryError(null)}
              />
              <InputField
                label={Boolean(phoneError) ? phoneError : "Telefon"}
                onChange={(event) => setMobilePhone(event.target.value)}
                placeholder="Telefon"
                error={Boolean(phoneError)}
                onBlur={() => validatePhoneNumber()}
                onFocus={() => setPhoneError(null)}
              />
              <InputField
                label={Boolean(pibError) ? pibError : "PIB"}
                value={pib}
                onChange={(event) => setPib(event.target.value)}
                placeholder="PIB"
                error={Boolean(pibError)}
                onFocus={() => setPibError(null)}
              />
            </StyledColumnGrid>
          </Grid>
        ) : (
          <Grid container spacing={2} style={{ marginTop: "0.3125rem" }}>
            <StyledColumnGrid item xs={6}>
              <InputField
                onChange={(event) => setName(event.target.value)}
                label={Boolean(nameError) ? nameError : "Ime salona"}
                placeholder="Ime salona"
                value={name}
                error={Boolean(nameError)}
                onFocus={() => setNameError(null)}
              />
              <InputField
                label={Boolean(addressError) ? addressError : "Adresa"}
                value={address}
                onChange={(event) => setAddress(event.target.value)}
                placeholder="Adresa"
                error={Boolean(addressError)}
                onFocus={() => setAddressError(null)}
              />
              <DropdownField
                label={"Grad"}
                options={cities.map((city) => ({
                  value: city.key,
                  label: city.value,
                }))}
                value={cityId || ""}
                onChange={(event) => setCityId(event.target.value)}
              />
              <DropdownField
                label={"Deo grada"}
                options={
                  cityParts && Array.isArray(cityParts) && cityParts.length > 0
                    ? cityParts.map((part) => ({
                        value: part.key,
                        label: part.value,
                      }))
                    : [{ value: "", label: "Nema dostupnih delova grada" }]
                }
                value={cityPartId || ""}
                onChange={(event) => setCityPartId(event.target.value)}
              />
            </StyledColumnGrid>
            <StyledColumnGrid item xs={6}>
              <InputField
                label={Boolean(countryError) ? countryError : "Država"}
                value={country}
                onChange={(event) => setConutry(event.target.value)}
                placeholder="Država"
                error={Boolean(countryError)}
                onFocus={() => setCountryError(null)}
                disabled
              />
              <InputField
                label={Boolean(phoneError) ? phoneError : "Telefon"}
                onChange={(event) => setMobilePhone(event.target.value)}
                placeholder="Telefon"
                error={Boolean(phoneError)}
                onBlur={() => validatePhoneNumber()}
                onFocus={() => setPhoneError(null)}
              />
              <InputField
                label={Boolean(pibError) ? pibError : "PIB"}
                value={pib}
                onChange={(event) => setPib(event.target.value)}
                placeholder="PIB"
                error={Boolean(pibError)}
                onFocus={() => setPibError(null)}
              />
            </StyledColumnGrid>
          </Grid>
        )}

        <div
          style={{
            width: "100%",
            marginTop: "1.3125rem",
            marginBottom: isMobile ? "0.3125rem" : "1.875rem",
          }}
        >
          <PrimaryButton
            text={"Sledeći korak"}
            width={"100%"}
            onClick={() => handleGeneralInfo()}
          />
        </div>
      </OnboardingCenterContainer>
    </OnboardingMainContainer>
  );
};
