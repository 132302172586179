import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "react-query";

import PetifyLogo from "../../assets/svg/PetifyLogo";
import { InputField } from "../../components/inputs/TextField";
import { PrimaryButton } from "../../components/buttons/primaryButton/PrimaryButton";
import {
  Header,
  StyledCenteredContainer,
  StyledMasterContainer,
  StyledSupportButton,
} from "../../constants/customStyles";
import { registerSalon, registerWorker } from "../../services/auth";
import { InputError } from "../../helpers/inputValidation/InputError";
import and from "../../helpers/inputValidation/validators/and";
import isFilledString from "../../helpers/inputValidation/validators/isFilledString";
import isPasswordStrong from "../../helpers/inputValidation/validators/isPasswordStrong";
import isEqualString from "../../helpers/inputValidation/validators/isEqualString";
import {
  ACCESS_TOKEN,
  IS_WORKER,
  REFRESH_TOKEN,
} from "../../constants/localStorageKeys";
import { useNavigate } from "react-router-dom";
import {
  Severity,
  useNotificationContext,
} from "../../context/NotificationContext";
import { CALENDAR, HELP } from "../../constants/urlPaths";
import { StyledRegistrationFieldsDiv } from "./RegistrationEmail.styles";
import { StyledIconButton } from "../login/Login.styles";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export const RegistrationPassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState<InputError | null>(null);
  const [repeatPassword, setRepeatPassword] = useState("");
  const { email, verificationCode, isWorker } = useParams();
  const { showNotification } = useNotificationContext();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState<boolean>(false);

  const handleTogglePasswordVisibility = (isRepeat: boolean) => {
    isRepeat && setShowRepeatPassword((prevShowPassword) => !prevShowPassword);
    !isRepeat && setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const { mutate: registerUserMutation } = useMutation(registerSalon, {
    onSuccess: (data) => {
      localStorage.setItem(ACCESS_TOKEN, data?.result?.accessToken);
      localStorage.setItem(REFRESH_TOKEN, data?.result?.refreshToken);
      showNotification("Uspešna registracija.", Severity.Success);
      navigate("/onboard/generalInfo");
    },
    onError: (error) => {
      showNotification("Neuspešna registracija.", Severity.Error);
    },
  });

  const { mutate: registerWorkerMutation } = useMutation(registerWorker, {
    onSuccess: (data) => {
      localStorage.setItem(ACCESS_TOKEN, data?.result?.accessToken);
      localStorage.setItem(REFRESH_TOKEN, data?.result?.refreshToken);
      showNotification("Uspešna registracija.", Severity.Success);
      navigate(CALENDAR);
    },
    onError: (error) => {
      showNotification("Neuspešna registracija.", Severity.Error);
    },
  });

  const validateFormFields = (): boolean => {
    const passwordValidation = and(
      isFilledString(password, InputError.Empty),
      () => isPasswordStrong(password, InputError.NotStrongPassword)
    );

    if (!passwordValidation.isValid) {
      setPasswordError(passwordValidation.error);
      return false;
    }

    const confirmPasswordValidation = and(
      isFilledString(repeatPassword, InputError.Empty),
      () => isEqualString(password, repeatPassword, InputError.NoMatch)
    );

    if (!confirmPasswordValidation.isValid) {
      setPasswordError(confirmPasswordValidation.error);
      return false;
    }

    setPasswordError(null);
    return true;
  };

  const handleRegistration = () => {
    if (validateFormFields()) {
      const data = {
        email: email ?? "",
        password: password,
        verificationCode: parseInt(verificationCode ?? "0"),
      };
      if (isWorker && JSON.parse(isWorker)) {
        localStorage.setItem(IS_WORKER, isWorker);
        registerWorkerMutation(data);
        return;
      }
      localStorage.setItem(IS_WORKER, "false");
      registerUserMutation(data);
    }
  };

  return (
    <StyledMasterContainer>
      <StyledSupportButton onClick={() => navigate(HELP)} />
      <StyledCenteredContainer>
        <PetifyLogo />
        <Header>Registracija</Header>
        <div
          style={{
            display: "inline-block",
            alignItems: "center",
            flexDirection: "column",
            color: "#6D6D6D",
            fontSize: "14px",
            fontWeight: "400",
            marginBottom: "0.93rem",
            marginTop: "0.8rem",
            textAlign: "justify",
          }}
        >
          <span>Ostalo je još samo da kreirate šifru kako biste završili </span>
          <span>registraciju.</span>
        </div>
        <StyledRegistrationFieldsDiv>
          <InputField
            placeholder="Sifra"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type={showPassword ? "text" : "password"}
            label={"Sifra"}
            InputProps={{
              endAdornment: (
                <StyledIconButton
                  onClick={() => handleTogglePasswordVisibility(false)}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </StyledIconButton>
              ),
            }}
          />
          <InputField
            placeholder="Ponovite sifru"
            value={repeatPassword}
            onChange={(e) => setRepeatPassword(e.target.value)}
            type={showRepeatPassword ? "text" : "password"}
            label={Boolean(passwordError) ? passwordError : "Ponovite sifru"}
            error={Boolean(passwordError)}
            onBlur={() => validateFormFields()}
            onFocus={() => setPasswordError(null)}
            InputProps={{
              endAdornment: (
                <StyledIconButton
                  onClick={() => handleTogglePasswordVisibility(true)}
                  edge="end"
                >
                  {showRepeatPassword ? <Visibility /> : <VisibilityOff />}
                </StyledIconButton>
              ),
            }}
          />

          <PrimaryButton
            width={"100%"}
            text={"Ok"}
            onClick={handleRegistration}
          />
        </StyledRegistrationFieldsDiv>
      </StyledCenteredContainer>
    </StyledMasterContainer>
  );
};
