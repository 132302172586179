import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material//ChevronRight";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, useMediaQuery, useTheme } from "@mui/material";

import ToolbarMenu from "./Toolbar";
import {
  StyledAppBar,
  StyledAppBarShift,
  StyledDrawer,
  StyledLogo,
  StyledToolBar,
} from "./NavigationMenu.styles";
import MenuList from "./MenuList";
import PetifyLogo from "../../assets/svg/PetifyLogo";

interface NavigationMenuProps {
  isClosed: boolean;
  closeMenu: () => void;
  openMenu: () => void;
}

const NavigationMenu = ({
  isClosed,
  closeMenu,
  openMenu,
}: NavigationMenuProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      {isMobile && (
        <>
          {isClosed ? (
            <StyledAppBar position="fixed" color="inherit">
              <ToolbarMenu closed={isClosed} openMenu={openMenu} />
            </StyledAppBar>
          ) : (
            <StyledAppBarShift position="fixed" color="inherit">
              <ToolbarMenu closed={isClosed} openMenu={openMenu} />
            </StyledAppBarShift>
          )}
        </>
      )}
      <StyledDrawer variant="permanent" anchor="left" open={!isClosed}>
        <StyledToolBar>
          <IconButton onClick={() => (isClosed ? openMenu() : closeMenu())}>
            {isClosed ? (
              <ChevronRightIcon />
            ) : isMobile ? (
              <CloseIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </StyledToolBar>
        <StyledLogo>
          <PetifyLogo width="9rem" height="9rem" />
        </StyledLogo>
        <MenuList />
      </StyledDrawer>
    </>
  );
};

export default NavigationMenu;
