import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";

import { COLORS } from "../../../constants/colors";

interface StyledPrimaryButtonProps extends ButtonProps {
  fontSize?: string;
}

export const StyledPrimaryButton = styled(Button)<StyledPrimaryButtonProps>(({ theme, fontSize }) => ({
  backgroundColor: COLORS.primary,
  borderRadius: "0.5625rem",
  height: "56px",
  fontSize: fontSize || "1rem",
  lineHeight: "normal",
  "&:hover": {
    backgroundColor: COLORS.primary,
    outline: `0.00625rem solid ${COLORS.primary}`,
  },
}));
