import dayjs from "dayjs";
import { useQuery } from "react-query";

import apiClient from "../api/api";
import { CANCEL_RESERVATION, FETCH_APPOINTMENTS, MANUAL_RESERVATION } from "../routes/routes";
import { Appointment } from "../../types/Types";
import { USE_REFETCH_APPOITMENTS_KEY } from "../../constants/queryKeys";

export const fetchSalonAppointments = async (startDate, endDate) => {
  const { data } = await apiClient.get(
    `${FETCH_APPOINTMENTS}?startDate=${startDate}&endDate=${endDate}`
  );
  return data;
};

export const useSalonAppointments = (startDate, endDate) => {
  const { data, isLoading, isError, isFetching, refetch } = useQuery(
    [`appoitment-${startDate}-${endDate}` || USE_REFETCH_APPOITMENTS_KEY],
    () => fetchSalonAppointments(startDate, endDate),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  return {
    data: data?.result?.map((item) => ({
      ...item,
      start: dayjs(item.start).toDate(),
      end: dayjs(item.end).toDate(),
    })),
    isLoading,
    isError,
    isFetching,
    refetch,
  };
};

export const postNewappointment = async ({
  date,
  ownerName,
  petName,
  mobilePhone,
  note,
  serviceIds,
  timeData,
}: Appointment) => {
  const { data } = await apiClient.post(MANUAL_RESERVATION, {
    date,
    ownerName,
    petName,
    mobilePhone,
    note,
    serviceIds,
    startTime: timeData.timeSlot,
    workerId: timeData.workerId,
  });

  return data;
};

export const cancelAppointment = async (id: number) => {
    const { data } = await apiClient.post(CANCEL_RESERVATION, {
    id,
  });
    return data;
};
