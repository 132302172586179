import { styled } from "@mui/material";
import { COLORS } from "../../../constants/colors";

export const IconButtonBorder = styled("div")({
  width: "1.125rem",
  height: "1.125rem",
  borderRadius: "50% !important",
  backgroundColor: COLORS.secondary,
  padding: "0.3125rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
