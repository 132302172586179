import React from "react";
import { FormControl, InputLabel, MenuItem } from "@mui/material";

import { DropdownContainer, StyledSelect } from "./Dropdown.styles";

interface DropdownProps {
  options: any;
  label: string | "";
  value: string | number;
  onChange: (event: any) => void;
  style?: React.CSSProperties;
}

export const DropdownField = ({
  label,
  options,
  value,
  onChange,
  style,
  ...props
}: DropdownProps) => {
  return (
    <DropdownContainer style={style}>
      <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>
        <StyledSelect
          value={value}
          onChange={onChange}
          label={label}
          {...props}
        >
          {options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </DropdownContainer>
  );
};

export default DropdownField;
