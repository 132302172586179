import { Tabs, Typography, styled } from "@mui/material";
import { COLORS } from "../../constants/colors";

export const CustomTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTab-root": {
    color: COLORS.gray_500,
    width: "200px",

    [theme.breakpoints.down("md")]: {
    width: "50%",
  },
  },
  "& .Mui-selected": {
    color: `${COLORS.primary}!important`,
  },
  "& .MuiTabs-indicator": {
    backgroundColor: `${COLORS.primary}!important`,
  },
}));

export const HeaderContainer = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: '100%',
}));

export const ContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "2rem",
  width: '100%',
  paddingTop: '2rem',

  [theme.breakpoints.down("sm")]: {
    paddingTop: '1rem',
    gap: "1rem",
  },
}));

export const FieldsContainer = styled("div")(({ theme }) => ({
  display: "flex",
  gap: "1rem",
  alignItems: "center",
  width: '100%',

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

export const ButtonContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: '100%',
  gap: "1rem",

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column-reverse",
  },
}));

export const PriceCol = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: '100%',
  gap: "0.25rem",
}));

export const HeadingAnalytics = styled(Typography)(({ theme }) => ({
  fontSize: "2rem",
  color: COLORS.darkGray,
  fontWeight: "600",
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
}));

export const CountReservations = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  color: COLORS.gray_600,
  fontWeight: "500",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.8rem",
  },
}));

export const StatusReservation = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: "400",
  [theme.breakpoints.down("sm")]: {
    fontSize: "14px",
  },
}));

